import { useEffect, useState } from "react";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import {
  getMyEvents,
  getProfileSummary,
  getMyClubsGrouped,
  updateProfileDisplayAll,
} from "../../../actions/profile";
import {
  addEvent,
  getEventTags,
  declineEventInvite,
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getRecommendations,
  getLatest
} from "../../../actions/event";
import {
  Button,
  Spin,
  Collapse,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Layout,
  Menu,
  Badge,
  Dropdown,
  Divider,
} from "antd";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import {
  FilterOutlined,
  CloseOutlined,
  SearchOutlined,
  CalendarOutlined,
  MenuOutlined,
  SettingOutlined,
  LogoutOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import { logout } from "../../../actions/auth";
import { getInterestListNonPaged } from "../../../actions/interest";
import { getClubListNonPaged, getClubFromSlug } from "../../../actions/club";
import PageMiniHeader from "../calendar/PageMiniHeader";
import BreezeCalendar from "../../calendar/BreezeCalendar";
import { EventInvite } from "../events/EventInvite";
import { getCalendarEvents } from "../../../actions/calendar";
import { updateProfileInterest } from "../../../actions/profile";
import { setRefreshCalendar } from "../../../actions/refreshCalendar";
import { createLoginTrail } from "../../../actions/analytics";
import { autoAcceptFriend } from "../../../actions/social";
import DefaultAvatar from "../../widget/DefaultAvatar";
import userNoPhoto from "../../../assets/images/icons/user-no-photo.svg";
import flame from "../../../assets/images/streak/flame-2.svg";
import breezeHorizontalLogoBlue from "../../../assets/images/logos/breeze-horizontal-blue.svg";
import PersonAdd from "../../../assets/images/icons/person_add_b.svg";
import BlockImg from "../../../assets/images/icons/block.svg";

import ModalLogout from "../../layout/ModalLogout";
import JoinBreezeModal from "./JoinBreezeModal";

const { Panel } = Collapse;
interface SelectItem {
  label: string;
  value: string;
}
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const customFormat = (value: any) => `${value.format(dateFormat)}`;
const PAGE_SIZE = 9;

const ClubPage = (props: {
  searchUpcomingEvents: Function;
  searchSchoolWideEvents: Function;
  getRecommendations: Function;
  getMyEvents: Function;
  getInterestListNonPaged: Function;
  getClubListNonPaged: Function;
  declineEventInvite: Function;
  getProfileSummary: any;
  userIsBack: boolean;
  member: any;
  getEventTags: Function;
  getCalendarEvents: Function;
  googleAccessToken: string;
  updateProfileInterest: Function;
  refreshCalendar: Boolean;
  setRefreshCalendar: Function;
  createLoginTrail: Function;
  updateProfileDisplayAll: Function;
  logout: Function;
  flutterConnect:any;
  getClubFromSlug: Function;
}) => {
  const {
    member,
    getEventTags,
    getCalendarEvents,
    googleAccessToken,
    updateProfileInterest,
    refreshCalendar,
    setRefreshCalendar,
    createLoginTrail,
    updateProfileDisplayAll,
    flutterConnect,
    logout,
    getClubFromSlug,
  } = props;
  const [events, setEvents] = useState<EventInvite[]>([]);
  const [allEvents, setAllEvents] = useState<EventInvite[]>([]);
  const [searchString, setSearchString] = useState("");
  const [selectedInterest, setSelectedInterest]: any = useState(
    member?.interests ?? []
  );
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [interestList, setInterestList] = useState<any[]>([]);
  const [isInterestLoaded, setIsInterestLoaded] = useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<any[]>([]);
  const [currMonth, setCurrMonth] = useState(
    moment(dateRangeFilter[0]).month()
  );
  const [formCreateEvent] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [eventTags, setEventTags] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [recommendedEvents, setRecommendedEvents] = useState<any[]>([]);
  const [googleEvents, setGoogleEvents] = useState<any[]>([]);
  const [dateFilter, setDateFilter] = useState<any>(null);
  const [calendarView, setCalendarView] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCurrentEvents, setIsLoadingCurrentEvents] = useState(false);
  const [isLoadingPastEvents, setIsLoadingPastEvents] = useState(false);
  const [isJoinBreezeOpen, setIsJoinBreezeOpen] = useState(false);
  const [allOtherPromotions, setAllOtherPromotions] = useState<any>([]);
  const [isLoadingFutureEvents, setIsLoadingFutureEvents] = useState<any>(false);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [modal2Visible, setModal2Visible] = useState(false);
  const [clubData, setClubData] = useState<any>(null);
  let navigate = useNavigate();

  const fetchCalendarEvents = async () => {
    const from = moment(dateFilter).startOf("month").startOf("week").utc().toISOString();
    const to = moment(dateFilter).endOf("month").endOf("week").utc().toISOString();

    const googleEvents = await getCalendarEvents({
      accessToken: googleAccessToken,
      startDate: from,
      endDate: to,
    });
    if (googleEvents && googleEvents.data.length >= 0) {
      setGoogleEvents(googleEvents.data);
    }
  };

  const updateInterest = async () => {
    const res = await updateProfileInterest({
      interests: selectedInterest.map((e: any) => e.interestId),
      isSkipValidation: true,
    });
  };

  const removeDuplicates = (arr1: any, arr2: any) => {
    const mergedArr = arr1.concat(arr2);
    return mergedArr.filter(
      (v: any, i: any, s: any) =>
        mergedArr.findIndex((e: any) => e.eventId == v.eventId) === i
    );
  };

  const openNotification = () => {
    api.open({
      message: "Search Results",
      description: "No Record Found!",
      duration: 3,
      icon: <SearchOutlined />,
    });
  };
  
  const fetchEvents = async (toUpdate?:Boolean) => {
    const views = {
      day: getTodaySearchParams,
      week: getWeekSearchParams,
      month: getMonthSearchParams,
    };

    const params = (
      views[calendarView as keyof typeof views] ?? views["day"]
    )();

    if (!dateFilter) return;
    
    setIsLoadingCurrentEvents(true);
    
    const current = await searchEvents(params);
    if (current && current.data && current.data.items) {
      setIsLoadingCurrentEvents(false);
      setEvents([...current.data.items]);
      setAllEvents([...current.data.items]);
    }
    fetchCalendarEvents();  //google events
    fetchFutureEvents(params);
    fetchPastEvents(params);
  };

  const fetchPastEvents = async (params: any) => {
    setIsLoadingPastEvents(true);
    const past = await searchEvents({
      ...params,
      from: moment(dateFilter)
        .startOf("month")
        .startOf("week")
        .utc()
        .toISOString(),
      to: moment(params.from).local().startOf("day").utc().toISOString(),
    });
    setIsLoadingPastEvents(false);

    if (!(past && past.data && past.data.items)) return;
    setEvents((current) => removeDuplicates(current, [...past.data.items]));
    setAllEvents((current) => removeDuplicates(current, [...past.data.items]));
  };

  const fetchFutureEvents = async (params: any) => {
    setIsLoadingFutureEvents(true);
    const future = await searchEvents({
      ...params,
      from: moment(params.to).local().endOf("day").utc().toISOString(),
      to: moment(dateFilter).endOf("month").endOf("week").utc().toISOString(),
    });
    setIsLoadingFutureEvents(false);

    if (!(future && future.data && future.data.items)) return;
    setEvents((current) => removeDuplicates(current, [...future.data.items]));
    setAllEvents((current) =>
      removeDuplicates(current, [...future.data.items])
    );
  };

  const getMonthSearchParams = () => ({
    from: moment(dateFilter)
      .startOf("month")
      .startOf("week")
      .utc()
      .toISOString(),
    to: moment(dateFilter).endOf("month").endOf("week").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const getWeekSearchParams = () => ({
    from: moment(dateFilter).startOf("week").utc().toISOString(),
    to: moment(dateFilter).endOf("week").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const getTodaySearchParams = () => ({
    from: moment(dateFilter).startOf("day").utc().toISOString(),
    to: moment(dateFilter).endOf("day").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const searchEvents = async (params: any) =>
    params.from !== params.to
      ? await props.searchUpcomingEvents(
        {
          ...params,

          pageSize: 2000,
          searchString,
          schoolEventsOnly: false,
          MyEventsOnly: false,

          InterestIds:
            selectedInterest?.length > 0
              ? selectedInterest
                .reduce(
                  (acc: any, e: any) => [
                    ...acc,
                    e.interestId
                    // ...[e.interestId,
                    // ...(e.subInterestIds ?? [])],
                  ],
                  []
                )
                .toString()
              : null,
          memberGroupIds:clubData?.memberGroupId,
          Statuses: "-1,0,1,2",
        },
        controller
      )
      : [];

  const fetchEventTags = async () => {
    let data = await getEventTags();
    setEventTags(data.data);
  };

  const fetchInterestList = async () => {
    const data = await props.getInterestListNonPaged();
    let myInterests = member?.interests
      ? member?.interests.map((data: any) => {
        return data.interestId;
      })
      : [];
    let firstOnList = data.data.filter((item: any) => {
      if (myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let nextOnList = data.data.filter((item: any) => {
      if (!myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
          subInterestIds: item.subInterestIds,
        };
      }
    });
    let mergedList = firstOnList.concat(nextOnList).map((item: any) => ({
      label: item.name,
      value: item.interestId,
      subInterestIds: item.subInterestIds,
    }));
    setInterestOptions(mergedList);
    setInterestList(data.data);
    setIsInterestLoaded(true);
  };


  const searchFilter = () => {
    let filteredResult = allEvents.filter((e) =>
      e.title.toLowerCase().includes(searchString.toLowerCase())
    );
    if (filteredResult.length === 0 && allEvents.length > 0) {
      openNotification();
    }
    setEvents(filteredResult);
  };

  const onLogoutHandler = () => {
    setModal2Visible(true);
  };

  const onLogoutCancel = () => {
    setModal2Visible(false);
  };

  const getClubData = async () => {
    setIsLoading(true);
    const slug = (window.location.pathname).split('/')[2];
    const result = await getClubFromSlug(slug);
    if (result.status == 200) {
        setClubData(result.data);
    } else {
      message.error("Club Not Found!");
      navigate('/*');
    }
    setIsLoading(false);
};

useEffect(() => {
    getClubData();
    fetchEventTags();
    fetchInterestList();
    createLoginTrail();
  }, []);

  useEffect(() => { //check if logged in
    if(member===null){
      setIsJoinBreezeOpen(true);
    }
  }, []);

  useEffect(() => {
    searchFilter();
  }, [searchString]);

  useEffect(() => {
    if (!dateFilter) return;
    if(member!==null && clubData!==null){
      fetchEvents();
    }
  }, [dateFilter, clubData]);

  useEffect(() => {
    if(member!==null){
      setEvents([]);
      updateInterest();
    }
    if (dateFilter && member!==null && clubData!==null) fetchEvents(true);
  }, [selectedInterest, selectedTags]);

  useEffect(() => {
    if (currMonth !== moment(dateRangeFilter[0]).month() && member!==null && clubData!==null) {
      //prevents reloading within the same month
      setEvents([]);
      setCurrMonth(moment(dateRangeFilter[0]).month());
      fetchEvents();
    }
  }, [dateRangeFilter]);

  useEffect(() => {
    if (refreshCalendar && member!==null && clubData!==null) {
      setRefreshCalendar(false);
      fetchEvents();
    }
  }, [refreshCalendar]);

  const controller = new AbortController();

  return (
    <Layout>
      <JoinBreezeModal
        isOpen={isJoinBreezeOpen}
        setIsOpen={setIsJoinBreezeOpen}
      />
     
      <ModalLogout
        onLogoutCancel={onLogoutCancel}
        modal2Visible={modal2Visible}
        logout={logout}
      />
          <Header
            className="desktop-header"
            style={{ position: "fixed", zIndex: 10, width: "100%" }}
          >
            <div className="menu-wrapper">
              <Link to={member ? "/calendar" : "/"}>
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${breezeHorizontalLogoBlue})`,
                  }}
                />
              </Link>
              <Menu className="secondMenus" mode="horizontal">
                {member?.loginStreak>1 && (
                  <Badge count={member?.loginStreak} color="#f35d38" offset={[0, 22]} size="small">
                    <img src={flame} className="badge"/>
                  </Badge>
                )}
                <Dropdown
                  key="more"
                  overlay={
                    
                    <Menu>
                       {member ? (
                        <>
                        <Menu.Item key="blocklist">
                          <Link to={"/contacts/blocklist"}>Blocklist</Link>
                        </Menu.Item>
                        {member?.memberType === "Teacher" && (
                          <Menu.Item key="import">
                            <Link to={"/admin"}>Import Event</Link>
                          </Menu.Item>
                        )}
                        <Menu.Item key="settings">
                          <Link to={"/settings"}>Settings</Link>
                        </Menu.Item>
                        <Divider className="dropdown-divider" />
                        <Menu.Item key="logout">
                          <a onClick={() => onLogoutHandler()}>Logout</a>
                        </Menu.Item>
                        </>
                        ):(
                          <Menu.Item key="login">
                          <Link to={"/"}>Login</Link>
                          </Menu.Item>
                        )}
                    </Menu>
                  }
                  placement="bottomRight"
                > 
                  <Menu.Item key="4">
                    <div className="user-container">
                      {member?.profilePicture ? (
                        <div>
                          <img
                            src={member?.profilePicture + "?" + Date.now()}
                            height={24}
                            width={24}
                          />{" "}
                          <h5>{member?.firstName + " " + member?.lastName}</h5>
                        </div>
                      ) : (
                        <Fragment>
                          {member?.firstName && member?.lastName ?(
                            <>
                              <DefaultAvatar
                              firstName={member?.firstName}
                              lastName={member?.lastName}
                              />{" "}
                            </>
                          ):(
                            <img src={userNoPhoto} height={24} width={24}/>
                          )}
                          {member?.firstName && member?.lastName && (
                            <h5>{member?.firstName + " " + member?.lastName}</h5>
                          )}
                        </Fragment>
                      )}
                    </div>
                  </Menu.Item>
                </Dropdown>
              </Menu>
            </div>
          </Header>
          <div className="mobile-header">
            <div className="left">
              <MenuOutlined
                className="nav-item"
                onClick={() => setIsFlyoutVisible(true)}
              />
              <div
                className="logo"
                style={{ backgroundImage: `url(${breezeHorizontalLogoBlue})` }}
              />
            </div>

            <div className="nav-items">
              {/* <QuestionCircleOutlined className="nav-item" /> */}
              {member?.loginStreak>1 && (
                  <Badge count={member?.loginStreak} color="#f35d38" offset={[0, 0]} size="small"> 
                    <img src={flame} className="badge"/>
                  </Badge>
                )}
              <div className="nav-item">
                <img
                  src={
                    member && member?.profilePicture
                      ? member?.profilePicture
                      : // ? member?.profilePicture + "?" + Date.now()
                        userNoPhoto
                  }
                  height={24}
                  width={24}
                />
              </div>
            </div>
          </div>
          <div
            className={`mobile-flyout-backdrop fadein-fast ${
              flutterConnect ? "app" : ""
            }`}
            style={{
              display: `${isFlyoutVisible ? "block" : "none"}`,
            }}
            onClick={() => setIsFlyoutVisible(false)}
          >
            <div className="mobile-flyout" onClick={(e) => e.stopPropagation()}>
              <ul className="menu-list">
                <div>
                  <li className="flyout-profile">
                    <div className="user-container">
                      {member?.profilePicture ? (
                        <div>
                          <img
                            src={member?.profilePicture}
                            // src={member?.profilePicture + "?" + Date.now()}
                            height={24}
                            width={24}
                          />{" "}
                          <h5>{member?.firstName + " " + member?.lastName}</h5>
                        </div>
                      ) : (
                        <Fragment>
                         {member?.firstName && member?.lastName ?(
                            <>
                              <DefaultAvatar
                              firstName={member?.firstName}
                              lastName={member?.lastName}
                              />{" "}
                            </>
                          ):(
                            <img src={userNoPhoto} height={24} width={24}/>
                          )}
                          {member?.firstName && member?.lastName && (
                            <h5>{member?.firstName + " " + member?.lastName}</h5>
                          )}
                        </Fragment>
                      )}
                    </div>
                    <CloseOutlined
                      className="mr-2"
                      onClick={() => setIsFlyoutVisible(false)}
                    />
                  </li>
                  <div className="divider" />
                  {member && (
                    <>
                    
                  <Link to={"/calendar"}>
                    <li
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <CalendarOutlined style={{ marginRight: "10px" }} />
                      Calendar
                    </li>
                  </Link>
                  <Link to={"/contacts/friend-requests"}>
                    <li
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <img src={PersonAdd} style={{ marginRight: "10px" }} />
                      Contacts
                    </li>
                  </Link>
                  <Link to={"/contacts/blocklist"}>
                    <li
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <img src={BlockImg} style={{ marginRight: "10px" }} />
                      Blocklist
                    </li>
                  </Link>
                  {member?.memberType === "Teacher" && (
                    <Link to={"/admin"}>
                      <li
                        onClick={() => setIsFlyoutVisible(false)}
                      >
                        <ImportOutlined style={{ marginRight: "10px" }} />
                        Import Event
                      </li>
                    </Link>
                  )}
                  <Link to={"/settings"}>
                    <li
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <SettingOutlined style={{ marginRight: "10px" }} />
                      Settings
                    </li>
                  </Link>
                  </>
                  )}
                </div>
                <div>
                  {member ? (
                  <li
                    onClick={() => {
                      setIsFlyoutVisible(false);
                      setModal2Visible(true);
                    }}
                  >
                    <LogoutOutlined style={{ marginRight: "10px" }} />
                    Sign Out
                  </li>
                  ):(
                    <Link to={"/"}>
                    <li>
                      Login
                    </li>
                  </Link>
                  )}

                  <div className="divider" />
                  <Link to="privacy-policy">
                    <li className="privacy-policy">Privacy Policy</li>
                  </Link>
                </div>
              </ul>
            </div>
          </div>

          <Content className="site-layout">

    <Fragment>
      {contextHolder}

      <PageMiniHeader
        tags={eventTags}
        interests={interestList}
        filterToggle={<div></div>}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedInterest={selectedInterest}
        setSelectedInterest={setSelectedInterest}
      >
        <Popover
          placement="bottomLeft"
          content={<div />}
          trigger="click"
          className="filter-sm"
        >
          <Button
            className="filter-toggler mobile-reactive-btn"
            icon={<FilterOutlined />}
            shape="round"
          />
        </Popover>
      </PageMiniHeader>
      <div className="content-wrapper">
        <div className="content-container">
          <Fragment>
            <Spin spinning={isLoadingCurrentEvents} size="large">
              <BreezeCalendar
                isLoading={isLoadingFutureEvents || isLoadingPastEvents}
                selectedTime={selectedTime}
                refresh={fetchEvents}
                selectedInterest={selectedInterest}
                eventsFuture={events}
                promotions={allOtherPromotions}
                eventTags={eventTags}
                recommendedEvents={recommendedEvents}
                googleEvents={googleEvents}
                member={member}
                updateProfile={updateProfileDisplayAll}
                clubData={clubData}
                clubIsLoading={isLoading}
                handleCalendarFilterDateChange={async (
                  newDateFilter: any,
                  view: any
                ) => {
                  const parsed = new Date(newDateFilter);

                  setCalendarView(view);
                  setDateFilter((current: any) =>
                    current &&
                      `${current.toLocaleString("en-US", {
                        month: "short",
                        timeZone: "UTC",
                      })}` ===
                      parsed.toLocaleString("en-US", {
                        month: "short",
                        timeZone: "UTC",
                      })
                      ? current
                      : parsed
                  );
                }}
              />
            </Spin>
          </Fragment>
        </div>
      </div>
    </Fragment>
    </Content>
      <Footer>
        <div className="footer-wrapper">
          <div className="d-flex justify-content-space-between">
            <h3 className="footer-text">
              © Breeze 2022, All Rights Reserved.
            </h3>
            <div className="footer-links">
              <Link to="privacy-policy">Privacy Policy</Link>
            </div>
          </div>
        </div>
      </Footer>
    </Layout>
  );
};

const mapStateToProps = (state: {
  auth: any;
  userIsBack: boolean;
  googleAccessToken: string;
  refreshCalendar: boolean;
  flutterConnect:any;
}) => ({
  flutterConnect: state.flutterConnect,
  userIsBack: state.userIsBack,
  member: state.auth?.member,
  googleAccessToken: state.auth?.googleAccessToken,
  refreshCalendar: state.refreshCalendar,
});
export default connect(mapStateToProps, {
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getRecommendations,
  getMyEvents,
  getInterestListNonPaged,
  getClubListNonPaged,
  getMyClubsGrouped,
  declineEventInvite,
  getProfileSummary,
  addEvent,
  getEventTags,
  getCalendarEvents,
  updateProfileInterest,
  setRefreshCalendar,
  createLoginTrail,
  getLatest,
  autoAcceptFriend,
  updateProfileDisplayAll,
  logout,
  getClubFromSlug,
})(ClubPage);
