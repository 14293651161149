import { FIREBASE_REG_TOKEN } from "./types";

export const setFirebaseRegistrationToken = (payload) => (dispatch) => {
  {
    dispatch({
      type: FIREBASE_REG_TOKEN,
      payload,
    });
  }
};
