import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";
import CustomTooltip from "./CustomTooltip";
import grade from '../../../../../assets/images/icons/pivot-admin/grade.svg'

const Day = (data:any) => {
    const curr = data?.data;
    const prev = data?.prev;
    const primeTime = data?.primeTime;
    return(
        <Tooltip title={<CustomTooltip data={curr}/>} overlayClassName="time-section-tooltip">
            <div className="day-analytic">
                <div className="main">
                    <h1 className="title">{moment(curr?.date).format("dddd DD")}</h1>
                    <h1 className="time">{moment(curr?.date).format("h:mm A")} - {moment(curr?.date).add(1,"hour").format("hh:mm A")}</h1>
                    <p className="previous">last week was <span className="blue">{moment(prev?.date).format("h:mm A")} - {moment(prev?.date).add(1,"hour").format("hh:mm A")}</span></p>
                </div>
                {moment(curr?.date).format("h:mm A") === moment(primeTime).format("h:mm A") && (
                    <div className="medal-box">
                        <img src={grade}/>
                    </div>
                )}
            </div>
        </Tooltip>
    )
}
export default Day;