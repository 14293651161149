import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Spin, Divider } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { connect } from "react-redux";
import {
    CalendarOutlined,
    RiseOutlined,
    FallOutlined,
} from "@ant-design/icons";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js'
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

import PersonIcon from '../../../../assets/images/icons/pivot-admin/Person.svg'
import PersonAlertIcon from '../../../../assets/images/icons/pivot-admin/person_alert.svg'
import GroupIcon from '../../../../assets/images/icons/pivot-admin/group.svg'
import NoData from '../../../../assets/images/illustration/No-data-to-display.svg'

import MonthFilter from "../components/MonthFilter";
import { getLoginOverview, getWeeklyLoginOverview } from "../../../../actions/pivotAdmin";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
)

const LoginsAnalytics=(props:{
    dates: any,
    filterType: any,
    getLoginOverview: Function,
    getWeeklyLoginOverview: Function,
})=>{
    const {
        dates,
        filterType,
        getLoginOverview,
        getWeeklyLoginOverview,
      } = props;
    const [ selectedMonth, setSelectedMonth ] = useState(moment().month()+1);
    const [ loginOverViewData, setLoginOverviewData ] = useState<any>({});
    const [ weeklyLoginData, setWeeklyLoginData ] = useState<any>([]);
    const [ activePercent, setActivePercent ] = useState({type:"",value:0});
    const [ inactivePercent, setInactivePercent ] = useState({type:"",value:0});
    const [ totalPercent, setTotalPercent ] = useState({type:"",value:0});
    const [ isFetching, setIsFetching ] = useState(false);

    const fetchLoginOverview = async() =>{
        setIsFetching(true);
        const defaultFrom = moment().startOf("month").utc().toISOString();
        const defaultTo = moment().endOf("month").utc().toISOString();
        // const res = await getLoginOverview({
        //     from: dates[0]?dates[0]:defaultFrom,
        //     to: dates[1]?dates[1]:defaultTo,
        //     filterBy : filterType
        // });
        // if(res.status===200){
        //     // setLoginOverviewData(res.data);
        //     // getPercentageChange(res.data);
        // }
        const res2 = await getWeeklyLoginOverview({
            from: dates[0]?dates[0]:defaultFrom,
            to: dates[1]?dates[1]:defaultTo,
        });
        if(res2.status===200){
            setWeeklyLoginData(res2.data);
            setLoginOverviewData(res2.data?.slice(-1)[0]);
            getPercentageChange(res2.data?.slice(-2));
        }
        setIsFetching(false);
    }

    useEffect(() => {
        fetchLoginOverview();
    }, [dates]);

    function toFixedIfNecessary(value:any){
        return +parseFloat(value).toFixed(0);
    }
    
    const getPercentageChange = (data:any) =>{
        let currWeek = data[1];
        let lastWeek = data[0];
        if(currWeek?.activeUsers>=lastWeek?.activeUsers){
            setActivePercent({type:"increase",value:toFixedIfNecessary(((currWeek?.activeUsers-lastWeek?.activeUsers)/(lastWeek?.activeUsers>0?lastWeek?.activeUsers:1))*100)});
        }else{
            setActivePercent({type:"decrease",value:toFixedIfNecessary(((lastWeek?.activeUsers-currWeek?.activeUsers)/(currWeek?.activeUsers>0?currWeek?.activeUsers:1))*100)});
        }

        if(currWeek?.inactiveUsers>=lastWeek?.inactiveUsers){
            setInactivePercent({type:"increase",value:toFixedIfNecessary(((currWeek?.inactiveUsers-lastWeek?.inactiveUsers)/(lastWeek?.inactiveUsers>0?lastWeek?.inactiveUsers:1))*100)});
        }else{
            setInactivePercent({type:"decrease",value:toFixedIfNecessary(((lastWeek?.inactiveUsers-currWeek?.inactiveUsers)/(currWeek?.inactiveUsers>0?currWeek?.inactiveUsers:1))*100)});
        }
   
        if((currWeek?.activeUsers+currWeek?.inactiveUsers)>=(lastWeek?.activeUsers+lastWeek?.inactiveUsers)){
            setTotalPercent({type:"increase",value:toFixedIfNecessary((((currWeek?.activeUsers+currWeek?.inactiveUsers)-(lastWeek?.activeUsers+lastWeek?.inactiveUsers))/((lastWeek?.activeUsers+lastWeek?.inactiveUsers)>0?(lastWeek?.activeUsers+lastWeek?.inactiveUsers):1))*100)});
        }else{
            setTotalPercent({type:"decrease",value:toFixedIfNecessary((((lastWeek?.activeUsers+lastWeek?.inactiveUsers)-(currWeek?.activeUsers+currWeek?.inactiveUsers))/((currWeek?.activeUsers+currWeek?.inactiveUsers)>0?(currWeek?.activeUsers+currWeek?.inactiveUsers):1))*100)});
        }
    }

    const [userData, setUserData] = useState({
        labels:[],
        datasets: [
            {
              label: "Active Users",
              data: weeklyLoginData.map((data:any) => data.activeUsers),
              backgroundColor: [
                "#02B8F6",
              ],
              borderColor: "#02B8F6",
              pointRadius:0,
              borderWidth: 2,
            },
            {
              label: "Inactive Users",
              data: weeklyLoginData.map((data:any) => data.inactiveUsers),
              backgroundColor: [
                "#912FC0",
              ],
              borderColor: "#912FC0",
              pointRadius:0,
              borderWidth: 2,
            },
          ],
    })

    useEffect(() => {
        setUserData({
            labels:weeklyLoginData.map((d:any)=>`${moment(d.fromDate).format('MM/DD/YY')}-${moment(d.toDate).format('MM/DD/YY')}`),
            datasets: [
                {
                label: "Active Users",
                data: weeklyLoginData.map((data:any) => data.activeUsers),
                backgroundColor: [
                    "#02B8F6",
                ],
                borderColor: "#02B8F6",
                pointRadius:0,
                borderWidth: 2,
                },
                {
                label: "Inactive Users",
                data: weeklyLoginData.map((data:any) => data.inactiveUsers),
                backgroundColor: [
                    "#912FC0",
                ],
                borderColor: "#912FC0",
                pointRadius:0,
                borderWidth: 2,
                },
            ],
        })
    }, [weeklyLoginData]);

    return(
        <div className="component-container" style={{height:'45vh'}}>
            <div className="header">
                <h1 className="title">
                    AVERAGE LOGINS ANALYTICS
                </h1>
                <div id="login-analytics-spin">
                    <Spin className="spin-loading" spinning={isFetching}/>
                </div>
            </div>
            <Divider className="divider"/>
            <div className="overview">
                <Row gutter={[16, 16]} >
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                        <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={PersonIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{loginOverViewData?.activeUsers??'No Data'}</h1>
                                    <p>ACTIVE USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={activePercent.type==="increase"?"blue":"red"}>{activePercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {activePercent.type==="increase"?"+":"-"}{isNaN(activePercent.value)?0:activePercent.value}% </span>
                                {activePercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={12} xl={8}>
                    <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={PersonAlertIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{loginOverViewData?.inactiveUsers??'No Data'}</h1>
                                    <p>INACTIVE USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={inactivePercent.type==="increase"?"blue":"red"}>{inactivePercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {inactivePercent.type==="increase"?"+":"-"}{isNaN(inactivePercent.value)?0:inactivePercent.value}% </span>
                                {inactivePercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={24} lg={24} xl={8}>
                    <div className="section">
                            <div className="top">
                                <div className="white-sq">
                                    <img src={GroupIcon}/>
                                </div>
                                <div className="data">
                                    <h1>{isNaN(loginOverViewData?.activeUsers+loginOverViewData?.inactiveUsers)?'No Data':(loginOverViewData?.activeUsers+loginOverViewData?.inactiveUsers)}</h1>
                                    <p>TOTAL USERS</p>
                                </div>
                            </div>
                            <div className="bottom">
                                <span className={totalPercent.type==="increase"?"blue":"red"}>{totalPercent.type==="increase"?<RiseOutlined/>:<FallOutlined/>} {totalPercent.type==="increase"?"+":"-"}{isNaN(totalPercent.value)?0:totalPercent.value}% </span>
                                {totalPercent.type==="increase"?"more":"less"} than last week
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            {(isFetching)?(
                    <div id="login-analytics-graph-spin">
                        <Spin className="spin-loading" spinning={isFetching}/>
                    </div>
                ):(
            <div style={{height:'100%'}}>
                {userData?.labels?.length===0?(
                    <div className="no-data-graph"><img src={NoData} className="no-data-graph"/></div>
                ):(
                <Line 
                    data={userData} 
                    redraw 
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            myScale: {
                                position: 'right',
                            },
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        responsive: true,
                        interaction: {
                        mode: 'index',
                        intersect: false,
                        },
                        plugins:{
                            legend:{
                                align:'start',
                                labels:{
                                    boxWidth:5,
                                    boxHeight:5,
                                    usePointStyle:true,
                                }
                            },
                            datalabels: {
                                display:false,
                            },
                            tooltip:{
                                // external:customTooltip,
                                enabled: false,
                                external: (context) => {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    const tooltipModel = context.tooltip;
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = '0';
                                        return;
                                    }

                                    // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
                                    tooltipEl.classList.remove('below', 'no-transform');


                                    // Set HTML & Data
                                    if (tooltipModel.body) {
                                        // console.log(tooltipModel)
                                        const Active = tooltipModel.dataPoints[0];
                                        const Inactive = tooltipModel.dataPoints[1];
                                        const innerHtml = `
                                        <div class="analytics-tooltip">
                                            <div class="title">
                                                ${tooltipModel.title}
                                            </div>
                                            <div class="total"><span>Total Users</span><span>${Number(Active.raw)+Number(Inactive.raw)}</span></div>
                                            <div class="point-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:${tooltipModel.labelColors[0].backgroundColor}"></span>
                                                        <span>Active User</span>
                                                    </div>
                                                    <p class="description">Users who already logged or added events from the last 2 days.</p>
                                                </div>
                                                <p class="point-total" style="color:${tooltipModel.labelColors[0].backgroundColor}">${Active.formattedValue}</p>
                                            </div>
                                            <div class="point-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:${tooltipModel.labelColors[1].backgroundColor}"></span>
                                                        <span>Inactive User</span>
                                                    </div>
                                                    <p class="description">Users with no activity from the last 2 days.</p>
                                                </div>
                                                <p class="point-total" style="color:${tooltipModel.labelColors[1].backgroundColor}">${Inactive.formattedValue}</p>
                                            </div>
                                        </div>
                                    `;

                                        tooltipEl.querySelector('table')!.innerHTML = innerHtml;
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = '1';
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            }
                        },
                    }}
                    
                />
            )}

            </div>
                )}
            {/* <div className="construction">
                Oops! This feature is under construction.
            </div> */}
        </div>
    )
}

const mapStateToProps = (state: {
    auth: any;
    userIsBack: boolean;
    googleAccessToken: string;
  }) => ({
    userIsBack: state.userIsBack,
    googleAccessToken: state.auth?.googleAccessToken,
  });
export default connect(mapStateToProps, {
    getLoginOverview,
    getWeeklyLoginOverview,
})(LoginsAnalytics);
