import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
export const getMemberList = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/member` +
        `?memberType=${params.memberType}&page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(
          params.searchString ?? ""
        )}&status=${params.status}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getMemberDetails = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/member/${params.memberId}`);
    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add school user
export const addSchoolUser = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/admin/`,
      formData,
      config
    );

    dispatch(setAlert("Success", "School User Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

//add school user bulk

export const addSchoolUserBulk = (formData, params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Member/admin/upload?schoolId=${params.schoolId}`,
      formData,
      config
    );

    dispatch(setAlert("Success", "School Users Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", "Check the CSV file", "warning"));
    }
    return response;
  }
};

export const updateAdminUser = (formData, memberId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Member/admin/${memberId}`,
      formData,
      config
    );

    dispatch(
      setAlert("Success", "School Admin User Successfully updated", "success")
    );
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const deleteMember = (memberId) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`/api/Member/${memberId}`);

    dispatch(setAlert("Success", "Member Set To Inactive", "success"));
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
  }
};

export const getSchoolList = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/School/list`);

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getCategoryList = () => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/groupcategory/list`);

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getSchoolListPaged = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  let searchString = !params.searchString
    ? ""
    : `&searchString=${params.searchString}`;
  try {
    const res = await axiosApiInstance.get(
      `/api/School` + `?Page=${page}&PageSize=${params.pageSize}` + searchString
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add school user
export const addSchool = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(`/api/School/`, formData, config);

    dispatch(setAlert("Success", "School Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateSchool = (formData, schoolId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/School/${schoolId}`,
      formData,
      config
    );

    dispatch(setAlert("Success", "School updated", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const deleteSchool = (id) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`/api/School/${id}`);

    dispatch(setAlert("Success", "School Deactivated", "success"));
    return res;
  } catch (err) {
    if (err.response.status === 400) {
      dispatch(setAlert("Warning", err.response.data.error, "warning"));
    } else {
      dispatch(
        setAlert("Error", "Something went wrong, try again later", "error")
      );
    }

    return err.response;
  }
};
