import { v4 as uuidv4 } from "uuid";
import { SET_ALERT, REMOVE_ALERT } from "./types";

export const setAlert = (title, message, alertType, data) => (dispatch) => {
  {
    const id = uuidv4();
    dispatch({
      type: SET_ALERT,
      payload: { title, message, alertType, id, data },
    });

    dispatch({ type: REMOVE_ALERT, payload: id });
  }
};
