import moment from "moment";
import { Button, Dropdown, Form, Input, Skeleton } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { getMyNotifications } from "../../../actions/profile";
import {
  handleNotificationActions,
  executeAction,
  getNotificationUrl,
} from "../../../actions/notifications";
import { CREATED, OK } from "../../../helpers/statuscodes";

const menu = (
  <div></div>
  // <Menu
  //   className="overlay-item-controls"
  //   items={[
  //     {
  //       label: "Mark all as read",
  //       key: "mark-all-as-read",
  //       icon: <EyeOutlined />,
  //     },
  //     {
  //       label: "Notification Settings",
  //       key: "notif-settings",
  //       icon: <SettingOutlined />,
  //     },
  //     {
  //       label: "Open Notifications",
  //       key: "open-notifs",
  //       icon: <BellOutlined />,
  //     },
  //   ]}
  // />
);

interface NotifItem {
  activityLogId: string;
  recordId: string;
  fullName: string;
  profilePicture: string;
  description: string;
  notificationType: string;
  notificationTitle: string;
  notificationMessage: string;
  notificationActions: string;
  createdOn: string;
  isSeen: boolean;
  state: boolean;
  isDeclined: boolean;
  loading: boolean;
  actionCompleteText: string;
  actionTaken: string;
}

interface NotifState {
  recordId: string;
  state: boolean;
}

const NotificationList = (props: {
  fullWidth: boolean;
  handleHideNotification: Function;
  getMyNotifications: Function;
  handleNotificationActions: Function;
  dropdownVisible: boolean;
  executeAction: Function;
  auth: any;
  flutterConnect: boolean
}) => {
  const [declineForm] = Form.useForm();
  const listInnerRef: any = useRef();
  const {
    fullWidth,
    handleHideNotification,
    getMyNotifications,
    handleNotificationActions,
    dropdownVisible,
    executeAction,
    auth: { member },
    flutterConnect
  } = props;
  const [activeNotifControl, setActiveNotifControl] = useState("");
  const [isListLoading, setIsListLoading] = useState(false);
  const [data, setData] = useState<NotifItem[]>([]);
  // const [dataState, setDataState] = useState<NotifState[]>([]);

  let navigate = useNavigate();

  const initialPagination = {
    current: 1,
  };
  const [pagination, setPagination] = useState(initialPagination);

  const handleNotifItemStateChange = (notifData: NotifItem, state: boolean) => {
    setData(
      data.map((filterItem: any) => {
        if (filterItem.activityLogId === notifData.activityLogId) {
          return {
            ...filterItem,
            state,
          };
        } else {
          return filterItem;
        }
      })
    );
  };

  const handleAction = async (notifItem: any, actionType: string) => {
    const parsedActions = JSON.parse(notifItem.notificationActions);
    let itemActions = parsedActions.filter((data: any) => {
      return data.label === actionType;
    });
    // console.log(itemActions);
    setData(
      data.map((filterItem: any) => {
        if (filterItem.activityLogId === notifItem.activityLogId) {
          return {
            ...filterItem,
            loading: true,
          };
        } else {
          return filterItem;
        }
      })
    );

    const payload = JSON.stringify({
      //notificationType: alert.data.notificationType,
      activityLogId: notifItem.activityLogId,
      status: Number(itemActions[0].status),
      //recordId: alert.data.recordId,
      //memberId: alert.data.memberId,
    });

    let result = await executeAction(payload);
    if (result.status == OK || result.status == CREATED) {
      setData(
        data.map((filterItem: any) => {
          if (filterItem.activityLogId === notifItem.activityLogId) {
            return {
              ...result.data,
              loading: false,
              actionCompleteText: "Approved",
            };
          } else {
            return filterItem;
          }
        })
      );
    } else {
      setData(
        data.map((filterItem: any) => {
          if (filterItem.activityLogId === notifItem.activityLogId) {
            return {
              ...filterItem,
              loading: false,
            };
          } else {
            return filterItem;
          }
        })
      );
    }
  };

  const onDeclineForm = async (formData: any) => {
    const parsedActions = JSON.parse(formData.data.notificationActions);
    let itemActions = parsedActions.filter((data: any) => {
      return data.label === "Decline";
    });
    setData(
      data.map((filterItem: any) => {
        if (filterItem.activityLogId === formData.data.activityLogId) {
          return {
            ...filterItem,
            loading: true,
          };
        } else {
          return filterItem;
        }
      })
    );
    const payload = JSON.stringify({
      //notificationType: alert.data.notificationType,
      activityLogId: formData.data.activityLogId,
      status: Number(itemActions[0].status),
      remarks: formData.remarks,
      //recordId: alert.data.recordId,
      //memberId: alert.data.memberId,
    });
    let result = await executeAction(payload);
    if (result.status == OK || result.status == CREATED) {
      setData(
        data.map((filterItem: any) => {
          if (filterItem.activityLogId === formData.data.activityLogId) {
            return {
              ...result.data,
              loading: false,
              actionCompleteText: "Declined",
              state: false,
            };
          } else {
            return filterItem;
          }
        })
      );
    } else {
      setData(
        data.map((filterItem: any) => {
          if (filterItem.activityLogId === formData.data.activityLogId) {
            return {
              ...filterItem,
              loading: false,
            };
          } else {
            return filterItem;
          }
        })
      );
    }
  };

  const fetchList = async (paginationParam: any) => {
    if (paginationParam.current === 1) {
      setData([]);
    }

    let params = {
      page: paginationParam.current,
      pageSize: 10,
      searchString: "",
    };
    const res = await getMyNotifications(params);

    let items = res.data.items;
    if (items.length !== 0) {
      if (paginationParam.current !== 1) {
        const formattedData = items.map((item: NotifItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setData(data.concat(formattedData));
      } else {
        const formattedData = items.map((item: NotifItem) => {
          return {
            ...item,
            state: false,
            isDeclined: false,
            loading: false,
            actionCompleteText: null,
          };
        });
        setData(formattedData);
      }
      setPagination({
        current: paginationParam.current + 1,
      });
    }
    setIsListLoading(false);
  };

  const navigateToPage = (notifData: NotifItem) => {
    let url = getNotificationUrl(
      member.memberType,
      notifData.notificationType,
      notifData.recordId
    );

    navigate(url);
  };

  useEffect(() => {
    setIsListLoading(true);
    setPagination(initialPagination);
    fetchList(initialPagination);
  }, []);

  //if viewing is not fullview
  const onScroll = () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight === scrollHeight) {
        console.log(pagination);
        fetchList(pagination);
      }
    }
  };

  const windowOnScroll = () => {
    // console.log(ev);
    if (window.innerHeight + window.scrollY >= (flutterConnect ? document.body.scrollHeight - 1  : document.body.scrollHeight)) {
      fetchList(pagination);
    }
  };

  useLayoutEffect(() => {
    if (fullWidth) {
      window.addEventListener("scroll", windowOnScroll);
    }
    return () => {
      window.removeEventListener("scroll", windowOnScroll);
    };
  }, [pagination]);

  useEffect(() => {
    if (dropdownVisible) {
      setIsListLoading(true);
      setPagination(initialPagination);
      fetchList(initialPagination);
    }
  }, [dropdownVisible]);

  return (
    <div
      className={`notification-widget ${
        fullWidth && "w-100-i notification-widget-full no-shadow no-bg"
      }`}
    >
      <div className="notification-widget-header">
        <h5>Most Recent</h5>
        {!fullWidth && (
          <Link onClick={() => handleHideNotification()} to={"notifications"}>
            See all
          </Link>
        )}
      </div>
      <ul
        className={`notif-list ${fullWidth && "no-scroll-auto-height"}`}
        {...(!fullWidth ? { onScroll: () => onScroll() } : "")}
        ref={listInnerRef}
      >
        {data.map((notifData: NotifItem) => {
          return notifData && notifData.notificationActions ? (
            // className="notif-active"
            <li
              className={`${fullWidth && "mb-3 bg-white"} ${
                notifData.state === true && "notif-active"
              }`}
              key={notifData.activityLogId}
              onClick={(e: any) => navigateToPage(notifData)}
            >
              {/* <div
                className="overlay-button"
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <div
                    className="overlay-item"
                    style={{ marginTop: fullWidth ? "13px" : "" }}
                  >
                    <EllipsisOutlined />
                  </div>
                </Dropdown>
              </div> */}
              <div className="notif-main">
                {notifData.profilePicture && notifData.profilePicture !== "" ? (
                  <div className="user-avatar">
                    <img
                      height="auto"
                      width="42px"
                      src={notifData.profilePicture}
                    />
                  </div>
                ) : (
                  <div className={`${fullWidth && "mr-2"}`}>
                    <div className="no-avatar">
                      <span>
                        {notifData &&
                          notifData.fullName &&
                          notifData.fullName.charAt(0)}
                      </span>
                    </div>
                  </div>
                )}
                
                <div className="notif-format">
                  <div className={`notif-content ${!fullWidth && "flex-grow-1"}`}>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: notifData.notificationMessage,
                      }}
                      >
                    </div>
                    <div className={`notif-timestamp ${fullWidth && "mt-3"}`}>
                      <small>{moment(notifData.createdOn).fromNow()}</small>
                    </div>
                    {!fullWidth &&
                      (!notifData.actionTaken ? (
                        <div className="notif-controls">
                          {notifData.notificationActions &&
                            JSON.parse(notifData.notificationActions).map(
                              (notifActions: any) => {
                                if (notifActions && notifActions.additionalData) {
                                  //this means that this data is for declining
                                  return (
                                    <Button
                                      className="btn-radius"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleNotifItemStateChange(
                                          notifData,
                                          true
                                        );
                                      }}
                                      disabled={notifData.state === true}
                                    >
                                      {notifActions.label && notifActions.label}
                                    </Button>
                                  );
                                } else {
                                  return (
                                    <Button
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        handleAction(notifData, "Accept");
                                      }}
                                      className="text-semibold ant-btn-primary btn-radius mr-3"
                                      disabled={
                                        notifData.state === true ||
                                        notifData.loading === true
                                      }
                                      loading={
                                        notifData.loading === true &&
                                        notifData.state === false
                                      }
                                    >
                                      {notifActions.label && notifActions.label}
                                    </Button>
                                  );
                                }
                              }
                            )}
                        </div>
                      ) : (
                        <p className="text-bold">
                          {notifData.actionTaken && notifData.actionTaken}
                        </p>
                      ))}
                    {notifData.state === true && (
                      <div
                        className="notif-form"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <p>Reasons for declining the request</p>
                        <Form
                          name={`declineForm_${notifData.activityLogId}`}
                          onFinish={onDeclineForm}
                        >
                          <Form.Item
                            name={`remarks`}
                            initialValue=""
                            rules={[
                              {
                                required: true,
                                message: "Please input your reason!",
                              },
                            ]}
                          >
                            <TextArea rows={3} placeholder="Input reason" />
                          </Form.Item>
                          <Form.Item
                            name="data"
                            hidden={true}
                            initialValue={notifData}
                          >
                            <Input />
                          </Form.Item>
                          <div className="notif-controls">
                            <Button
                              htmlType="submit"
                              className="text-semibold ant-btn-primary btn-radius mr-3"
                              loading={notifData.loading === true}
                            >
                              Submit
                            </Button>
                            <Button
                              disabled={notifData.loading === true}
                              className="btn-radius"
                              onClick={(e) => {
                                e.stopPropagation();
                                handleNotifItemStateChange(notifData, false);
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                  {fullWidth &&
                    (!notifData.actionTaken ? (
                      <div className="notif-controls ml-auto mr-5 pl-8">
                        {notifData.notificationActions &&
                          JSON.parse(notifData.notificationActions).map(
                            (notifActions: any) => {
                              if (notifActions && notifActions.additionalData) {
                                //this means that this data is for declining
                                return (
                                  <Button
                                    className="btn-radius"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleNotifItemStateChange(notifData, true);
                                    }}
                                    disabled={notifData.state === true}
                                  >
                                    {notifActions.label && notifActions.label}
                                  </Button>
                                );
                              } else {
                                return (
                                  <Button
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleAction(notifData, "Accept");
                                    }}
                                    className="text-semibold ant-btn-primary btn-radius mr-3"
                                    disabled={
                                      notifData.state === true ||
                                      notifData.loading === true
                                    }
                                    loading={
                                      notifData.loading === true &&
                                      notifData.state === false
                                    }
                                  >
                                    {notifActions.label && notifActions.label}
                                  </Button>
                                );
                              }
                            }
                          )}
                      </div>
                    ) : (
                      <p className="text-bold ml-auto mt-4 mr-3 ">
                        {notifData.actionTaken && notifData.actionTaken}
                      </p>
                    ))}
                </div>
                {!notifData.isSeen && (
                  <div
                    className="mt-3"
                    style={{ marginTop: fullWidth ? "22px" : "" }}
                  >
                    <div className="notif-status"></div>
                  </div>
                )}
              </div>
            </li>
          ) : (
            <li
              className="mb-3"
              onClick={() => {
                navigateToPage(notifData);
              }}
            >
              {/* <div
                className="overlay-button"
                onClick={(e) => e.stopPropagation()}
              >
                <Dropdown
                  overlay={menu}
                  placement="bottomRight"
                  trigger={["click"]}
                >
                  <div
                    className="overlay-item"
                    style={{ marginTop: fullWidth ? "13px" : "" }}
                  >
                    <EllipsisOutlined />
                  </div>
                </Dropdown>
              </div> */}
              <div className="notif-main">
                {notifData.profilePicture && notifData.profilePicture !== "" ? (
                  <div className="user-avatar">
                    <img
                      height="auto"
                      width="42px"
                      src={notifData.profilePicture}
                    />
                  </div>
                ) : (
                  <div>
                    <div className="no-avatar">
                      <span>
                        {notifData &&
                          notifData.fullName &&
                          notifData.fullName.charAt(0)}
                      </span>
                    </div>
                  </div>
                )}
                <div className="notif-content">
                  {/* {fullWidth && <h4>Vicki Chen</h4>} */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: notifData.notificationMessage,
                    }}
                  ></div>
                  <div className="notif-timestamp">
                    <small>{moment(notifData.createdOn).fromNow()}</small>
                  </div>
                  <div className="notif-invite-status">
                    {notifData.notificationType==="DeclineEventInvite" ? (
                      <small className="red">Invite Declined</small>
                      ):notifData.notificationType==="AcceptEventInvite"&&(
                        <small>Invite Accepted</small>
                      )}
                  </div>
                </div>
                {!notifData.isSeen && (
                  <div className={`mt-3 ${fullWidth && "ml-auto"}`}>
                    <div className="notif-status"></div>
                  </div>
                )}
              </div>
            </li>
          );
        })}

        {isListLoading && (
          <Fragment>
            <li>
              <div className="notif-main">
                <Skeleton avatar paragraph={{ rows: 2 }} />
              </div>
            </li>
            <li>
              <div className="notif-main">
                <Skeleton avatar paragraph={{ rows: 2 }} />
              </div>
            </li>
            <li>
              <div className="notif-main">
                <Skeleton avatar paragraph={{ rows: 2 }} />
              </div>
            </li>
          </Fragment>
        )}
      </ul>
      {!fullWidth && (
        <div className="notification-footer">
          <div className="notification-footer-container">
            <Link onClick={() => handleHideNotification()} to={"notifications"}>
              View All Notifications
            </Link>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: { auth: any, flutterConnect: boolean }) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect
});

export default connect(mapStateToProps, {
  getMyNotifications,
  handleNotificationActions,
  executeAction,
})(NotificationList);
