import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
  Skeleton,
} from "antd";

import { Fragment, useEffect, useState } from "react";
import ContactItem from "./ContactItem";
import { connect } from "react-redux";
import {
  addFriend,
  getFriendApi,
  getSuggestedFriendsApi,
  searchFriendsApi,
  blockFriendApi,
} from "../../../actions/social";
import { ACTIVE, PENDING } from "../../../enums/friendTypes";
import NoPendingReq from "../../../assets/images/illustration/no-pending-req.svg";
import { contactsColors } from "../../../helpers/contactsColors";

const SuggestedFriends = (props: {
  getFriendApi: Function;
  getSuggestedFriendsApi: Function;
  addFriend: Function;
  searchTerm: String;
  searchFriendsApi: Function;
  blockFriendApi: Function;
  auth: any;
}) => {
  const {
    getFriendApi,
    getSuggestedFriendsApi,
    addFriend,
    searchTerm,
    searchFriendsApi,
    blockFriendApi,
    auth,
  } = props;
  const [friendsData, setFriendsData] = useState<any[]>([]);
  const [originalFriendsData, setOriginalFriendsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  // useEffect(() => {
  //   fetchFriends();
  // }, []);

  const fetchFriends = async () => {
    setIsLoading(true);
    let data = await getSuggestedFriendsApi();
    const friendDataWithColor = data.data.map((friend: any) => {
      return {
        ...friend,
        color:
          contactsColors[Math.floor(Math.random() * contactsColors.length)],
        loading: false,
        mutualFriends: friend.mutualFriends.map((mutual: any) => {
          return {
            ...mutual,
            color:
              contactsColors[Math.floor(Math.random() * contactsColors.length)],
            loading: false,
          };
        }),
      };
    });
    setFriendsData(friendDataWithColor);
    setOriginalFriendsData(friendDataWithColor);
    setIsLoading(false);
  };

  const addFriendHandler = async (emailAddress: String) => {
    // const updatedItems = friendsData.map((item) => {
    //   if (item.emailAddress === emailAddress) {
    //     return { ...item, loading: true };
    //   }
    //   return item;
    // });
    // setFriendsData(updatedItems);
    const filteredItems = friendsData.filter(
      (item: any) => item.emailAddress !== emailAddress
    );
    let data = addFriend(emailAddress);
    setFriendsData(filteredItems);
  };

  const blockFriendHandler = async (emailAddress: String) => {
    let data = blockFriendApi(emailAddress);
    data = data.data;
    // let emailToRemove =
    //   auth.member.emailAddress != data.fromEmail
    //     ? data.fromEmail
    //     : data.toEmail;
    const filteredItems = friendsData.filter(
      (item: any) => item.emailAddress != emailAddress
    );
    console.log(filteredItems);
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  const yalieSearchHandler = async (searchTerm: String) => {
    let data = await searchFriendsApi(searchTerm);
    setFriendsData(data.data);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    if (searchTerm) {
      const handler = setTimeout(() => {
        setDebouncedTerm(searchTerm);
      }, 1000); // 2000ms = 2 seconds
      return () => {
        clearTimeout(handler);
      };
    } else {
      setDebouncedTerm("");
      if (originalFriendsData.length > 0) {
        setFriendsData(originalFriendsData);
        setIsLoading(false);
      } else {
        fetchFriends();
      }
    }
  }, [searchTerm]);

  useEffect(() => {
    if (debouncedTerm) {
      yalieSearchHandler(debouncedTerm);
    }
  }, [debouncedTerm]);
  return (
    <div className="contact-list-container">
      <div className="contact-list-container">
        <h3 className="list-title d-flex align-items-center">
          Suggested{" "}
          <Badge showZero className="ml-1" count={friendsData.length} />
        </h3>
      </div>
      <div className="contact-list">
        <Skeleton loading={isLoading} active avatar></Skeleton>
        {!isLoading &&
          friendsData &&
          friendsData.map((friend) => (
            <ContactItem
              key={friend.id}
              data={friend}
              addFriendHandler={addFriendHandler}
              blockFriendHandler={blockFriendHandler}
            />
          ))}

        {friendsData.length == 0 && !isLoading && (
          <div className="d-flex justify-content-center d-flex-column align-items-center mt-5">
            <img src={NoPendingReq} width="auto" height="400px" />
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={12}
              style={{ margin: "0 auto", flex: 0 }}
            >
              <div className="d-flex align-items-center d-flex-column">
                <h1 className="mt-4 mb-2 text-center">
                  No Suggestions at the moment
                </h1>
              </div>
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {
  getFriendApi,
  getSuggestedFriendsApi,
  addFriend,
  searchFriendsApi,
  blockFriendApi,
})(SuggestedFriends);
