import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Button, Modal, Carousel } from "antd";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { login } from "../../../actions/auth";
import { setAlert } from "../../../actions/alert"
import jwt_decode from "jwt-decode";
import { BAD_REQUEST, OK } from "../../../helpers/statuscodes";
import { tagLoginDetails } from "../../../utils/googleTagManager";
import ModalUserAgreement from "../../../pages/LoginPage/ModalUserAgreement";
import BreezeW from '../../../assets/images/logos/breeze-white.svg'
import GoogleLoginIcon from '../../../assets/images/continue-with-google.svg'

export const JoinBreezeModal = (props: {
    isOpen: boolean;
    setIsOpen: Function;
    member:any, 
    login: Function, 
    flutterConnect: any,
}) => {
  let navigate = useNavigate();
  const { isOpen, setIsOpen, member, login, flutterConnect } = props;
  const [modalUAisOpen, setModalUAisOpen] = useState(false);
  const [isLoginLoading, setIsLoginLoading] = useState(false);
  const [isLoginSuccess, setIsLoginSuccess] = useState(false);
  const [validationMessages, setValidationMessages]: any = useState([]);
  const [flutterLoginLaunched, setFlutterLoginLaunched]: any = useState(false);
  const [flutterCodeReceived, setFlutterCodeReceived]: any = useState(false);

  const onCancelHandler = () => {
    return;
    // setIsOpen(false); //modal isnt allowed to close
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: tokenResponse => responseMessage(tokenResponse),
    flow: 'auth-code',
    onError: () => setAlert('Error', 'Login failed.', "error"),
    scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events openid',
    // flow: 'auth-code',
    // ux_mode: flutterConnect ? 'redirect' : 'popup',
    include_granted_scopes: false,
    ux_mode: 'redirect',
    redirect_uri: window.location.origin
    // state: 'test'
});

const onAgree = () => {
    setModalUAisOpen(false);
    loginGoogle();
}

const responseMessage = async (response: any) => {
    setValidationMessages(new Array()); //clear validation errors
    setIsLoginLoading(true);

    const res = await login({
      loginType: 1,
      authCode: response.code,
      isMobile: flutterConnect
    });

    setFlutterLoginLaunched(false);
    setFlutterCodeReceived(false);

    if (res.status === OK) {
      let decoded: any = jwt_decode(res.data.token);
      // let userRole: String =
      //   decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
        // setUserRole(userRole);
        
      if (res.data.member)
        tagLoginDetails(res.data.member.memberId);
        setIsLoginSuccess(true);
    } else if (res.response.status === BAD_REQUEST) {
      if (
        res.response.data.error ===
        "Your account has been locked due to 5 consecutive unsuccessful login attempts. Try again in 5 minutes."
      ) {
        //failed login attempt
      }
      setValidationMessages({
        message: res.response.data.error,
      });
    }
    setIsLoginLoading(false);
  };

  return (
    <Modal
      centered
      className="modal-join-breeze"
      visible={isOpen}
      onCancel={onCancelHandler}
      closable={false}
      footer={null}
    >
        <ModalUserAgreement
            isOpen={modalUAisOpen}
            setIsOpen={setModalUAisOpen}
            onAgree={onAgree}
        />
        <div className="up">
            <img src={BreezeW} className="logo"/>
            <h3>Never Miss an event again</h3>
        </div>
        <div className="down">
            <p>Please sign-in using your school email</p>
            <img src={GoogleLoginIcon} onClick={() => setModalUAisOpen(true)} className="login"/>
            <p className="other" onClick={()=>navigate("/")}>Other Log-in Options</p>
        </div>
    </Modal>
  );
};


const mapStateToProps = (state: {
    auth: any;
    flutterConnect: any;
  }) => ({
    member: state.auth?.member,
    flutterConnect: state.flutterConnect,
    googleAccessToken: state.auth?.googleAccessToken,
  });

export default connect(mapStateToProps, { login, })(JoinBreezeModal);
