import { ReactNode, useEffect, useState } from "react";
import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Space,
  Tag,
  Input,
  Modal,
} from "antd";
import {
  CloseOutlined,
  SearchOutlined,
  ClockCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { connect } from "react-redux";
import { setRefreshCalendar } from "../../../actions/refreshCalendar";
import InterestsModal from "../calendar/InterestsModal";
import PersonAdd from "../../../assets/images/icons/person_add_b.svg";
import { updateProfileInterest } from "../../../actions/profile";
import { Link } from "react-router-dom";

const { CheckableTag } = Tag;
const PageHeaderWithInterest = (props: {
  children: ReactNode;
  member: any;
  interests: any;
  updateProfileInterest: Function;
}) => {
  const { children, member, interests, updateProfileInterest } = props;
  const [searchString, setSearchString] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalInterestsOpen, setIsModalInterestsOpen] = useState(false);
  const [isModalTimeOpen, setIsModalTimeOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isImportContactsModalOpen, setIsImportContactsModalOpen] =
    useState(false);
  const [selectedInterest, setSelectedInterest]: any = useState(
    member?.interests ?? []
  );
  const [selectedTags, setSelectedTags] = useState<any[]>([]);

  const times = [
    { name: "Morning", slot: "5:00 am - 11:59 am" },
    { name: "Afternoon", slot: "12:00 pm - 5:59 pm" },
    { name: "Night", slot: "6:00 pm - 11:59 pm" },
  ];

  const handleChange = (tag: string, checked: boolean) => {
    if (selectedTags.length > 4 && checked == true) {
      tagError();
    } else {
      const nextSelectedTags = checked
        ? [...selectedTags, tag]
        : selectedTags.filter((t: any) => t !== tag);
      setSelectedTags(nextSelectedTags);
    }
  };

  const handleChangeInterest = (interest: string, checked: boolean) => {
    // if(selectedInterest.length>4 && checked==true){
    //   tagError();
    // }else{
    const nextSelectedInterests = checked
      ? [...selectedInterest, interest]
      : selectedInterest.filter((t: any) => t !== interest);
    setSelectedInterest(nextSelectedInterests);
    // }
  };

  const tagError = () => {
    messageApi.open({
      type: "error",
      content: "Maximum of 5 tags only!",
    });
  };

  const InterestFilter = () => {
    return (
      <div className="tag-filter">
        <label className="label" onClick={() => setIsModalInterestsOpen(true)}>
          <h3>Interest</h3>
        </label>
        <div className="tag-input">
          <div className="left-area">
            <Button
              className="searchbtn"
              onClick={() => setIsModalInterestsOpen(true)}
            >
              <SearchOutlined />
            </Button>
            <Space size={[0, 8]}>
              {selectedInterest.slice(0, 2).map((i: any) => (
                <CheckableTag
                  key={i?.interestId}
                  checked={selectedInterest.includes(i)}
                  onChange={(checked) => handleChangeInterest(i, checked)}
                >
                  {i?.name}
                </CheckableTag>
              ))}
              {selectedInterest.length > 2 && (
                <Tag onClick={() => setIsModalInterestsOpen(true)}>
                  +{selectedInterest.length - 2}
                </Tag>
              )}
            </Space>
            {selectedInterest.length === 0 && (
              <p className="placeholder">Select one or more interests</p>
            )}
          </div>
          {selectedInterest.length > 0 && (
            <Button
              className="closebtn"
              onClick={() => setSelectedInterest([])}
            >
              <CloseCircleOutlined />
            </Button>
          )}
        </div>
      </div>
    );
  };

  const updateInterest = async () => {
    const res = await updateProfileInterest({
      interests: selectedInterest.map((e: any) => e.interestId),
      isSkipValidation: true,
    });
  };

  useEffect(() => {
    updateInterest();
  }, [selectedInterest, selectedTags]);

  return (
    <div className="mini-page-header">
      {contextHolder}
      <div className="container">
        <div className="header-wide">
          <div className="list-filters mr-4">
            <InterestFilter />
          </div>
          <Link to={"/contacts/friend-requests"}>
            <Button
              type="primary"
              shape="round"
              className="add-contact-btn"
              // onClick={() => setIsImportContactsModalOpen(true)}
            >
              <img src={PersonAdd} />
            </Button>
          </Link>
        </div>
        <div className="header-compressed">
          <InterestFilter />
          <Button
            type="primary"
            shape="round"
            className="add-contact-btn"
            onClick={() => setIsImportContactsModalOpen(true)}
          >
            <img src={PersonAdd} />
          </Button>
        </div>
        <div className="header-mobile">
          <Button
            type="primary"
            className="filters-btn"
            onClick={() => setIsModalInterestsOpen(true)}
          >
            Filters
          </Button>
          {selectedInterest.length > 0 && (
            <div className="selected-num">
              {selectedInterest.length} selected
              <Button
                className="closebtn"
                onClick={() => setSelectedInterest([])}
              >
                <CloseCircleOutlined />
              </Button>
            </div>
          )}
        </div>
      </div>
      <InterestsModal
        interests={interests}
        isModalOpen={isModalInterestsOpen}
        setIsModalOpen={setIsModalInterestsOpen}
        setSelectedInterest={setSelectedInterest}
        selectedInterest={selectedInterest}
      />
    </div>
  );
};

const mapStateToProps = (state: {
  auth: any;
  userIsBack: boolean;
  googleAccessToken: string;
}) => ({
  member: state.auth?.member,
  googleAccessToken: state.auth?.googleAccessToken,
  interests: state.auth.member.interests,
});

export default connect(mapStateToProps, {
  setRefreshCalendar,
  updateProfileInterest,
})(PageHeaderWithInterest);
