import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import moment, { Moment } from "moment";
import { connect } from "react-redux";
import { Fragment } from "react";
import {
  getMyEvents,
  getProfileSummary,
  getMyClubsGrouped,
  updateProfileDisplayAll,
} from "../../../actions/profile";
import {
  addEvent,
  getEventTags,
  declineEventInvite,
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getRecommendations,
  getLatest
} from "../../../actions/event";
import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
} from "antd";
import type { CheckboxValueType } from "antd/es/checkbox/Group";
import {
  FilterOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  SearchOutlined,
  CalendarOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { getInterestListNonPaged } from "../../../actions/interest";
import { getClubListNonPaged } from "../../../actions/club";
import { getEventPromotions } from "../../../actions/promotions";
import PageMiniHeader from "./PageMiniHeader";
import BreezeCalendar from "../../calendar/BreezeCalendar";
import CreateEventModal from "./CreateEventModal";
import CreateReminderModal from "./CreateReminderModal";
import StreakModal from "./ModalStreak";
import BannerPromoModal from "./ModalBannerPromotion";
import {
  BAD_REQUEST,
  CREATED,
  OK,
  NO_CONTENT,
} from "../../../helpers/statuscodes";
import { EventInvite } from "../events/EventInvite";
import { getCalendarEvents } from "../../../actions/calendar";
import { updateProfileInterest } from "../../../actions/profile";
import { setRefreshCalendar } from "../../../actions/refreshCalendar";
import { createLoginTrail } from "../../../actions/analytics";
import { autoAcceptFriend } from "../../../actions/social";

const { Panel } = Collapse;
interface SelectItem {
  label: string;
  value: string;
}
const { RangePicker } = DatePicker;

const dateFormat = "YYYY-MM-DD HH:mm:ss";
const customFormat = (value: any) => `${value.format(dateFormat)}`;
const PAGE_SIZE = 9;

const CalendarPage = (props: {
  searchUpcomingEvents: Function;
  searchSchoolWideEvents: Function;
  getRecommendations: Function;
  getMyEvents: Function;
  getInterestListNonPaged: Function;
  getClubListNonPaged: Function;
  getMyClubsGrouped: Function;
  declineEventInvite: Function;
  interests: any;
  getProfileSummary: any;
  userIsBack: boolean;
  member: any;
  addEvent: Function;
  getEventTags: Function;
  getCalendarEvents: Function;
  googleAccessToken: string;
  updateProfileInterest: Function;
  refreshCalendar: Boolean;
  setRefreshCalendar: Function;
  createLoginTrail: Function;
  getLatest: Function;
  autoAcceptFriend: Function;
  updateProfileDisplayAll: Function;
  getEventPromotions: Function;
}) => {
  const {
    getMyClubsGrouped,
    member,
    addEvent,
    getEventTags,
    getCalendarEvents,
    googleAccessToken,
    updateProfileInterest,
    refreshCalendar,
    setRefreshCalendar,
    createLoginTrail,
    getLatest,
    autoAcceptFriend,
    updateProfileDisplayAll,
    getEventPromotions,
  } = props;
  const [events, setEvents] = useState<EventInvite[]>([]);
  const [allEvents, setAllEvents] = useState<EventInvite[]>([]);
  const [hasMorePage, setHasMorePage] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isFilterShown, setIsFilterShown] = useState(false);
  const [showMoreInterests, setShowMoreInterest] = useState(false);
  const [selectedInterest, setSelectedInterest]: any = useState(
    member?.interests ?? []
  );
  const [interestOptions, setInterestOptions] = useState<SelectItem[]>();
  const [interestList, setInterestList] = useState<any[]>([]);
  const [isInterestLoaded, setIsInterestLoaded] = useState(false);
  const [isLoadingEventThisMonth, setIsLoadingEventThisMonth] = useState(true);
  const [isModalCreateEventOpen, setIsModalCreateEventOpen] = useState(false);
  const [isModalCreateEventLoading, setIsModalCreateEventLoading] =
    useState(false);
  const [isModalCreateReminderOpen, setIsModalCreateReminderOpen] =
    useState(false);
  const [isModalCreateReminderLoading, setIsModalCreateReminderLoading] =
    useState(false);
  const [dateRangeFilter, setDateRangeFilter] = useState<any[]>([]);
  const [currMonth, setCurrMonth] = useState(
    moment(dateRangeFilter[0]).month()
  );
  const [formCreateEvent] = Form.useForm();
  const [api, contextHolder] = notification.useNotification();
  const [clubsUserMods, setClubsUserMods] = useState([]);
  const [eventTags, setEventTags] = useState([]);
  const [selectedTime, setSelectedTime] = useState("");
  const [selectedTags, setSelectedTags] = useState<any[]>([]);
  const [recommendedEvents, setRecommendedEvents] = useState<any[]>([]);
  const [googleEvents, setGoogleEvents] = useState<any[]>([]);
  const [dateFilter, setDateFilter] = useState<any>(null);
  const [calendarView, setCalendarView] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCurrentEvents, setIsLoadingCurrentEvents] = useState(false);
  const [isLoadingPastEvents, setIsLoadingPastEvents] = useState(false);
  const [isFirstCheck, setIsFirstCheck] = useState(true);
  const [isStreakOpen, setIsStreakOpen] = useState(false);
  const [isBannerPromoOpen, setIsBannerPromoOpen] = useState(false);
  const [bannerPromotions, setBannerPromotions] = useState<any>([]);
  const [allOtherPromotions, setAllOtherPromotions] = useState<any>([]);
  const [isLoadingFutureEvents, setIsLoadingFutureEvents] = useState<any>(false);

  const fetchCalendarEvents = async () => {
    const from = moment(dateFilter).startOf("month").startOf("week").utc().toISOString();
    const to = moment(dateFilter).endOf("month").endOf("week").utc().toISOString();

    const googleEvents = await getCalendarEvents({
      accessToken: googleAccessToken,
      startDate: from,
      endDate: to,
    });
    if (googleEvents && googleEvents.data.length >= 0) {
      setGoogleEvents(googleEvents.data);
    }
  };

  const updateInterest = async () => {
    const res = await updateProfileInterest({
      interests: selectedInterest.map((e: any) => e.interestId),
      isSkipValidation: true,
    });
  };

  const getLatestModification = async (firstCheck? :Boolean) => {
    if(firstCheck===false) return; 
    setIsFirstCheck(false);
    const res = await getLatest();
    if(localStorage.getItem("lastModified")!==null && localStorage.getItem("lastModified") !== res.data.modifiedOn){  //if dates dont match
      fetchEvents(true);
    }
    localStorage.setItem('lastModified',res?.data?.modifiedOn); //updates modifiedOn date
  };

  const getRecommendedEvents = async (
    from: Moment | Date | String | undefined,
    to: Moment | Date | String,
    page: Number,
    sort?: boolean,
    ignoreCustomDate?: boolean
  ) => {
    from =
      dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
        ? moment(dateRangeFilter[0], "YYYY MM DD").startOf("day").toISOString()
        : from;

    to =
      dateRangeFilter?.length > 0 && ignoreCustomDate === undefined
        ? moment(dateRangeFilter[1], "YYYY MM DD").endOf("day").toISOString()
        : to;

    if (from === null || to === null) {
      return;
    }

    // Disabled get recommended as per adjustment
    // const result = await props.getRecommendations({
    //   from: from,
    //   to: to,
    //   page: 1,
    //   pageSize: 10,
    //   timeslot: 0
    // });

    // setRecommendedEvents(result.data.items);
  };

  const fetchPromotions = async () => {
    setIsLoading(true);
    let params;
    params = {
    searchString:"",
    page:1,
    pageSize:50,
    };
    let res = await getEventPromotions(params);
    if (res?.data) {
        let all = ((res?.data?.items).filter((p:any)=>moment().isBetween(p.from, p.to)));
        setBannerPromotions(all.filter((p:any)=>p.promotionType===0))
        setAllOtherPromotions(all.filter((p:any)=>p.promotionType!==0))
    }
    setIsLoading(false);
};

  const removeDuplicates = (arr1: any, arr2: any) => {
    const mergedArr = arr1.concat(arr2);
    return mergedArr.filter(
      (v: any, i: any, s: any) =>
        mergedArr.findIndex((e: any) => e.eventId == v.eventId) === i
    );
  };

  const openNotification = () => {
    api.open({
      message: "Search Results",
      description: "No Record Found!",
      duration: 3,
      icon: <SearchOutlined />,
    });
  };
  
  const fetchEvents = async (toUpdate?:Boolean) => {
    const views = {
      day: getTodaySearchParams,
      week: getWeekSearchParams,
      month: getMonthSearchParams,
    };

    const params = (
      views[calendarView as keyof typeof views] ?? views["day"]
    )();

    if(localStorage.getItem((moment(dateFilter).format('MMMMYY')))!==null && (moment(dateFilter).month()===(moment().month()))){     //get saved events from localStorage for current month
      fetchCalendarEvents();  //google events
      let events = JSON.parse(localStorage.getItem((moment(dateFilter).format('MMMMYY')))??"null");
      setEvents(events);
      setAllEvents(events);
      if(toUpdate === undefined || toUpdate!==true){  //wont continue if no need to update
        return;
      }
    };
    
    if (!dateFilter) return;
    
    setIsLoadingCurrentEvents(true);
    
    const current = await searchEvents(params);
    if (current && current.data && current.data.items) {
      setIsLoadingCurrentEvents(false);
      setEvents([...current.data.items]);
      setAllEvents([...current.data.items]);
    }
    fetchCalendarEvents();  //google events
    fetchFutureEvents(params);
    fetchPastEvents(params);
  };

  const fetchPastEvents = async (params: any) => {
    setIsLoadingPastEvents(true);
    const past = await searchEvents({
      ...params,
      from: moment(dateFilter)
        .startOf("month")
        .startOf("week")
        .utc()
        .toISOString(),
      to: moment(params.from).local().startOf("day").utc().toISOString(),
    });
    setIsLoadingPastEvents(false);

    if (!(past && past.data && past.data.items)) return;
    setEvents((current) => removeDuplicates(current, [...past.data.items]));
    setAllEvents((current) => removeDuplicates(current, [...past.data.items]));
  };

  const fetchFutureEvents = async (params: any) => {
    setIsLoadingFutureEvents(true);
    const future = await searchEvents({
      ...params,
      from: moment(params.to).local().endOf("day").utc().toISOString(),
      to: moment(dateFilter).endOf("month").endOf("week").utc().toISOString(),
    });
    setIsLoadingFutureEvents(false);

    if (!(future && future.data && future.data.items)) return;
    setEvents((current) => removeDuplicates(current, [...future.data.items]));
    setAllEvents((current) =>
      removeDuplicates(current, [...future.data.items])
    );
  };

  const getMonthSearchParams = () => ({
    from: moment(dateFilter)
      .startOf("month")
      .startOf("week")
      .utc()
      .toISOString(),
    to: moment(dateFilter).endOf("month").endOf("week").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const getWeekSearchParams = () => ({
    from: moment(dateFilter).startOf("week").utc().toISOString(),
    to: moment(dateFilter).endOf("week").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const getTodaySearchParams = () => ({
    from: moment(dateFilter).startOf("day").utc().toISOString(),
    to: moment(dateFilter).endOf("day").utc().toISOString(),
    page: 1,
    isDesc: false,
    ignoreCustomDate: false,
  });

  const searchEvents = async (params: any) =>
    params.from !== params.to
      ? await props.searchUpcomingEvents(
        {
          ...params,

          pageSize: 2000,
          searchString,
          schoolEventsOnly: false,
          MyEventsOnly: false,

          InterestIds:
            selectedInterest?.length > 0
              ? selectedInterest
                .reduce(
                  (acc: any, e: any) => [
                    ...acc,
                    e.interestId
                    // ...[e.interestId,
                    // ...(e.subInterestIds ?? [])],
                  ],
                  []
                )
                .toString()
              : null,
          memberGroupIds: null,
          Statuses: "-1,0,1,2",
        },
        controller
      )
      : [];

  const fetchEventTags = async () => {
    let data = await getEventTags();
    setEventTags(data.data);
  };

  const fetchInterestList = async () => {
    const data = await props.getInterestListNonPaged();
    let myInterests = props.interests
      ? props.interests.map((data: any) => {
        return data.interestId;
      })
      : [];
    let firstOnList = data.data.filter((item: any) => {
      if (myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
        };
      }
    });
    let nextOnList = data.data.filter((item: any) => {
      if (!myInterests.includes(item.interestId)) {
        return {
          label: item.name,
          value: item.interestId,
          subInterestIds: item.subInterestIds,
        };
      }
    });
    let mergedList = firstOnList.concat(nextOnList).map((item: any) => ({
      label: item.name,
      value: item.interestId,
      subInterestIds: item.subInterestIds,
    }));
    setInterestOptions(mergedList);
    setInterestList(data.data);
    setIsInterestLoaded(true);
  };

  const getClubsUserMods = async () => {
    let params = {
      searchString: "",
      privacyTypes: "",
      interests: "",
    };
    let res = await getMyClubsGrouped(params);
    if (res && res.data) {
      let groupedCLub = res.data.filter(
        (t: any) => t.category === "Joined Clubs"
      )[0];
      let joinedClubs = groupedCLub ? groupedCLub.clubs : [];
      let modClubList = joinedClubs.filter((c: any) =>
        c.moderators?.some((m: any) => m.memberId === member.memberId)
      );
      setClubsUserMods(modClubList);
    }
  };

  const searchFilter = () => {
    let filteredResult = allEvents.filter((e) =>
      e.title.toLowerCase().includes(searchString.toLowerCase())
    );
    if (filteredResult.length === 0 && allEvents.length > 0) {
      openNotification();
    }
    setEvents(filteredResult);
  };

  const moreInterestsTrigger = () => {
    setShowMoreInterest(!showMoreInterests);
  };

  const onChangeInterest = (checkedValues: CheckboxValueType[]) => {
    setSelectedInterest(checkedValues);
  };

  const submitCreateEvent = async (formData: any) => {
    try {
      setIsModalCreateEventLoading(true);
      setIsModalCreateReminderLoading(true);
      const timeZoneId = Intl.DateTimeFormat().resolvedOptions().timeZone;
      let payload = {};
      if (formCreateEvent.getFieldValue("memberGroupId") === undefined) {
        payload = {
          title: formCreateEvent.getFieldValue("title"),
          description: formCreateEvent.getFieldValue("description"),
          locationId: null,
          onlineLink: null,
          eventSeriesType: 0,
          eventType: null,
          interestId: null,
          startDate: formCreateEvent.getFieldValue("date")[0],
          endDate: formCreateEvent.getFieldValue("date")[1],
          memberGroupId: null,
          timeZoneId,
          isReminder: true,
        };
      } else {
        payload = {
          title: formCreateEvent.getFieldValue("title"),
          description: formCreateEvent.getFieldValue("description"),
          locationId: formCreateEvent.getFieldValue("locationId"),
          onlineLink: formCreateEvent.getFieldValue("onlineLink") ?? null,
          eventSeriesType: 0,
          eventType: formCreateEvent.getFieldValue("eventType"),
          interestId: formCreateEvent.getFieldValue("interestId"),
          softSkillScores:
            formCreateEvent.getFieldValue("softSkillScores") !== undefined
              ? Object.values(formCreateEvent.getFieldValue("softSkillScores"))
              : [],
          participants:
            formCreateEvent.getFieldValue("participants") !== undefined
              ? formCreateEvent
                .getFieldValue("participants")
                .map((item: any) => ({
                  id: item.value.split("-")[0],
                  type: parseInt(item.value.split("-")[1]),
                }))
              : [],
          startDate: formCreateEvent.getFieldValue("date")[0],
          endDate: formCreateEvent.getFieldValue("date")[1],
          memberGroupId: formCreateEvent.getFieldValue("memberGroupId"),
          timeZoneId,
          isReminder: false,
        };
      }
      const result = await addEvent(payload);

      if (result.status == OK || result.status == CREATED) {
        setIsModalCreateEventLoading(false);
        setIsModalCreateReminderLoading(false);
        setIsModalCreateEventOpen(false);
        setIsModalCreateReminderOpen(false);
        // fetchEventsByMonth(false);
        fetchEvents();
      } else if (result.status === BAD_REQUEST) {
        if (result.data) {
          message.error(result.data);
        }
        setIsModalCreateEventLoading(false);
        setIsModalCreateReminderLoading(false);
      }
    } catch (err) {
      console.log("Error", err);
    }
  };

  const loadOneSec = () => {
    setIsLoadingEventThisMonth(true);
    setTimeout(() => {
      setIsLoadingEventThisMonth(false);
    }, 1000);
  };

  useEffect(() => {
    fetchEventTags();
    fetchInterestList();
    createLoginTrail();
    if(localStorage.getItem('autoAccept')){
      const refId = localStorage.getItem('autoAccept');
      // localStorage.removeItem('autoAccept');
      autoAcceptFriend(refId);
    }
  }, []);

  useEffect(() => { //check if new login
    // if((localStorage.getItem('lastToken')=== null || localStorage.getItem('lastToken')!==localStorage.getItem('token'))){ //shows every login
    if((localStorage.getItem('lastShown')=== null || localStorage.getItem('lastToken')=== null || (moment(localStorage.getItem('lastShown')).format('DDMMYYY') !== moment().format('DDMMYYY') && localStorage.getItem('lastToken')!==localStorage.getItem('token')))){ //shows on the first login of the day
      let token = localStorage.getItem('token')??"";
      localStorage.setItem('lastToken',token);  //update last token
      localStorage.setItem('lastShown', moment().toString());  //update last shown
      if(member?.loginStreak>1){    //show only if streak is greater than 1
        setIsStreakOpen(true);
      }
      setIsBannerPromoOpen(true);
    }
  }, []);
  

  useEffect(() => {
    searchFilter();
  }, [searchString]);

  useEffect(() => {
    if (!dateFilter) return;
    getLatestModification(isFirstCheck);
    fetchEvents();
  }, [dateFilter]);

  useEffect(() => {
    if (isLoadingCurrentEvents || isLoadingFutureEvents || isLoadingPastEvents)
      setIsLoading(true);
    else setIsLoading(false);
  }, [isLoadingCurrentEvents, isLoadingFutureEvents, isLoadingPastEvents]);

  useEffect(() => {
    if (
      isModalCreateEventOpen === false &&
      isModalCreateReminderOpen === false
    ) {
      formCreateEvent.resetFields();
    }
  }, [isModalCreateEventOpen, isModalCreateReminderOpen]);

  useEffect(() => {
    setEvents([]);
    // fetchEventsByMonth(false);
    updateInterest();
    if (dateFilter) fetchEvents(true);
  }, [selectedInterest, selectedTags]);

  useEffect(() => {
    if (currMonth !== moment(dateRangeFilter[0]).month()) {
      //prevents reloading within the same month
      setEvents([]);
      setCurrMonth(moment(dateRangeFilter[0]).month());
      fetchEvents();
      // fetchEventsByMonth(false);
    }
  }, [dateRangeFilter]);

  useEffect(() => {
    if (refreshCalendar) {
      setRefreshCalendar(false);
      fetchEvents();
      // fetchEventsByMonth(false);
    }
  }, [refreshCalendar]);

  useEffect(() => {
    fetchPromotions();
  }, []);

  const controller = new AbortController();

  Promise.allSettled([fetchEvents]).then(()=>{
    //if previous month exists, delete to save memory except for current month
    if(localStorage.getItem((moment(dateFilter).subtract(1,"month").format('MMMMYY').toString()))!==null && moment(dateFilter).subtract(1,"month").month()!==moment().month()){
      localStorage.removeItem(moment(dateFilter).subtract(1,"month").format('MMMMYY').toString());
    }
    if(dateFilter!==null && dateFilter!==undefined && moment(dateFilter).month()===moment().month()){   //only save current month
      localStorage.setItem((moment(dateFilter).format('MMMMYY')).toString(), JSON.stringify(allEvents));
    }
  })

  return (
    <Fragment>
      {contextHolder}

      <PageMiniHeader
        tags={eventTags}
        interests={interestList}
        filterToggle={<div></div>}
        selectedTime={selectedTime}
        setSelectedTime={setSelectedTime}
        selectedTags={selectedTags}
        setSelectedTags={setSelectedTags}
        selectedInterest={selectedInterest}
        setSelectedInterest={setSelectedInterest}
      >
        <Popover
          placement="bottomLeft"
          content={<div />}
          trigger="click"
          className="filter-sm"
        >
          <Button
            className="filter-toggler mobile-reactive-btn"
            icon={<FilterOutlined />}
            shape="round"
          />
        </Popover>
      </PageMiniHeader>
      <div className="content-wrapper">
        <div className="content-container">
          <Fragment>
            <Spin spinning={isLoadingCurrentEvents} size="large">
              <BreezeCalendar
                isLoading={isLoadingFutureEvents || isLoadingPastEvents}
                selectedTime={selectedTime}
                refresh={fetchEvents}
                // refresh={fetchEventsByMonth}
                selectedInterest={selectedInterest}
                eventsFuture={events}
                promotions={allOtherPromotions}
                eventTags={eventTags}
                recommendedEvents={recommendedEvents}
                googleEvents={googleEvents}
                member={member}
                updateProfile={updateProfileDisplayAll}
                handleCalendarFilterDateChange={async (
                  newDateFilter: any,
                  view: any
                ) => {
                  const parsed = new Date(newDateFilter);

                  setCalendarView(view);
                  setDateFilter((current: any) =>
                    current &&
                      `${current.toLocaleString("en-US", {
                        month: "short",
                        timeZone: "UTC",
                      })}` ===
                      parsed.toLocaleString("en-US", {
                        month: "short",
                        timeZone: "UTC",
                      })
                      ? current
                      : parsed
                  );
                }}
              />
            </Spin>
          </Fragment>
        </div>
      </div>
      <CreateEventModal
        title={"Create Club Event"}
        handleSubmit={submitCreateEvent}
        isOpen={isModalCreateEventOpen}
        setIsOpen={setIsModalCreateEventOpen}
        form={formCreateEvent}
        isLoading={isModalCreateEventLoading}
        clubs={clubsUserMods}
        interests={interestOptions}
        interestList={interestList}
      />
      <CreateReminderModal
        title={"Create Reminder"}
        handleSubmit={submitCreateEvent}
        isOpen={isModalCreateReminderOpen}
        setIsOpen={setIsModalCreateReminderOpen}
        form={formCreateEvent}
        isLoading={isModalCreateReminderLoading}
      />
      <StreakModal
        isOpen={isStreakOpen}
        setIsOpen={setIsStreakOpen}
        days={member?.loginStreak}
      />
      <BannerPromoModal
        isOpen={isBannerPromoOpen && bannerPromotions.length>0}
        setIsOpen={setIsBannerPromoOpen}
        data={bannerPromotions}
      />
    </Fragment>
  );
};
const mapStateToProps = (state: {
  auth: any;
  userIsBack: boolean;
  googleAccessToken: string;
  refreshCalendar: boolean;
}) => ({
  interests: state.auth.member.interests,
  userIsBack: state.userIsBack,
  member: state.auth?.member,
  googleAccessToken: state.auth?.googleAccessToken,
  refreshCalendar: state.refreshCalendar,
});
export default connect(mapStateToProps, {
  searchUpcomingEvents,
  searchSchoolWideEvents,
  getRecommendations,
  getMyEvents,
  getInterestListNonPaged,
  getClubListNonPaged,
  getMyClubsGrouped,
  declineEventInvite,
  getProfileSummary,
  addEvent,
  getEventTags,
  getCalendarEvents,
  updateProfileInterest,
  setRefreshCalendar,
  createLoginTrail,
  getLatest,
  autoAcceptFriend,
  updateProfileDisplayAll,
  getEventPromotions,
})(CalendarPage);
