import { SET_PUSH_DATA, REMOVE_PUSH_DATA } from "../actions/types";

const initialState = [];

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_PUSH_DATA:
      return {
        data: payload
      };
    case REMOVE_PUSH_DATA:
      return {
        data: null
      };
    default:
      return state;
  }
}
