import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { logout } from "../../actions/auth";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal.svg";
const SessionExpiredPage = (props: any) => {
  const { logout } = props;

  let navigate = useNavigate();

  const { fading } = props;
  const [isFading, setIsFading] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsFading(true);
      setTimeout(() => {
        navigate("/login", { replace: true });
        // logout();
      }, 500);
    }, 3000);
  }, []);
  return (
    <div className={`login-success-page ${isFading && "fadeout"}`}>
      <div className={`anim-container fadein ${isFading && "fadeout"}`}>
        <div className="d-flex justify-content-center h-100">
          <div className="my-auto">
            <div className="d-flex-column align-items-center">
              <div>
                <img height="50px" width="auto" src={breezeHorizontalLogo} />
              </div>
              <h1 className="text-head">Your Session has been Expired!</h1>
              <div className="text-container">
                <p className="text-desc mt-2">
                  You will be automatically redirected to the login screen.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect(null, { logout })(SessionExpiredPage);
