import { connect } from "react-redux";
import ModalFullView from "../../member-app/Modals/ModalFullView";
import { updateProfile } from "../../../actions/profile";
import { Fragment, useEffect, useState } from "react";
import illusPwd from "../../../assets/images/illustration/illus-pwd.svg";
import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import AlertContainer from "../../../layouts/AlertContainer";
import { BAD_REQUEST, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import iconCheck from "../../../assets/images/icons/icon-check.svg";
import moment from "moment";
import { Student, StudentString, Teacher, TeacherString } from "../../../helpers/memberTypes";
import { handleFormErrors } from "../../../utils/validation";
import { isEmptyOrSpaces } from "../../../helpers/whitespaces";
import successFrame from "../../../assets/images/illustration/illus-profile.svg";

export interface IUpdateProfileModal {
    isOpen: boolean;
    setIsOpen: Function;
    updateProfile: Function;
    member: any;
}
const dateFormat = "YYYY-MM-DD";
const UpdateProfileModal: React.FC<IUpdateProfileModal> = ({ isOpen, setIsOpen, updateProfile, member }) => {
    const [form] = Form.useForm();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdateComplete, setIsUpdateComplete] = useState(false);
    const [gender, setGender] = useState(1);
    const [validationMessages, setValidationMessages]: any = useState({});

    const onFinish = async (formData: any) => {
        setIsLoading(true);
        let val = {
            ...formData,
            birthDate: moment(formData.birthDate).format(dateFormat),
            memberType:
                formData.memberType === TeacherString
                    ? Teacher
                    : formData.memberType === StudentString && Student,
            gender: gender,
        };
        let res = await updateProfile(val);

        if (res.status == OK) {
            setIsUpdateComplete(true);
            setTimeout(() => {
                setIsUpdateComplete(false);
                setIsOpen(false);
            }, 3000);
        } else if (res.status === BAD_REQUEST) {
            if (res.data) {
                setValidationMessages(handleFormErrors(res.data));
            }
        }

        setIsLoading(false);
    };

    useEffect(() => {
        form.setFieldsValue({
            firstName: member.firstName,
            lastName: member.lastName,
            memberType: member.memberType,
            jobTitle: member.jobTitle,
            schoolName: member.schoolName,
            emailAddress: member.emailAddress,
            birthDate: moment(member.birthDate),
        });
        if (member.gender === "Male") {
            setGender(0);
        } else {
            setGender(1);
        }
    }, [member]);

    return (
        <ModalFullView
            // handleSubmit={handleOk}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isLoading={isLoading}
        >
            <div className="profile-wrapper">
                {!isUpdateComplete && (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col
                            className="gutter-row "
                            xs={{ span: 24 }}
                            md={{ span: 12 }}
                            lg={{ span: 10 }}
                            xl={{ span: 8 }}
                        >
                            <h3 className="text-head">Edit Account Profile</h3>

                            <Form
                                layout="vertical"
                                form={form}
                                onFinish={onFinish}
                                className="custom-alert"
                            >
                                <Form.Item
                                    name="firstName"
                                    label="First Name"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your first name",
                                        },
                                        {
                                            max: 50,
                                            message: "First Name maximum length is 50 characters",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    //resolve to use original rule
                                                    return Promise.resolve();
                                                }
                                                if (!isEmptyOrSpaces(value)) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        new Error("Please input your First Name!")
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Enter first name" />
                                </Form.Item>

                                <Form.Item
                                    name="lastName"
                                    label="Last Name"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your last name",
                                        },
                                        {
                                            max: 50,
                                            message: "Last Name maximum length is 50 characters",
                                        },
                                        () => ({
                                            validator(_, value) {
                                                if (!value) {
                                                    //resolve to use original rule
                                                    return Promise.resolve();
                                                }
                                                if (!isEmptyOrSpaces(value)) {
                                                    return Promise.resolve();
                                                } else {
                                                    return Promise.reject(
                                                        new Error("Please input your Last Name!")
                                                    );
                                                }
                                            },
                                        }),
                                    ]}
                                >
                                    <Input placeholder="Enter last name" />
                                </Form.Item>
                                <Form.Item name="memberType" label="Role">
                                    <Input placeholder="Enter role" disabled />
                                </Form.Item>
                                <Form.Item name="schoolName" label="School">
                                    <Input placeholder="Enter school" disabled />
                                </Form.Item>
                                <Form.Item name="emailAddress" label="Email">
                                    <Input placeholder="Enter email" disabled />
                                </Form.Item>
                                <Form.Item
                                    name="birthDate"
                                    label="Birth Date"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your Birth Date",
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        placeholder="Enter birth date"
                                        format={(value: any) => value.format(dateFormat)}
                                    />
                                </Form.Item>
                                {member.memberType === TeacherString && (
                                    <Fragment>
                                        <Form.Item
                                            name="jobTitle"
                                            label="Job Title"
                                            rules={[
                                                {
                                                    required: true,
                                                    message: "Please enter your job title",
                                                },
                                                {
                                                    max: 50,
                                                    message:
                                                        "Job Title maximum length is 50 characters",
                                                },
                                                () => ({
                                                    validator(_, value) {
                                                        if (!value) {
                                                            //resolve to use original rule
                                                            return Promise.resolve();
                                                        }
                                                        if (!isEmptyOrSpaces(value)) {
                                                            return Promise.resolve();
                                                        } else {
                                                            return Promise.reject(
                                                                new Error("Please input your job title Name!")
                                                            );
                                                        }
                                                    },
                                                }),
                                            ]}
                                        >
                                            <Input placeholder="Enter Job Title" />
                                        </Form.Item>
                                    </Fragment>
                                )}
                                <Form.Item>
                                    <Button
                                        className={`${gender === 0 && "btn-active"}`}
                                        onClick={() => setGender(0)}
                                    >
                                        Male
                                    </Button>
                                    <Button
                                        className={`${gender === 1 && "btn-active"}`}
                                        onClick={() => setGender(1)}
                                    >
                                        Female
                                    </Button>
                                </Form.Item>

                                <Button
                                    loading={isLoading}
                                    htmlType="submit"
                                    className="mt-4 btn-radius"
                                    type="primary"
                                >
                                    Update
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                )}
                {isUpdateComplete && (
                    <div className="profile-success-frame">
                        <img src={successFrame} height="358px" width="auto" />
                        <h1>Update Success!</h1>
                        <h4>You have successfully updated your profile details</h4>
                    </div>
                )}
            </div>
        </ModalFullView >
    )
}

const mapStateToProps = (state: any) => ({
    member: state.auth.member,
});

export default connect(mapStateToProps, {
    updateProfile,
})(UpdateProfileModal);