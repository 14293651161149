import { Row, Col, Tooltip } from "antd";
import moment from "moment";
import NoImg from '../../../../../assets/images/icons/pivot-admin/no-img.svg'

const Event=({data}:any)=>{
    const imgSrc = data?.eventPicture.includes('https') ? data?.eventPicture : `https://yaleconnect.yale.edu${data?.eventPicture}`
    return(
        <div className="event-row">
            <Row>
                <Col span={9}>
                    <div className="main-data">
                        {data?.eventPicture === ""?
                            <div className="img"><img src={NoImg}/></div>
                            :<img src={imgSrc} className="img"/>
                        }
                        <div className="texts">
                            <Tooltip title={data?.title}>
                                <p className="title">{data?.title}</p>
                            </Tooltip>
                            <Tooltip title={data?.location}>
                                <p className="location">{data?.location}</p>
                            </Tooltip>
                            {moment(data?.startDate).isAfter(moment())?
                            <p className="upcoming">upcoming</p>
                            :<p className="past-event">past-event</p>
                            }
                        </div>
                    </div>
                </Col>
                <Col span={4}><div className="other-data">{moment(data?.startDate).format('MM/DD/YYYY')}</div></Col>
                <Col span={6}><div className="other-data">{data?.participantsCount}</div></Col>
                <Col span={5}><div className="other-data">{data?.viewCount}</div></Col>
            </Row>
        </div>
    )
}

export default Event;
