import { connect } from "react-redux";
import ModalFullView from "../../member-app/Modals/ModalFullView";
import { changePassword } from "../../../actions/profile";
import { useState } from "react";
import illusPwd from "../../../assets/images/illustration/illus-pwd.svg";
import { Button, Col, Form, Input, Row } from "antd";
import AlertContainer from "../../../layouts/AlertContainer";
import { BAD_REQUEST, NO_CONTENT, OK } from "../../../helpers/statuscodes";
import { EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import iconCheck from "../../../assets/images/icons/icon-check.svg";

export interface IChangePasswordModal {
    isOpen: boolean;
    setIsOpen: Function;
    changePassword: Function;
}
const ChangePasswordModal: React.FC<IChangePasswordModal> = ({ isOpen, setIsOpen, changePassword }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdatePasswordComplete, setIsUpdatePasswordComplete] =
        useState(false);
    const [validationMessages, setValidationMessages]: any = useState({});
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordForm] = Form.useForm();

    const handleFormErrors = (data: any) => {
        let responseValidation = data && data.errors ? data.errors : data;
        var obj = {};
        for (const [key, value] of Object.entries(responseValidation)) {
            Object.assign(obj, { [key]: value });
        }
        setValidationMessages(obj);
    };

    const onSubmitPasswordForm = async (FormData: any) => {
        setIsLoading(true);
        let res = await changePassword(FormData);

        if (res.status == OK || res.status == NO_CONTENT) {
            setIsUpdatePasswordComplete(true);
            passwordForm.resetFields();
            setTimeout(() => {
                setIsOpen(false);
            }, 3000);
        } else if (res.status === BAD_REQUEST) {
            if (res.data) {
                handleFormErrors(res.data);
            }
        }
        setIsLoading(false);
    };
    return (
        <ModalFullView
            // handleSubmit={handleOk}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isLoading={isLoading}
        >
            <div className="profile-wrapper">
                {!isUpdatePasswordComplete && (
                    <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                        <Col
                            className="gutter-row "
                            xs={{ span: 24 }}
                            md={{ span: 8 }}
                            lg={{ span: 8 }}
                            xl={{ span: 8 }}
                        >
                            <h3 className="text-head">Change Password</h3>
                            <div
                                className={`custom-alert ${validationMessages &&
                                    validationMessages.error &&
                                    "mb-4 has-error"
                                    }`}
                            >
                                {validationMessages && validationMessages.error && (
                                    <AlertContainer data={validationMessages} />
                                )}
                            </div>
                            <Form
                                className="form-modal-fullview"
                                layout="vertical"
                                form={passwordForm}
                                onFinish={onSubmitPasswordForm}
                            >
                                <Form.Item
                                    className="custom-password"
                                    name="password"
                                    label="Old Password"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your old password",
                                        },
                                    ]}
                                >
                                    <Input
                                        type={showPassword ? "text" : "password"}
                                        addonAfter={
                                            !showPassword ? (
                                                <EyeOutlined
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            ) : (
                                                <EyeInvisibleOutlined
                                                    onClick={() => setShowPassword(!showPassword)}
                                                />
                                            )
                                        }
                                    />
                                </Form.Item>
                                <div
                                    className={`mb-4 ${validationMessages &&
                                        validationMessages.NewPassword &&
                                        "has-error"
                                        }`}
                                >
                                    {validationMessages && validationMessages.Password && (
                                        <AlertContainer data={validationMessages.Password} />
                                    )}
                                </div>
                                <Form.Item
                                    className="custom-password"
                                    name="newPassword"
                                    label="New Password"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please enter your new password",
                                        },
                                    ]}
                                >
                                    <Input
                                        type={showNewPassword ? "text" : "password"}
                                        addonAfter={
                                            !showNewPassword ? (
                                                <EyeOutlined
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            ) : (
                                                <EyeInvisibleOutlined
                                                    onClick={() => setShowNewPassword(!showNewPassword)}
                                                />
                                            )
                                        }
                                    />
                                </Form.Item>
                                <div
                                    className={`mb-4 ${validationMessages &&
                                        validationMessages.NewPassword &&
                                        "has-error"
                                        }`}
                                >
                                    {validationMessages && validationMessages.NewPassword && (
                                        <AlertContainer data={validationMessages.NewPassword} />
                                    )}
                                </div>
                                <Form.Item
                                    className="custom-password"
                                    name="confirmPassword"
                                    label="Confirm Password"
                                    required
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please confirm password",
                                        },
                                    ]}
                                >
                                    <Input
                                        type={showConfirmPassword ? "text" : "password"}
                                        addonAfter={
                                            !showConfirmPassword ? (
                                                <EyeOutlined
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                />
                                            ) : (
                                                <EyeInvisibleOutlined
                                                    onClick={() =>
                                                        setShowConfirmPassword(!showConfirmPassword)
                                                    }
                                                />
                                            )
                                        }
                                    />
                                </Form.Item>
                                <div
                                    className={`mb-4 ${validationMessages &&
                                        validationMessages.ConfirmPassword &&
                                        "has-error"
                                        }`}
                                >
                                    {validationMessages &&
                                        validationMessages.ConfirmPassword && (
                                            <AlertContainer
                                                data={validationMessages.ConfirmPassword}
                                            />
                                        )}
                                </div>
                                <div className="password-requirement">
                                    <h5>Password must contain:</h5>
                                    <ul>
                                        <li>
                                            <div>
                                                <img src={iconCheck} height="11.5px" />
                                                At least 8 characters
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <img src={iconCheck} height="11.5px" />
                                                Atleast 1 upper case letter (A-Z)
                                            </div>
                                        </li>
                                        <li>
                                            <div>
                                                <img src={iconCheck} height="11.5px" />
                                                Atleast 1 number (0-9)
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                                <Button
                                    loading={isLoading}
                                    htmlType="submit"
                                    className="mt-4 btn-radius"
                                    type="primary"
                                >
                                    Update
                                </Button>
                            </Form>
                        </Col>
                    </Row>
                )}
                {isUpdatePasswordComplete && (
                    <div className="profile-success-frame">
                        <img src={illusPwd} height="358px" width="auto" />
                        <h1>Update Success!</h1>
                        <h4>You have successfully updated password.</h4>
                    </div>
                )}
            </div>
        </ModalFullView>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    changePassword,
})(ChangePasswordModal);