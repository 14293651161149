import {
    Button,
    Calendar as MiniCal,
    Select
} from "antd";
import moment from "moment";
interface IMiniCalendar {
    dateFilter: string;
    setDateFilter: Function;
}
export const MiniCalendar: React.FC<IMiniCalendar> = ({ dateFilter, setDateFilter }) => {
    return (
        <div className="mini-calendar">
            <MiniCal
                fullscreen={false}
                mode="year"
                value={moment(dateFilter)}
                onChange={(date) => setDateFilter(moment(date).toISOString())}
                headerRender={({ value, type, onChange, onTypeChange }) => {
                    const year = value.year();
                    const options = [];
                    for (let i = year - 5; i < year + 5; i += 1) {
                        options.push(
                            <Select.Option key={i} value={i} className="year-item">
                                {i}
                            </Select.Option>
                        );
                    }
                    return (
                        <div className="mini-cal-header">
                            <Select
                                size="small"
                                dropdownMatchSelectWidth={false}
                                className="my-year-select"
                                value={year}
                                onChange={(newYear) => {
                                    const now = value.clone().year(newYear);
                                    onChange(now);
                                }}
                            >
                                {options}
                            </Select>
                        </div>
                    );
                }}
            />
            <Button className="today-btn" onClick={() => setDateFilter(moment().toISOString())}>
                Today
            </Button>
        </div>
    );
};

export default MiniCalendar;