import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  CalendarOutlined,
  CommentOutlined,
  SearchOutlined,
  MoreOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import PageHeaderWithInterest from "../layouts/PageHeaderWithInterest";
import { contactsColors } from "../../../helpers/contactsColors";
import ContactItem from "./ContactItem";
import { connect } from "react-redux";
import { getFriendApi } from "../../../actions/social";
import { ACTIVE, PENDING } from "../../../enums/friendTypes";
import FriendsComponent from "./FriendsComponent";
import FriendRequestComponent from "./FriendRequestComponent";
import {
  FRIENDREQUESTSPAGE,
  FRIENDSPAGE,
  MYPENDINGFRIENDREQUESTSPAGE,
  FRIENDBLOCKLISTPAGE,
} from "../../../enums/SocialPageTypes";
import SentFriendRequest from "./SentFriendRequest";
import SuggestedFriends from "./SuggestedFriends";
import BlockListComponent from "./BlockListComponent";

const ContactPAge = (props: { getFriendApi: Function }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedTerm, setDebouncedTerm] = useState(searchTerm);
  const { getFriendApi } = props;
  const location = useLocation();
  const [api, contextHolder] = notification.useNotification();
  const [friendRequestsData, setFriendRequestsData] = useState<any[]>([]);
  const [activePage, setActivePage] = useState<Number>(FRIENDREQUESTSPAGE);

  const fetchFriendRequests = async () => {
    let data = await getFriendApi(PENDING);
    setFriendRequestsData(data.data);
  };

  const fetchFriends = async () => {
    let data = await getFriendApi(ACTIVE);
    setFriendRequestsData(data.data);
  };

  //   const myPendingFriendRequest = async () => {
  //     let data = await getFriendApi(SENTREQUEST);
  //     setFriendRequestsData(data.data);
  //   };
  useEffect(() => {
    // Set a timer to update the debounced term after 2 seconds of no typing
    const handler = setTimeout(() => {
      setDebouncedTerm(searchTerm);
    }, 2000); // 2000ms = 2 seconds

    // Cleanup function to clear the timeout if the component re-renders
    // before the timer completes, preventing unnecessary calls
    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm]);

  // Trigger the search when the debounced term changes
  useEffect(() => {
    if (debouncedTerm) {
      handleSearch(debouncedTerm);
    }
  }, [debouncedTerm]);

  const handleSearch = (query: String) => {
    // Your search logic here
    console.log("Searching for:", query);
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  const pathToPageMap: any = {
    "/contacts/friend-requests": FRIENDREQUESTSPAGE,
    "/contacts/sent-friend-requests": MYPENDINGFRIENDREQUESTSPAGE,
    "/contacts/friends": FRIENDSPAGE,
    "/contacts/blocklist": FRIENDBLOCKLISTPAGE,
  };

  useEffect(() => {
    if (window.location.pathname == "/contacts/friends") {
      setActivePage(FRIENDSPAGE);
    }
    if (window.location.pathname == "/contacts/friend-requests") {
      setActivePage(FRIENDREQUESTSPAGE);
    }
    if (window.location.pathname == "/contacts/sent-friend-requests") {
      setActivePage(MYPENDINGFRIENDREQUESTSPAGE);
    }
    if (window.location.pathname == "/contacts/blocklist") {
      setActivePage(FRIENDBLOCKLISTPAGE);
    }
  }, [location.pathname]);
  return (
    <Fragment>
      {contextHolder}

      <PageHeaderWithInterest>
        <Popover
          placement="bottomLeft"
          content={<div />}
          trigger="click"
          className="filter-sm"
        >
          <Button
            className="filter-toggler mobile-reactive-btn"
            icon={<FilterOutlined />}
            shape="round"
          />
        </Popover>
      </PageHeaderWithInterest>
      <div className="contact-content-wrapper">
        <div className="contact-content-container">
          <Fragment>
            <Row
              style={{ margin: 0 }}
              gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
            >
              <Col className="gutter-row" xs={24} sm={24} md={24}>
                <Link to="/calendar"></Link>
                <div className="d-flex align-items-center">
                  <Link to={"/calendar"}>
                    <ArrowLeftOutlined />
                  </Link>
                  <h3 className="mb-0 ml-2" style={{ lineHeight: "normal" }}>
                    Contact List
                  </h3>
                </div>
              </Col>
            </Row>
            <Row
              style={{ margin: 0 }}
              className="mt-4 d-flex h-100 "
              gutter={{ xs: 24, sm: 24, md: 24, lg: 24 }}
            >
              <Col className="d-flex h-100 gutter-row" xs={24} sm={24} md={24}>
                <div
                  className="px-4 pt-4 pb-4 contact-page-content w-100"
                  style={{ backgroundColor: "white" }}
                >
                  {activePage != FRIENDBLOCKLISTPAGE && (
                    <Row
                      style={{ margin: 0 }}
                      gutter={{ xs: 24, sm: 24, md: 24, lg: 12 }}
                    >
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                      >
                        <div
                          className="social-navigation d-flex"
                          style={{ gap: "4px" }}
                        >
                          <Link to={"/contacts/friend-requests"}>
                            <Button
                              className={
                                activePage == FRIENDREQUESTSPAGE
                                  ? "btn-breeze-primary"
                                  : "btn-breeze-inactive"
                              }
                              type={
                                activePage == FRIENDREQUESTSPAGE
                                  ? "primary"
                                  : "default"
                              }
                            >
                              Friend Requests
                            </Button>
                          </Link>

                          <Link to={"/contacts/sent-friend-requests"}>
                            <Button
                              className={
                                activePage == FRIENDREQUESTSPAGE
                                  ? "btn-breeze-primary"
                                  : "btn-breeze-inactive"
                              }
                              type={
                                activePage == MYPENDINGFRIENDREQUESTSPAGE
                                  ? "primary"
                                  : "default"
                              }
                            >
                              Your Pending Requests
                            </Button>
                          </Link>

                          <Link to={"/contacts/friends"}>
                            <Button
                              className={
                                activePage == FRIENDREQUESTSPAGE
                                  ? "btn-breeze-primary"
                                  : "btn-breeze-inactive"
                              }
                              type={
                                activePage == FRIENDSPAGE
                                  ? "primary"
                                  : "default"
                              }
                            >
                              Friends
                            </Button>
                          </Link>
                        </div>
                      </Col>
                      <Col
                        className="gutter-row"
                        xs={24}
                        sm={24}
                        md={24}
                        lg={12}
                      >
                        <Input
                          onChange={handleChange}
                          name="search_yalies"
                          placeholder="enter a name or email"
                          addonBefore={<SearchOutlined />}
                          value={searchTerm}
                        />
                      </Col>
                    </Row>
                  )}

                  <Row
                    style={{ margin: 0 }}
                    className="h-100 d-flex contact-inner"
                    gutter={{ xs: 24, sm: 24, md: 24, lg: 12 }}
                  >
                    {activePage === FRIENDREQUESTSPAGE && (
                      <Fragment>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                        >
                          <FriendRequestComponent searchTerm={searchTerm} />
                        </Col>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={24}
                          lg={12}
                        >
                          <SuggestedFriends searchTerm={searchTerm} />
                        </Col>
                      </Fragment>
                    )}
                    {activePage === MYPENDINGFRIENDREQUESTSPAGE && (
                      <Fragment>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={16}
                          style={{ margin: "0 auto" }}
                        >
                          <SentFriendRequest searchTerm={searchTerm} />
                        </Col>
                      </Fragment>
                    )}
                    {activePage === FRIENDSPAGE && (
                      <Fragment>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={16}
                          style={{ margin: "0 auto" }}
                        >
                          <FriendsComponent
                            setActivePage={setActivePage}
                            searchTerm={searchTerm}
                          />
                        </Col>
                      </Fragment>
                    )}
                    {activePage === FRIENDBLOCKLISTPAGE && (
                      <Fragment>
                        <Col
                          className="gutter-row"
                          xs={24}
                          sm={24}
                          md={16}
                          style={{ margin: "0 auto" }}
                        >
                          <BlockListComponent
                            setActivePage={setActivePage}
                            searchTerm={searchTerm}
                          />
                        </Col>
                      </Fragment>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          </Fragment>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getFriendApi })(ContactPAge);
