import { connect } from "react-redux";
import { Link } from "react-router-dom";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal.svg";

import { Layout, Menu } from "antd";
import { Fragment } from "react";

const { Footer } = Layout;

const DetailedFooter = (props: any) => {

  return (
    <Footer
      className="detailed-footer"
      style={{ position: "fixed", zIndex: 1, width: "100%" }}
    >
      {/* <Link to="/">
        <img
          className="logo-custom"
          src={breezeHorizontalLogo}
          alt="breeze logo"
        />
      </Link> */}
      <Link to="/privacy-policy" className="privacy-policy">Privacy and Policy</Link>
    </Footer>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(DetailedFooter);
