import { Content, Footer, Header } from "antd/lib/layout/layout";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import BreezeIcon from '../assets/images/logos/breeze-horizontal-blue.svg'
import graphic from '../assets/images/empty-state-illustration.svg'

const PageNotFound = (props: any) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  let navigate = useNavigate();

  return (
    <div className="not-found">
      <Header className="page-header">
        <img src={BreezeIcon} className="icon" onClick={() => navigate("/")}/>
      </Header>
      <Content className="content">
        <img src={graphic}/>
        <h1>Page Not Found</h1>
      </Content>
        <div className="not-found-footer">
          <h3 className="footer-text">
            © Breeze 2022, All Rights Reserved.
          </h3>
          <div className="footer-links">
            <Link to="privacy-policy">Privacy Policy</Link>
          </div>
        </div>
    </div>
  );
};

export default PageNotFound;
