import { Button ,Input ,Modal ,Tooltip} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { CheckCircleOutlined, SettingOutlined, ArrowLeftOutlined, RightOutlined } from "@ant-design/icons";
import ModalCustomForm from "../Modals/ModalCustomForm";
import type { RangePickerProps } from "antd/es/date-picker";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { eventTypes } from "../../../helpers/eventTypes";

import StarG from '../../../assets/images/icons/star-g.svg'
import StarB from '../../../assets/images/icons/star-b.svg'

//interests icons
import Academic from '../../../assets/images/icons/pivot-admin/Interests/icon-academic.svg'
import Arts from '../../../assets/images/icons/pivot-admin/Interests/icon-arts.svg'
import Business from '../../../assets/images/icons/pivot-admin/Interests/icon-business.svg'
import Fairs from '../../../assets/images/icons/pivot-admin/Interests/icon-fairs.svg'
import Faith from '../../../assets/images/icons/pivot-admin/Interests/icon-faith.svg'
import HealthCounseling from '../../../assets/images/icons/pivot-admin/Interests/icon-health&counseling.svg'
import HumanitiesAcademic from '../../../assets/images/icons/pivot-admin/Interests/icon-humanities-academic.svg'
import PoliticsGovt from '../../../assets/images/icons/pivot-admin/Interests/icon-politics&govt.svg'
import RecActs from '../../../assets/images/icons/pivot-admin/Interests/icon-recreational-activities.svg'
import ServiceOutreach from '../../../assets/images/icons/pivot-admin/Interests/icon-service&outreach.svg'
import Sports from '../../../assets/images/icons/pivot-admin/Interests/icon-sports.svg'
import StemAcadEvents from '../../../assets/images/icons/pivot-admin/Interests/icon-stem-academic-events.svg'
import UnivEvents from '../../../assets/images/icons/pivot-admin/Interests/icon-university-events.svg'


const { Search } = Input;

export const InterestsModal = (props: {
    interests: any;
    isModalOpen: boolean;
    setIsModalOpen: Function;
    setSelectedInterest: Function;
    selectedInterest: any;
    member: any;
}) => {
    const {
        interests,
        member,
        isModalOpen,
        setIsModalOpen,
        setSelectedInterest,
        selectedInterest
    } = props;
    const [interestsForUpdate, setinterestsForUpdate] = useState<any[]>(selectedInterest);
    const [showninterests, setShowninterests] = useState<any[]>(interests);
    const [searchString, setSearchString]: any = useState("");
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(showninterests.length / 10);
    const memberInterests = member?.interests??[];
    const [widthSize, setWidthSize] = useState<number>(window.innerWidth);
    const InterestsIcons=[
        {name:'Academic', icon:Academic},
        {name:'Arts', icon:Arts},
        {name:'Business', icon:Business},
        {name:'Fairs', icon:Fairs},
        {name:'Faith', icon:Faith},
        {name:'Health', icon:HealthCounseling},
        {name:'Humanities', icon:HumanitiesAcademic},
        {name:'Politics', icon:PoliticsGovt},
        {name:'Recreational', icon:RecActs},
        {name:'Service', icon:ServiceOutreach},
        {name:'Sports', icon:Sports},
        {name:'Stem', icon:StemAcadEvents},
        {name:'University', icon:UnivEvents},
    ]

    const onFilterSearchHandler = (searchInput: String) => {
        setSearchString(searchInput);
        setPage(1);
    };

    const onUpdate = () => {
        setSelectedInterest(interestsForUpdate);
        setIsModalOpen(false);
    }

    const InterestTag = (interest: any) => {
        let updated = (showninterests?.filter(i=>i.interestId === interest.interestId)[0]);
        const [isSelected, setIsSelected] = useState(interestsForUpdate.some(t => t.interestId === interest?.interestId));
        // let isDisabled = (interestsForUpdate.length>=5 && !isSelected);
        const handleSelect = (interest: any, checked: boolean) => {
            const nextselectedInterest = !checked
                ? [...interestsForUpdate, interest]
                : interestsForUpdate.filter((t: any) => t.interestId !== interest?.interestId);
            setinterestsForUpdate(nextselectedInterest);
        };

        return (
            // <div className={(isDisabled)?"interest-tag-disabled":isSelected?"interest-tag-b":"interest-tag"} onClick={()=>{handleSelect(interest, isSelected)}}>
            <div className={isSelected ? "interest-tag-selected" : "interest-tag"} onClick={() => { handleSelect(interest, isSelected) }}>
                {InterestsIcons.some(e => e.name === ((interest?.name).split(" ")[0]))&&(
                    <img src={InterestsIcons.filter(i=>i?.name===((interest?.name).split(" ")[0]))[0].icon} className="grey-sq"/>
                )}
                <Tooltip title={interest?.name}>
                    <p className="name">
                        {interest?.name}
                    </p>
                </Tooltip>
                {updated && (
                    <p className={"count"}>
                        {updated?.eventCount} upcoming events
                    </p>
                )}
                {/* {memberInterests?.map((mi: any) => mi.name).includes(interest?.name) && (
                    <p className="tag-info">
                        <img src={isSelected ? StarB : StarG} />
                        <span >Personal Interest</span>
                    </p>
                )} */}
            </div>
        )
    }

    useEffect(() => {
        if (searchString !== "") {
            setShowninterests(interests.filter((t: any) => (((t.name).toLowerCase()).includes(searchString.toLowerCase()))))
        } else {
            setShowninterests(interests)
        }
    }, [searchString]);

    useEffect(() => {
        if (interests.length > 0) {
            setShowninterests(interests)
        }
    }, [interests]);

    useEffect(() => {
        setinterestsForUpdate(selectedInterest);
    }, [selectedInterest]);

    useEffect(() => {
        setWidthSize(window.innerWidth);
    }, [isModalOpen]);

    return (
        <Modal
            title={
                <>
                    {widthSize<992 && (
                        <Button
                            className="mr-1"
                            style={{border:'none'}}
                            type="default"
                            icon={<ArrowLeftOutlined />}
                            onClick={() => setIsModalOpen(false)}
                        />
                    )}
                    Interest
                </>
            }
            className="modal-interest-tags"
            centered
            closable={widthSize>992?true:false}
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={
                widthSize>992?
                <Button
                    className="mr-1 mobile-reactive-btn"
                    style={{borderRadius:'4px'}}
                    type="primary"
                    icon={<CheckCircleOutlined />}
                    // shape="round"
                    onClick={onUpdate}
                    disabled={selectedInterest === interestsForUpdate}
                >
                    Update
                </Button>:null}
        >
            <div className="d-flex">
                <div className="d-flex-column w-100 mb-3">
                    {widthSize<992 && (
                        <Button
                            className="mr-1 mobile-reactive-btn update-btn-mobile"
                            style={{borderRadius:'4px'}}
                            type="primary"
                            icon={<CheckCircleOutlined />}
                            // shape="round"
                            onClick={onUpdate}
                            disabled={selectedInterest === interestsForUpdate}
                        >
                            Update
                        </Button>
                    )}
                    <Search
                        className="search-interest"
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search Interest"
                    />
                    <div className="tag-grid">
                        {selectedInterest?.sort((a: any, b: any) => (b?.eventCount) - (a?.eventCount)).map((t: any, index: number) =>
                            <InterestTag key={index} {...t} />
                        )}
                        {showninterests?.sort((a: any, b: any) => (b?.eventCount) - (a?.eventCount)).filter((st) => !selectedInterest?.map((t: any) => t.name).includes(st.name)).map((t: any, index: number) =>
                            <InterestTag key={index} {...t} />
                        )}
                        {/* {showninterests.filter((st) => !selectedInterest?.map((t: any) => t.name).includes(st.name)).slice((page === 1 ? 0 : (10 * (page - 1))), (10 * page)).map((t: any, index: number) =>
                            <InterestTag key={index} {...t} />
                        )} */}
                    </div>
                    {/* <div className="interest-page-btns">
                        <Button onClick={() => setPage(page - 1)} disabled={page === 1}><LeftOutlined /></Button>
                        <p>{page}/{totalPages}</p>
                        <Button onClick={() => setPage(page + 1)} disabled={page === totalPages}><RightOutlined /></Button>
                    </div> */}
                </div>
            </div>
        </Modal>
    );
};


const mapStateToProps = (state: any) => ({
    member: state.auth?.member,
});
export default connect(mapStateToProps, {
    getSoftSkillsNonPaged,
})(InterestsModal);