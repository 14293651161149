import {
    Button,
    Form,
    Space,
    Select,
    Input,
    DatePicker,
    Col,
    Row,
    message,
    Modal,
    Tag,
    Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { PlusOutlined, SettingOutlined } from "@ant-design/icons";
import ModalCustomForm from "../Modals/ModalCustomForm";
import type { RangePickerProps } from "antd/es/date-picker";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { eventTypes } from "../../../helpers/eventTypes";
import PointDistributionInput from "../../super-admin/interest/PointDistribution";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import LocationField from "../../super-admin/select-fields/LocationField";
import ScheduleG from '../../../assets/images/icons/schedule-g.svg'
import ScheduleB from '../../../assets/images/icons/schedule-b.svg'

export const PrimeTimeModal = (props: {
    timeSlots: any;
    isModalOpen: boolean;
    setIsModalOpen: Function;
    setSelectedTime: Function;
    selectedTime: string;
}) => {
    const { timeSlots,
        isModalOpen,
        setIsModalOpen,
        setSelectedTime,
        selectedTime,
    } = props;
    const [selectedSlot, setSelectedSlot] = useState<any>(null);

    const onUpdate = () => {
        setSelectedTime(selectedSlot);
        setIsModalOpen(false);
    }

    useEffect(() => {
        setSelectedSlot(selectedTime);
    }, [selectedTime])

    const onClick = (name: String) => {
        if (selectedSlot !== name) {
            setSelectedSlot(name);
        } else {
            setSelectedSlot('');
        }
    }

    const TimeSlot = (slot: any) => {
        return (
            <div className={selectedSlot === slot.name ? "interest-tag-b" : "interest-tag"} onClick={() => onClick(slot.name)}>
                <Tooltip title={slot.name}>
                    <p className={selectedSlot === slot.name ? "tag-b" : "tag"}>
                        {slot.name}
                    </p>
                </Tooltip>
                <p className="tag-info">
                    <img src={selectedSlot === slot.name ? ScheduleB : ScheduleG} />
                    <span >{slot.slot}</span>
                </p>
            </div>
        )
    }

    return (
        <Modal
            title="Preferred Time"
            className="modal-time-slots"
            centered
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={
                <Button
                    className="mr-1 mobile-reactive-btn"
                    type="primary"
                    icon={<SettingOutlined />}
                    shape="round"
                    onClick={onUpdate}
                    disabled={selectedSlot === selectedTime}
                >
                    Update
                </Button>}
        >
            <div className="d-flex">
                <div className="d-flex-column w-100 mb-3">
                    <div className="tag-grid">
                        {timeSlots.map((t: any, index: number) =>
                            <TimeSlot key={index} {...t} />
                        )}
                    </div>
                </div>
            </div>
        </Modal>
    );
};


const mapStateToProps = (state: any) => ({
    member: state.auth?.member,
});
export default connect(mapStateToProps, {
    getSoftSkillsNonPaged,
})(PrimeTimeModal);