import { Form, Select } from "antd";
import { Fragment, useEffect, useState } from "react";
import AlertContainer from "../../../layouts/AlertContainer";
import { getLocationListNonPaged } from "../../../actions/location";
import { connect } from "react-redux";

const { Option } = Select;

const LocationField = (props: {
  getLocationListNonPaged: Function;
  validationMessages: any;
  className: string;
}) => {
  const { validationMessages, getLocationListNonPaged, className } = props;
  const [locationList, setLocationList] = useState([]);
  const [isLoadingLocationList, setIsLoadingLocationList] = useState(false);
  useEffect(() => {
    const initialize = async () => {
      setIsLoadingLocationList(true);
      const data = await getLocationListNonPaged();
      setLocationList(data.data);
      setIsLoadingLocationList(false);
    };
    initialize();
  }, []);
  return (
    <Fragment>
      <Form.Item
        label="Location"
        name="locationId"
        className={className}
        rules={[
          {
            required: true,
            message: "Please enter Location!",
          },
        ]}
      >
        <Select
          disabled={isLoadingLocationList}
          placeholder={
            isLoadingLocationList ? "Loading Locations..." : "Select Location for the event"
          }
          allowClear
        >
          {locationList &&
            locationList.map((data: any) => {
              return (
                <Option key={data.locationId} value={data.locationId}>
                  {data.name}
                </Option>
              );
            })}
        </Select>
      </Form.Item>
      <div
        className={`mb-4 ${
          validationMessages && validationMessages.Location && "has-error"
        }`}
      >
        {validationMessages && validationMessages.Location && (
          <AlertContainer data={validationMessages.Location} />
        )}
      </div>
    </Fragment>
  );
};

export default connect(null, {
  getLocationListNonPaged,
})(LocationField);
