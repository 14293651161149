import { Button, Col, Row, Table, Space, message, TableProps } from "antd";
import { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getEventPromotions, deleteEventPromotion } from "../../../actions/promotions";
import CreatePromotions from "./promotions/CreatePromotion";
type ColumnsType<T extends object = object> = TableProps<T>['columns'];
const Promotions = (props:any) => {
    const { getEventPromotions, deleteEventPromotion } = props;
    const [selectedTab, setSelectedTab] = useState(0);
    const [shownPage, setShownPage] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [promotions, setPromotions] = useState<any>([]);
    const [allPromotions, setAllPromotions] = useState<any>([]);
    const [editPromoId, setEditPromoId] = useState<any>(null);

    const fetchPromotions = async () => {
        setIsLoading(true);
        let params;
        params = {
        searchString:"",
        page:1,
        pageSize:50,
        };
        let res = await getEventPromotions(params);
        if (res?.data) {
            setAllPromotions(res?.data?.items);
            sortPromotions(res?.data?.items);
        }
        setIsLoading(false);
    };
    const deleteEvent = async (eventId:any) => {
        setIsLoading(true);
        let params;
        params = {
            eventPromotionId:eventId,
        };
        let res = await deleteEventPromotion(params);
        if (res?.status===204) {
            message.success(`Promotion Deleted!`);
            fetchPromotions();
        }else{
            message.error(`Error Deleting Promotion!`);
        }
        setIsLoading(false);
    };
    const sortPromotions = (promos:any) => {
        if(selectedTab===0){
            setPromotions(promos.filter((p:any)=>moment(p.from).isAfter(moment())))
        }else if(selectedTab===1){
            setPromotions(promos.filter((p:any)=>moment().isBetween(p.from, p.to) ))
        }else{
            setPromotions(promos.filter((p:any)=>moment(p.to).isBefore(moment())))
        }
    };
    const onEdit = (eventId:any) =>{
        setEditPromoId(eventId);
        setShownPage(1);
    }

    useEffect(()=>{
        if(shownPage===0){
            fetchPromotions();
            setEditPromoId(null);
        }
    },[shownPage]);

    useEffect(()=>{
        sortPromotions(allPromotions);
    },[selectedTab]);

    interface DataType {
        promotionName: string;
        promotionType: string;
        duration: {
          from: string;
          to: string;
        };
        actions: {
          eventPromotionId: string;
          to: string;
        };
      }
 
    const columns: ColumnsType<DataType> = [
        {
          title: 'Promotion Name',
          dataIndex: 'promotionName',
          key: 'title',
        },
        {
          title: 'Type',
          dataIndex: 'promotionType',
          key: 'type',
          render: (type:any) => (
              <p>{type===0?"Front Page Banner": type===1?"Highlight":"Result Priority"}</p>
          ),
        },
        {
          title: 'Duration',
          dataIndex: ['duration','data'],
          key: 'duration',
          render: (duration:any,data:any) => (
              <p>{moment(data?.from).format('MMM DD - ')}{moment(data?.to).format('DD, YYYY')}</p>
          ),
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: ['actions','data'],
          render: (eventId:any, data:any) => (
            <Space size="middle">
                {moment().isBefore(moment(data?.to)) && (
                    <a onClick={()=>onEdit(data?.eventPromotionId)}>Edit</a>
                )}
                <a onClick={()=>deleteEvent(data?.eventPromotionId)}>Delete</a>
            </Space>
          ),
        },
    ];

    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content" style={{padding:'0px', margin:'0px'}}>
                    {shownPage === 0 ?(
                        <div className="promotions-page">
                            <div className="header">
                                <div className="tab-group">
                                    <Button className={selectedTab===0?"selected-tab":"tab"} onClick={()=>setSelectedTab(0)}>Upcoming</Button>
                                    <Button className={selectedTab===1?"selected-tab":"tab"} onClick={()=>setSelectedTab(1)}>Ongoing</Button>
                                    <Button className={selectedTab===2?"selected-tab":"tab"} onClick={()=>setSelectedTab(2)}>Completed</Button>
                                </div>
                                <Button onClick={()=>setShownPage(1)} type="primary" disabled={isLoading} loading={isLoading}>Create Promotion</Button>
                            </div>
                            <div className="table-container">
                                <Table
                                    className="promo-table"
                                    loading={isLoading}
                                    rowKey="key"
                                    pagination={false}
                                    columns={columns}
                                    dataSource={promotions}
                                />
                            </div>
                        </div>
                    ):(
                        <CreatePromotions setPage={setShownPage} editPromoId={editPromoId} promoList={allPromotions}/>
                    )}

                </div>
            </div>
        </Fragment >
    );
};

const mapStateToProps = (state: {
    auth: any;
  }) => ({
    member: state.auth?.member,
  });

export default connect(mapStateToProps, { getEventPromotions, deleteEventPromotion })(Promotions);
