import { Content, Footer, Header } from "antd/lib/layout/layout";
import graphic from '../../assets/images/empty-state-illustration.svg'

const PageNotFound = (props: any) => {
  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page

  return (
    <div className="not-found-member-route">
      <Content className="content">
        <img src={graphic}/>
        <h1>Page Not Found</h1>
      </Content>
    </div>
  );
};

export default PageNotFound;
