import { connect } from "react-redux";
import { Link } from "react-router-dom";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal-blue.svg";

import { Layout, Menu, Button } from "antd";
import { Fragment } from "react";

const { Header } = Layout;

const HomePageHeader = (props: any) => {
  const {
    auth: { isAuthenticated },
  } = props;
  return (
    <Header
      className="ant-layout-header-breeze"
      style={{ position: "fixed", zIndex: 1, width: "100%" }}
    >
      <Link to="/">
        <img
          className="logo-custom"
          src={breezeHorizontalLogo}
          alt="breeze logo"
        />
      </Link>
      {props.title!==undefined && (
        <h2>{props.title}</h2>
      )}
      {props.title!==undefined && (
        <Button
          key="back"
          className="btn-radius"
        >
          <Link to="/">
            Cancel
          </Link>
        </Button>
      )}

    </Header>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(HomePageHeader);
