import { callApi } from './general';
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
const baseUrl = '/api/EventPromotion';

export const getEventPromotions = (values, controller) => async (dispatch) => {
  return await callApi({
    // api: `?page=${values.page}&pageSize=${values.pageSize}${values.searchString!==""?`&searchString=${encodeURIComponent(values.searchString)}`:""}`,
    api: `?page=${values.page}&pageSize=${values.pageSize}${values.searchString!==""?`&searchString=${encodeURIComponent(values.searchString)}`:""}`,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};


export const createEventPromotion = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'promotionName' },
    { name: 'promotionType' },
    { name: 'from' },
    { name: 'to' },
    { name: 'eventIds' },
    { name: 'isActive' },
    { name: 'url' },
    { name: 'file' },
    { name: 'bannerFile' },
    { name: 'priority' },
  ];
  return await callApi({
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'post',
    baseUrl: baseUrl,
  });
};

export const updateEventPromotion = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'promotionName' },
    { name: 'promotionType' },
    { name: 'from' },
    { name: 'to' },
    { name: 'eventIds' },
    { name: 'isActive' },
    { name: 'url' },
    { name: 'file' },
    { name: 'bannerFile' },
    { name: 'priority' },
  ];
  return await callApi({
    api:`${values.eventPromotionId}`,
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'put',
    baseUrl: baseUrl,
  });
};

export const deleteEventPromotion = (values, controller) => async (dispatch) => {
  return await callApi({
    api:`${values.eventPromotionId}`,
    controller: controller,
    dispatch: dispatch,
    type: 'delete',
    baseUrl: baseUrl,
  });
};

export const getPromoInfo = (values, controller) => async (dispatch) => {
  return await callApi({
    api:`${values}`,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};
