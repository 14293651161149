import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";

const CustomTooltip = (data:any)=>{
    const interests = data?.data?.eventGroup ?? [];
    const total = data?.data?.totalEventCount;
    const colors = ["#165BAA","#A155B9","#F765A3","#E74731","#FFA800"]
    return(
        <div className="analytics-tooltip">
            <div className="title">
                {/* ${tooltipModel.title} */}
            </div>
            <div className="total" style={{marginBottom:"10px"}}><span>Events</span><span>{isNaN(total)?0:total}</span></div>
            {interests.slice(0,5).map((int:any,i:any)=>
                <div className="summary-info">
                    <div className="left">
                        <div className="label">
                            <span className="point" style={{backgroundColor:colors[i]}}></span>
                            <span>{int?.interestName}</span>
                        </div>
                    </div>
                    <p className="point-total" style={{color:colors[i]}}>{int?.eventCount}</p>
                </div>
            )}
        </div>
    )
}
export default CustomTooltip;