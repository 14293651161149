import { Row, Col, Tooltip } from "antd";
import moment from "moment";
//interests icons
import Academic from '../../../../../assets/images/icons/pivot-admin/Interests/icon-academic.svg'
import Arts from '../../../../../assets/images/icons/pivot-admin/Interests/icon-arts.svg'
import Business from '../../../../../assets/images/icons/pivot-admin/Interests/icon-business.svg'
import Fairs from '../../../../../assets/images/icons/pivot-admin/Interests/icon-fairs.svg'
import Faith from '../../../../../assets/images/icons/pivot-admin/Interests/icon-faith.svg'
import HealthCounseling from '../../../../../assets/images/icons/pivot-admin/Interests/icon-health&counseling.svg'
import HumanitiesAcademic from '../../../../../assets/images/icons/pivot-admin/Interests/icon-humanities-academic.svg'
import PoliticsGovt from '../../../../../assets/images/icons/pivot-admin/Interests/icon-politics&govt.svg'
import RecActs from '../../../../../assets/images/icons/pivot-admin/Interests/icon-recreational-activities.svg'
import ServiceOutreach from '../../../../../assets/images/icons/pivot-admin/Interests/icon-service&outreach.svg'
import Sports from '../../../../../assets/images/icons/pivot-admin/Interests/icon-sports.svg'
import StemAcadEvents from '../../../../../assets/images/icons/pivot-admin/Interests/icon-stem-academic-events.svg'
import UnivEvents from '../../../../../assets/images/icons/pivot-admin/Interests/icon-university-events.svg'

const Info = ({ data, selectedTab }: { data: any, selectedTab: any }) => {
    const InterestsIcons=[
        {name:'Academic', icon:Academic},
        {name:'Arts', icon:Arts},
        {name:'Business', icon:Business},
        {name:'Fairs', icon:Fairs},
        {name:'Faith', icon:Faith},
        {name:'Health', icon:HealthCounseling},
        {name:'Humanities', icon:HumanitiesAcademic},
        {name:'Politics', icon:PoliticsGovt},
        {name:'Recreational', icon:RecActs},
        {name:'Service', icon:ServiceOutreach},
        {name:'Sports', icon:Sports},
        {name:'Stem', icon:StemAcadEvents},
        {name:'University', icon:UnivEvents},
    ]

    return(
        <div className="info-row">
            <Row>
                <Col span={12}>
                    <div className="main-data">
                        {selectedTab===0 && (
                            // <div className="img"/>
                            <img src={InterestsIcons.filter(i=>i.name===(data?.name).split(" ")[0])[0].icon} className="img"/>
                        )}
                        <Tooltip title={data?.name}>
                            <p className="name">{data?.name}</p>
                        </Tooltip>
                    </div>
                </Col>
                <Col span={6}><div className="other-data">{data?.noOfEvents}</div></Col>
                <Col span={6}><div className="other-data">{data?.noOfHours ?? data?.noOfMembers ?? 'N/A'}</div></Col>
            </Row>
        </div>
    )
}

export default Info;