import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
export const getInterests = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  let searchString = params.searchString
    ? `&searchString=${encodeURIComponent(params.searchString)}`
    : "";
  try {
    const res = await axiosApiInstance.get(
      `/api/Interest` +
        `?page=${page}&pageSize=${params.pageSize}` +
        searchString +
        `&status=${params.status}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    // dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getInterestListNonPaged = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/Interest/list`);
    return res;
  } catch (err) {
    const response = err.response;
    //dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const addInterest = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(`/api/Interest/`, formData, config);

    dispatch(setAlert("Success", "Interest Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateInterest = (formData, interestId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Interest/${interestId}`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Interest Successfully updated", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const deleteInterest = (id) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(`/api/Interest/${id}`);

    dispatch(setAlert("Success", "Interest Deactivated", "success"));
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", err.response.data.error, "error")
    );
    return err;
  }
};
