import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import Overview from "./opac/Overview";
import Events from "./opac/Events";
import Popular from "./opac/Popular";

const Opac = (dates:any, filterType:any) => {

    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content">
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <Overview {... dates} {...filterType}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Events {... dates}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <Popular {... dates}/>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment >
    );
};

export default Opac;
