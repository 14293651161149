import { Button, Modal } from "antd";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import iconExclamation from "../../assets/images/icons/icon-exclamation.svg";
import { setRecentLogout } from "../../actions/recentLogout";
import { connect } from "react-redux";
import flutterConnect from "../../reducers/flutterConnect";

const ModalLogout = (
  props: any
  ) => {
  const { onLogoutCancel, modal2Visible, logout, setRecentLogout, flutterConnect } = props;
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  
  return (
    <Modal
      className="modal-notify"
      centered
      visible={modal2Visible}
      onCancel={onLogoutCancel}
      footer={[]}
    >
      <div className="d-flex">
        <div>
          <img src={iconExclamation} />
        </div>
        <div className="d-flex-column ml-2">
          <div className="mod-title">Logging Out</div>
          <div className="mt-1">
            <p className="modal-message mt-1">
              You will be logged out of any active sessions.
            </p>
            <div className="d-flex justify-content-space-between">
              <Button
                key="logout-submit"
                className="btn-breeze-no-bg"
                onClick={onLogoutCancel}
              >
                Go Back
              </Button>
              <Button
                key="submit"
                className="btn-breeze-2"
                onClick={async () => {
                  setIsLoading(true);

                  const res = await logout();
                  if (res.status == 204)
                    console.log('check logout');
                    console.log(flutterConnect);
                    if (flutterConnect) {
                      (window as any).flutter_inappwebview
                        .callHandler('handleLogout');
                    }
                    setRecentLogout(true);

                }}
                loading={isLoading}
              >
                {isLoading ? "Logging Out" : "Confirm"}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = (state: { auth: any; firebase: any; flutterConnect: boolean; }) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect,
});

export default connect(mapStateToProps, {
  setRecentLogout
})(ModalLogout);
