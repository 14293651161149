import { Button, Form, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

export const ModalCustomForm = (props: {
  title: string;
  handleSubmit: any;
  isOpen: boolean;
  setIsOpen: Function;
  form: any;
  isLoading: boolean;
  footerArray: any;
  children: any;
  width?: any;
}) => {
  const {
    title,
    handleSubmit,
    isOpen,
    setIsOpen,
    form,
    isLoading,
    footerArray,
    children,
    width,
  } = props;
  const [count, setCount] = useState(0);
  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={footerArray}
      width={width}
    >
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        {props.children}
      </Form>
    </Modal>
  );
};

export default ModalCustomForm;
