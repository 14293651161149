import axios from "axios";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
import { configure } from "@testing-library/react";
import { callApi } from "./general";

const baseUrl = '/api/Contacts';

export const getContacts = (values,controller) => async (dispatch) => {
  var params = [
    { name: 'accessToken' },
  ];
  return await callApi({
    api: 'getsocialcontacts',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const getDBContacts = (values,controller) => async (dispatch) => {
  return await callApi({
    api: 'getcontacts',
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const addContact = (values,controller) => async (dispatch) => {
  var params = [
    // { name: 'contactResourceName' },
    // { name: 'loginType' },
    { name: 'email' },
    { name: 'accessToken' },
  ];
  return await callApi({
    api: 'add',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'post',
    baseUrl: baseUrl,
  });
};

export const removeContact = (values,controller) => async (dispatch) => {
  // var params = [
  //   { name: 'id' },
  // ];
  return await callApi({
    api: `remove?id=${values.id}`,
    // params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'delete',
    baseUrl: baseUrl,
  });
};

export const addEmail = (values,controller) => async (dispatch) => {
  var params = [
    { name: 'emails' },
  ];
  return await callApi({
    api: 'add-email',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'post',
    baseUrl: baseUrl,
  });
};