import { Row, Col, Tooltip } from "antd";
import moment from "moment";

const AnalyticTooltip = ({ dates, filterType, overViewData }: { dates: any, filterType: any, overViewData: any }) =>{

    return(
        <div className="analytics-tooltip" style={{border:"none"}}>
            <div className="title">
                {dates[0]===null?moment().format('MMMM YYYY'):(filterType==='Month' ? moment(dates[0]).format('MMMM YYYY') : `${moment(dates[0]).format('MMM DD')} - ${moment(dates[1]).format('MMM DD')}`)}
            </div>
            <div className="total"><span>Total Events</span><span>{overViewData?.totalEvents??'No Data'}</span></div>
            <div className="point-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:'#165BAA'}}></span>
                        <span style={{color:'#000'}}>Upcoming Events</span>
                    </div>
                    <p className="description">Events that are scheduled to happen in the following days, weeks, or months.</p>
                </div>
                <p className="point-total" style={{color:'#165BAA'}}>{overViewData?.totalUpcoming??'No Data'}</p>
            </div>
            <div className="point-info">
                <div className="left">
                    <div className="label">
                        <span className="point" style={{backgroundColor:'#595959'}}></span>
                        <span style={{color:'#000'}}>Past Events</span>
                    </div>
                    <p className="description">Events that have already happened or occured.</p>
                </div>
                <p className="point-total" style={{color:'#595959'}}>{overViewData?.previousTotalEvents??'No Data'}</p>
            </div>
        </div>
    )
}

export default AnalyticTooltip;