import { Row, Col, Tooltip, Progress } from "antd";
import moment from "moment";

const Info2 = ({data}:any) => {
    return(
        <div className="info-row">
            <Row>
                <Col span={10}>
                    <div className="main-data" style={{paddingRight:'20px'}}>
                        <Tooltip title={data?.name}>
                            <p className="name">{data?.name}</p>
                        </Tooltip>
                    </div>
                </Col>
                <Col span={10}><div className="other-data"><Progress percent={data?.percentage} showInfo={false} strokeColor={"#0077CD"}/></div></Col>
                <Col span={4}><div className="other-data">{data?.noOfEvents}</div></Col>
            </Row>
        </div>
    )
}

export default Info2;