import GoogleCircle from '../../assets/images/logos/icon-google.svg';
import BreezeWhite from '../../assets/images/logos/icon-breeze-white.svg';
import BreezeBlue from '../../assets/images/logos/icon-breeze-blue.svg';
import Promo from '../../assets/images/icons/promo-icon.svg';
import Highlight from '../../assets/images/icons/highlight.svg';
import Interests from '../../assets/images/icons/pivot-admin/Interests.svg';
import Share from "../../assets/images/icons/share-b.svg";
import { EventProps, EventWrapperProps, ToolbarProps, Views } from 'react-big-calendar';
import moment from 'moment';
import { connect } from "react-redux";
import { createCalendarEvent } from '../../actions/calendar';
import { Button, Dropdown, Menu, Popover, Spin, Tooltip, Avatar, message, Switch } from 'antd';
import {
    PlusCircleFilled,
    LeftOutlined,
    RightOutlined,
    DownOutlined,
    UpOutlined,
    SyncOutlined,
} from "@ant-design/icons";
import { ReactNode, useState } from 'react';
import MiniCalendar from './MiniCalendar';
import { contactsColors } from "../../helpers/contactsColors";

export const slotPropGetter = () => {
    return {
        className: 'slot',
        style: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100px',
            fontSize: '14px',
            fontWeight: '700',
            margin: '0px',
            padding: '0px',
        },
    };
};

interface CalendarToolBarProps extends ToolbarProps {
    isLoading?: boolean,
    setDateFilter?: any,
    dateFilter?: any,
    setCalView?: any,
    setView?: any,
    member?: any,
    widthSize : number,
    updateProfile : Function,
    clubData?: any;
    clubIsLoading?: any;
}

export const CalendarToolBar: React.FC<CalendarToolBarProps> = ({ date, view, label, onView, localizer, isLoading,
    onNavigate, setDateFilter, dateFilter, setCalView, setView, widthSize, member, updateProfile, clubData, clubIsLoading } ) => {
    const [displayAllDay, setDisplayAllDay] = useState(member?.displayAllDayEvents ?? false);
    let isMobile = widthSize < 992;
    const onToggleAllDay = async () => {
        let res = await updateProfile({
            ...member,
            jobTitle:'a',
            displayAllDayEvents: !displayAllDay,
        });
        if(res.status===200){
            setDisplayAllDay(!displayAllDay);
        }
    };
    let pathname = (window.location.pathname);
    const shareClub = () =>{
        let url = window.location.href;
        navigator.clipboard.writeText(url);
        message.success("Club Page URL Copied to Clipboard!");
    }
    return (
        <div className={`calendarToolbar ${view==="day"?'mobile-day':''}`}>
            {pathname.includes("/club") && (
                <div className='top-additional'>
                    <Spin spinning={clubIsLoading}>
                    <h1>
                        <img src={Interests}/>
                        {clubData?.name}
                        <Button className="circle-btn" onClick={shareClub}><img src={Share}/></Button>
                    </h1>
                    </Spin>
                    <Button disabled={true} className='sync-btn'>
                    {/* <Button disabled={isLoading} className='sync-btn'> */}
                        <SyncOutlined/>
                        Sync to my Calendar
                    </Button>
                </div>
            )}
            <div className='main'>
            <div className="left-side">
                <div className="nav-btn">
                    <Button onClick={() => onNavigate("PREV")} disabled={isLoading}>
                        <LeftOutlined />
                    </Button>
                    <Button onClick={() => onNavigate("NEXT")} disabled={isLoading}>
                        <RightOutlined />
                    </Button>
                </div>
                <Popover
                    placement="bottomLeft"
                    content={<MiniCalendar setDateFilter={setDateFilter} dateFilter={dateFilter} />}
                    trigger={isLoading ? "" : "click"}
                >
                    <Button className="mini-calendar-btn">
                        <h2>
                            {moment(date, "YYYY MM DD").format("MMMM YYYY")}{" "}
                            <Spin spinning={isLoading} size="small">
                                <DownOutlined
                                    style={{ visibility: isLoading ? "hidden" : "visible" }}
                                />
                            </Spin>
                        </h2>
                    </Button>
                </Popover>
                <div className="date-small-screen">
                    <div className="nav-btn2" style={{marginRight:'10px'}}>
                        <Button onClick={() => onNavigate("PREV")} disabled={isLoading}>
                            <LeftOutlined />
                        </Button>
                        <Button onClick={() => onNavigate("NEXT")} disabled={isLoading}>
                            <RightOutlined />
                        </Button>
                    </div>
                    <h3 className='date-full'>{moment(date).format(view === "month" ? "MMMM YYYY" : "DD MMMM YYYY")}
                        <Spin spinning={isLoading} size="small" className='spin' />
                    </h3>
                </div>
            </div>
            <div className="right-side">
                <div className='allday-toggle'>
                    <p>All Day Events</p>
                    <Switch size="small"  checked={displayAllDay} onChange={onToggleAllDay}/>
                </div>
                <Dropdown
                    className="dropdown"
                    disabled={isLoading}
                    overlay={
                        <Menu>
                            <Menu.Item
                                key={Views.MONTH}
                                onClick={() => {
                                    onView(Views.MONTH);
                                    setView(Views.MONTH);
                                    setCalView("month");
                                }}
                            >
                                Month
                            </Menu.Item>
                            <Menu.Item
                                key={Views.WEEK}
                                onClick={() => {
                                    onView(isMobile ? Views.DAY : Views.WEEK);
                                    setView(isMobile ? Views.DAY : Views.WEEK);
                                    setCalView(isMobile ? "day" : "week");
                                }}
                            >
                                Week
                            </Menu.Item>
                            {!isMobile &&
                                <Menu.Item
                                    key={Views.DAY}
                                    onClick={() => {
                                        onView(Views.DAY);
                                        setView(Views.DAY);
                                        setCalView("day");
                                    }}
                                >
                                    Day
                                </Menu.Item>
                            }
                        </Menu>
                    }
                >
                    <Button>
                        {(isMobile && view === "day") ? "Week" : view.charAt(0).toUpperCase() + view.slice(1)} <DownOutlined />
                    </Button>
                </Dropdown>
            </div>
            </div>
        </div>
    );
};

export const DayHeader = ({ date, localizer }: { date: any, localizer: any }) => {
    return (
        <div className={"day-header-cell"}>
            <h4>{localizer.format(date, 'ddd')}</h4>
            <h1>{localizer.format(date, 'DD')}</h1>
        </div>
    )
};

export const WeekHeader = ({ date, localizer }: { date: any, localizer: any }) => {
    return (
        <div className={"week-header-cell"}>
            <h4>{localizer.format(date, 'ddd')}</h4>
            <h1>{localizer.format(date, 'DD')}</h1>
        </div>
    )
};

interface IEventComponent {
    // Add any additional props you may need
    event: any;
    refresh: () => void;
}

export const EventComponent: React.FC<IEventComponent> = ({ event, refresh }) => {
    let isUpcomingEvent = (moment(event.actualStart ?? event.start).isAfter(new Date()));
    let isAllDay = event?.allDay;
    const [isLoading, setIsLoading] = useState(false);
    const googleAccessToken = localStorage.getItem("googleAccessToken");

    return (
        <div className='event-component' style={getStyling(event)}>
            <span className="upper">
                <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
                <span className="details">
                    <p className="title">{event?.title}</p>
                    {!event?.allDay && (
                        <p className="time">{moment(event?.actualStart ?? event?.start).format("h:mm a")} - {moment(event?.actualStart ?? event?.start).isSame(event?.actualEnd ?? event?.end, 'day') ?
                            moment(event?.actualEnd ?? event?.end).format("h:mm a")
                            : moment(event?.actualEnd ?? event?.end).format("MMM D, h:mm a")}</p>
                    )}
                </span>
                {(event?.isHighlighted===true||event?.isPriority===true)&&(
                    <img src={Promo} className="promoIcon"/>
                )}
            </span>
            <div className="lower">
                {!event.isGoogleEvent && isUpcomingEvent && !isAllDay &&
                    <Spin spinning={isLoading}>
                        <PlusCircleFilled className="addIcon" onClick={async (e) => {
                            e.stopPropagation();
                            try {
                                setIsLoading(true);
                                const res = await createCalendarEvent({
                                    accessToken: googleAccessToken,
                                    eventId: event.eventId ?? event?.conflicts[0]
                                });
                                let call = await res({});
                                if (call.status === 200) {
                                    message.success(`You successfully added ${event?.title}!`);
                                    refresh();
                                }
                                setIsLoading(false);
                            }
                            catch (e) { 
                                message.error('An Error Occured');
                             }
                        }
                        } />
                    </Spin>
                }
                {
                    !isAllDay && event.conflicts !== undefined && event.conflicts !== null && event.conflicts.length > 1 && (<p className={event.isGoogleEvent?"more-w":"more"}>+ {event.conflicts?.length - 1} <u>More</u></p>)
                }
            </div>
        </div>
    )
};

interface IEventComponentMonth {
    isAddedEvent: any;
    isGoogleEvent: any;
    count: any;
    event: any;
    isHighlighted: any;
    isPriority: any;
}

export const EventComponentMonth: React.FC<EventProps<IEventComponentMonth>> = ({ event }) => {
    return (
        <div className='event-component-month'  style={getStyling(event)}>
            {window.innerWidth>=992 && 
                <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
            }
            <p >
                {event.count} event{event.count > 1 ? "s" : ''}
            </p>
            {(event?.isHighlighted===true||event?.isPriority===true)&&(
                <img src={Promo} className="promoIcon"/>
            )}
        </div>
    )
};

interface ICustomEventWrapper {
    event: any;
    calView: any;
    allEventItems: any;
    priorityPromotions?: any;
    refresh: Function;
}

export const CustomEventWrapper: React.FC<ICustomEventWrapper> = ({ event, children, calView, allEventItems, refresh, priorityPromotions }) => {
    // Your custom event wrapper logic
    return (
        <Tooltip
            title={(window.innerWidth<992)?"":<CustomTooltip type={"event"} data={event} calView={calView} allEventItems={allEventItems} refresh={refresh} priorityPromotions={priorityPromotions}/>}
            className="overlap-tooltip"
            color="#F3F6F9"
        >
            {children}
        </Tooltip>
    );
};

export const CustomTooltip = ({ type, data, calView, allEventItems, refresh, priorityPromotions }: { type: any, data: any, calView: any, allEventItems: any, refresh: Function, priorityPromotions?:any; }) => {
    const googleAccessToken = localStorage.getItem("googleAccessToken");

    let conflicts:any = [];
    let prio:any = [];
    if (data.conflicts !== undefined) {
        prio = priorityPromotions?.filter((p:any)=>data?.conflicts?.includes(p));
        conflicts = data.conflicts?.filter((c:any)=>!priorityPromotions.includes(c));
    } else if (data.classes !== undefined) {
        prio =priorityPromotions?.filter((p:any)=>data?.classes?.includes(p));
        conflicts = data.classes?.filter((c:any)=>!priorityPromotions.includes(c));
    }
    conflicts = [...prio,...conflicts];

    const EventBox = (event:any) => {
        const [isEventLoading, setIsEventLoading] = useState(false);
        let isUpcomingEvent = (moment(event.start).isAfter(new Date()));
        let isGoogleEvent = (event.isGoogleEvent===true && event.isAddedEvent===false);
        return (<p className="event-details" style={{border:event?.isPriority?'2px solid #FFA940':event?.isHighlighted?'2px solid #02B8F680':'none'}}>
            <div className="data">
                {event?.isPriority===true && (
                    <div className='promoted'>
                        Promoted 
                        <img src={Promo}/>
                    </div>
                )}
                {event?.isPriority===false && event?.isHighlighted === true && (
                    <img src={Highlight} className='highlighted'/>
                )}
                <h4>
                    <Tooltip title={event?.title}>
                    <img src={isGoogleEvent?GoogleCircle:BreezeBlue}/>
                    {event.title}
                    </Tooltip>
                </h4>
                <span className="time">
                    <p>{moment(event.start).format(
                        "h:mm A"
                    )}
                        - {moment(event.end).format(
                            "h:mm A"
                        )}
                    </p>
                </span>
                {
                    event.mutual&&event.mutual.length>0
                    ?(
                        <Avatar.Group
                        maxCount={3}
                        // maxPopoverTrigger="click"
                        maxPopoverTrigger="hover"
                        size={18}
                        maxStyle={{ color: '#0098EE', backgroundColor: '#D3F4FF', cursor: 'pointer', pointerEvents:'none' }}
                        className="avatar-group"
                        >
                        {event.mutual?.map((s:any)=>
                            <Tooltip title={s.displayName} placement="top">
                            <Avatar style={{ backgroundColor: contactsColors[Math.floor(Math.random() * 6)] }} src={s.profilePicture??null}>{s.displayName.match(/\b(\w)/g).join('')}</Avatar>
                            </Tooltip> 
                        )}
                        </Avatar.Group>
                    )
                    :(
                        <span className="mutuals">No mutual(s)</span>
                    )
                }
            </div>
            {(!event.isGoogleEvent)&&(event.metadata !== null)&& isUpcomingEvent && (
                <div className="add-event-spin">
                <Spin spinning={isEventLoading}>
                    <PlusCircleFilled className="add-event-btn" onClick={async (e) => {
                            e.stopPropagation();
                            try {
                                setIsEventLoading(true);
                                const res = await createCalendarEvent({
                                    accessToken: googleAccessToken,
                                    eventId: event.eventId
                                });
                                let call = await res({});
                                if (call.status === 200) {
                                    message.success(`You successfully added ${event?.title}!`);
                                    refresh();
                                }
                                setIsEventLoading(false);
                            }
                            catch (e) { 
                                message.error('An Error Occured');
                            }
                        }
                    } />
                </Spin>
                </div>
            )}
        </p>)
    }

    return (
        <div className="custom-tooltip">
            {calView !== "month" &&
                type === "event" &&
                (data.conflicts === undefined || conflicts?.length===0) && (
                    <>
                        <h3>{data.title}</h3>
                        <p>
                            {moment(data.actualStart ?? data.start).format("dddd, MMMM D h:mm a ")} -{" "}
                            {moment(data.actualStart ?? data.start).isSame(data.actualEnd ?? data.end, "day")
                                ? moment(data.actualEnd ?? data.end).format("h:mm a")
                                : moment(data.actualEnd ?? data.end).format("dddd, MMMM D h:mm a")}
                            <br />
                        </p>
                    </>
                )
            }
            {
                type === "event" && (conflicts?.length > 0) && (
                    <>
                        <h3 className="conflict-time">{moment(data.start).format("dddd D - ha")} {data.isAddedEvent?"Added":data.isGoogleEvent?"Google":"Breeze"} Events </h3>
                        <p className="conflict-list">
                            {conflicts?.map((c: any) => { 
                                let event = allEventItems.filter((a: any) => a.eventId === c)[0];
                                return(
                                    <EventBox {... event}/>
                                )
                            }
                            )}
                        </p>
                    </>
                )}
        </div>
    );
};

export const getStyling = (event: any) => {
    let eventProps: any = {};
    if (event.allDay) {
        eventProps.style = {
            color: '#02B8F6',
            border: '1px solid #02B8F6',
            backgroundColor: '#FFF', borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else if (event.isAddedEvent) {
        eventProps.style = {
            color: '#FFF',
            borderColor: '#02B8F6',
            backgroundColor: '#02B8F6', borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else if (event.isHighlighted || event.isPriority) {
        eventProps.style = {
            color: '#595959',
            borderColor: '#859CA1',
            backgroundImage: 'linear-gradient(90.13deg, #D3F4FF 26%, #6DD7F8 102.28%)',
            borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else if (event.isClass || event.isGoogleEvent) {
        eventProps.style = {
            color: '#FFF',
            borderColor: '#859CA1',
            backgroundColor: '#859CA1', borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    } else {
        eventProps.style = {
            color: '#595959',
            borderColor: '#D3F4FF',
            backgroundColor: '#D3F4FF',
            borderRadius: "5px",
            filter: moment(event.actualStart ?? event.start).isAfter(new Date()) ? null : 'opacity(50%) brightness(100%)',
        };
    }
    return eventProps.style;
}


interface IEventComponentDay {
    event: any;
    refresh: any;
}

export const EventComponentDay: React.FC<IEventComponentDay> = ({ event, children, refresh }) => {
    const googleAccessToken = localStorage.getItem("googleAccessToken");
    let isUpcomingEvent = (moment(event.actualStart ?? event.start).isAfter(new Date()));
    let isMultiDay = (moment(event.start ?? event.actualStart).day() !== (moment(event.end ?? event.actualEnd).day()));
    const [isLoading, setIsLoading] = useState(false);
    return (
        <div className='d-flex w-100 h-100' style={{ gap: "6px" }}>
            {event?.neighborCount>1 && event.conflicts !== undefined && !event.isAddedEvent && !event.isGoogleEvent && (
                    <div className='d-flex' style={{
                        color: '#595959',
                        borderColor: '#D3F4FF',
                        backgroundColor: '#D3F4FF', justifyContent: "center", alignItems: "center",
                        // minWidth: "100px",
                        width:'100%',
                        paddingLeft: "10px",
                        paddingRight: "10px",
                        borderRadius: "5px",
                    }}> <p className="more" style={{ fontWeight: "bold", color: "#0098EE", margin: 0 }}>+ {event.conflicts?.length} More</p>
                    </div>)
            }
            {(event?.neighborCount===undefined || event?.neighborCount<=1 || event.isGoogleEvent) &&
            <div className={`event-component-day ${isMultiDay && "multi"} w-100`} style={getStyling(event)}>
                <span className="upper">
                    <img src={event.isAddedEvent ? BreezeWhite : event.isGoogleEvent ? GoogleCircle : BreezeBlue} />
                    <span className={`details ${isMultiDay&&'multiday'}`}>
                        <p className="title">{event.title}</p>
                            <p className="time">{moment(event?.actualStart ?? event?.start).format("h:mm a")} - {moment(event?.actualStart ?? event?.start).isSame(event?.actualEnd ?? event?.end, 'day') ?
                                moment(event?.actualEnd ?? event?.end).format("h:mm a")
                                : moment(event?.actualEnd ?? event?.end).format("MMM D, h:mm a")}</p>
                    </span>
                    {(event?.isHighlighted===true||event?.isPriority===true)&&(
                        <img src={Promo} className={(!event.isGoogleEvent && isUpcomingEvent)?"shifted-promo":"promoIcon"}/>
                    )}
                </span>
                <div className={isMultiDay?'multiday-lower':"lower"}>
                    {!event.isGoogleEvent && isUpcomingEvent &&
                        <Spin spinning={isLoading}>
                            <PlusCircleFilled className="addIcon" onClick={async (e) => {
                                e.stopPropagation();
                                try {
                                    setIsLoading(true);
                                    const res = await createCalendarEvent({
                                        accessToken: googleAccessToken,
                                        eventId: event.eventId ?? event?.conflicts[0]
                                    });
                                    let call = await res({});
                                    if (call.status === 200) {
                                        refresh();
                                        message.success(`You successfully added ${event?.title}!`);
                                    }
                                    setIsLoading(false);
                                }
                                catch (e) { 
                                    message.error('An Error Occured');
                                }
                            }
                            } />
                        </Spin>
                    }
                    {
                        event.conflicts !== undefined && event.conflicts !== null && event.conflicts?.length > 1 && (<p className={event.isGoogleEvent?"more-w":"more"}>+ {event.conflicts?.length-1} <u>More</u></p>)
                    }
                </div>
            </div>
            }
        </div>
    )
};

export const TimeGutterHeader = ({expand, setExpandAllDay, count, view }: {expand: any, setExpandAllDay: Function, count: any, view: any }) => {
    return (
        <div className="time-gutter-header">
            {count.total > 0 && (
                expand===false ? (
                    <p className='label'>All Day Events <span className='blue-text'>{(count.additional)>0 && `(+${count.additional})`}</span></p>
                ):(
                    <p className='label'>Show Less</p>
                )
            )}
            {count.additional>0 && (
                <Button onClick={()=>setExpandAllDay(!expand)} className='arrow-btn'>
                    {expand===true?
                        <UpOutlined/>
                    :<DownOutlined/>}
                </Button>
            )}
        </div>
    )
};