import { useEffect, useState } from "react";
import { Button, Modal, Carousel } from "antd";
import moment from "moment";

export const ModalBannerPromotion = (props: {
  isOpen: boolean;
  setIsOpen: Function;
  data: any;
}) => {
  const { isOpen, setIsOpen, data } = props;
  let sortDate = (data?.sort((a:any, b:any)=>moment(a.createdOn).diff(b.createdOn)));
  let prioritySorted = (sortDate.sort((a:any, b:any)=>a.priority - b.priority));    //if same priority value, oldest is shown first

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      centered
      title="Promotions"
      className="modal-banner-promotion"
      visible={isOpen}
      onCancel={onCancelHandler}
      closable={true}
      footer={null}
    >
        <Carousel
            arrows={true}
            autoplay={true}
            draggable={true}
            adaptiveHeight={true}
            className="banner-carousel"
        >
        {prioritySorted?.map((p:any)=>
            <a href={p?.url} target="_blank">
                <img src={p?.bannerFileUrl}/>
            </a>
        )}
        </Carousel>
    </Modal>
  );
};

export default ModalBannerPromotion;
