import { Fragment, useState, useEffect } from "react";
import { Button, Modal, Tooltip, message, Input, Form, Divider, Tag, Space, Avatar, Checkbox } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { UserOutlined, SearchOutlined, MailOutlined } from "@ant-design/icons";
import type { CheckboxChangeEvent } from 'antd/es/checkbox';
import { contactsColors } from "../../helpers/contactsColors";
import { getContacts, getDBContacts, addContact, removeContact, addEmail } from "../../actions/contacts";
import PersonAdd from '../../assets/images/icons/person_add.svg'

const { Search } = Input;

const ModalImportContacts = (props: {
  visible: boolean,
  onCancel: Function,
  addContact: Function,
  addEmail: Function,
  getContacts: Function;
  getDBContacts: Function;
  removeContact: Function,
  googleAccessToken: string,
}) => {
  const {
    visible,
    onCancel,
    addEmail,
    addContact,
    getContacts,
    getDBContacts,
    removeContact,
    googleAccessToken,
  } = props;
  const [showMoreContacts, setShowMoreContacts] = useState(false);
  const [showMoreAdded, setShowMoreAdded] = useState(false);
  const [allContacts, setAllContacts] = useState([]);
  const [contactList, setContactList] = useState([]);
  const [addEmailList, setAddEmailList] = useState<string>("");
  const [searching, setSearching] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isContactUpdated, setIsContactUpdated] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();

  const success = (email: string) => {
    messageApi.open({
      type: 'success',
      content: `You followed ${email}`,
    });
  };

  const successInvite = (email: string) => {
    messageApi.open({
      type: 'success',
      content: `You sent an invite to ${email}`,
    });
  };

  const exists = () => {
    messageApi.open({
      type: 'warning',
      content: 'Contact Already Exists!',
    });
  };
  const error = () => {
    messageApi.open({
      type: 'error',
      content: 'Invalid Email',
    });
  };
  const successRemove = (email: string) => {
    messageApi.open({
      type: 'success',
      content: `You unfollowed ${email}`,
    });
  };
  const errorRemove = (email: string) => {
    messageApi.open({
      type: 'error',
      content: `Error unfollowing ${email}`,
    });
  };

  const onSearch = (value: any) => {
    if (value !== "") {
      setSearching(true);
      setContactList(allContacts.filter((c: any) => (c.email).toLowerCase().includes(value.toLowerCase())));
    } else {
      setSearching(false);
      setContactList(allContacts);
    }
  }

  const onAdd = async (contact: any) => {
    setLoading(true);
    let res = await addContact({
      accessToken: googleAccessToken,
      email: contact?.email,
    });
    if (res.status === 200) {
      success(contact?.email);
      // fetchContacts();
      setIsContactUpdated(true);
    }
    setLoading(false);
    setSearching(false);
  }

  const onAddEmail = async () => {
    if (addEmailList !== "") {
      var re = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
      if (re.test(addEmailList) === true) {
        if (contactList?.some((s: any) => (s.status === 1) && (s.email === addEmailList))) {
          exists();
        } else {
          setLoading(true);
          let res = await addContact({
            email: addEmailList,
          });
          if (res.status === 200) {
            successInvite(addEmailList);
            // fetchContacts();
            setAddEmailList("");
            setIsContactUpdated(true);
          }
          setLoading(false);
        }
      } else {
        error();
      }
    }
  }

  const onRemove = async (contact: any) => {
    setLoading(true);
    let res = await removeContact({ id: contact.id });
    if (res.status === 204) {
      successRemove(contact?.email);
      // fetchContacts();
      setIsContactUpdated(true);
    } else {
      errorRemove(contact?.email);
    }
    setLoading(false);
    setSearching(false);
  }

  // const fetchContacts = async () => {
  //   let res = await getContacts({ accessToken: googleAccessToken });
  //   if (res.status === 200) {
  //     setContactList(res.data);
  //     setAllContacts(res.data);
  //     refreshContacts();
  //   }
  // };

  const refreshContacts = async () => {
    let res = await getDBContacts();
    if (res.status === 200) {
      setContactList((previous) => removeDuplicates([...res.data], [...previous]));
      setAllContacts((previous) => removeDuplicates([...res.data], [...previous]));
    }
  };

  const removeDuplicates = (arr1: any, arr2: any) => {
    const mergedArr = arr1.concat(arr2);
    return mergedArr.filter(
      (v: any, i: any, s: any) =>
        mergedArr.findIndex((e: any) => e.email == v.email) === i
    );
  };

  useEffect(() => {
    refreshContacts();
    // if (visible === true) {
    //   fetchContacts();
    // }
  }, [visible])

  const Contact = (data: any) => {
    let c = data.data;
    return (
      <div className="contact">
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Avatar size={50} style={{ backgroundColor: contactsColors[Math.floor(Math.random() * 6)] }} src={c?.profilePicUrl ?? null}>
            {c?.email.charAt(0).toUpperCase()}
          </Avatar>
          {/* <img src={(c?.profilePicUrl)}/> */}
          {/* <div className="text-group">
            <h3>
              {c?.displayName}
            </h3>
            <div className="mutuals">
              <Avatar size={20} style={{ fontSize:'12px', backgroundColor: '#0098EE' }} src={<UserOutlined/>}/>
              <p>
                n Mutual Friends
              </p>
            </div>
          </div> */}
          {/* NOTE: Temporary removal of mutual friends due to implementation */}
          <div className="text-group" style={{ display: "flex", alignItems: "center" }}>
            <h3 style={{ marginBottom: 0 }}>
              {c?.email}
            </h3>
            {/* <div className="mutuals">
                <Avatar size={20} style={{ fontSize:'12px', backgroundColor: '#0098EE' }} src={<UserOutlined/>}/>
                <p>
                  n Mutual Friends
                </p>
              </div> */}
          </div>
        </div>
        <Button type={c.status === 0 ? "primary" : "default"} shape="round" loading={loading}
          onClick={() => c.status === 0 ? onAdd(c) : onRemove(c)} >
          {c.status === 0 ? "Add" : "Added"}
        </Button>
      </div>
    )
  }

  return (
    <>
      {contextHolder}
      <Modal
        className="modal-import-contacts"
        centered
        visible={visible}
        onCancel={() => {
          onCancel(isContactUpdated);
          setIsContactUpdated(false);
        }}
        footer={null}
        title="Import Contacts"
      >

        <div className="header-options mb-3">
          {/* <Button onClick={refreshContacts}>Refresh</Button> */}
          <h3>Import Email Address</h3>
          <div className="text-group" style={{ display: "flex", alignItems: "center" }}>
            <Input addonBefore={<MailOutlined />} value={addEmailList} placeholder="Enter Email Address" allowClear onChange={(e) => setAddEmailList(e.target.value)} />
            <Tooltip title="Add Email">
              <Button
                type="primary"
                shape="round"
                className="add-contact-btn ml-2"
                onClick={onAddEmail}
                disabled={addEmailList === ""}
              ><img src={PersonAdd} />
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="header-options">
          <h3>Search Contact</h3>
          <Search
            // addonBefore={<SearchOutlined/>}
            className="search"
            placeholder="Search or import contacts" allowClear onSearch={onSearch} />
        </div>
        {!searching &&
          (<>
            <div className="contact-list">
              <h3>Contacts</h3>
              {contactList?.filter((s: any) => s.status === 1).length > 0 ? contactList?.filter((s: any) => s.status === 1).slice(0, showMoreAdded ? 10 : 3).map((c: any, index: number) =>
                <Contact key={index} data={c} />
              ) : <h3 style={{ textAlign: 'center' }}>No Contacts</h3>}
            </div>
            {contactList?.filter((s: any) => s.status === 1).length > 3 && <Button className="show-more-btn" onClick={() => setShowMoreAdded(!showMoreAdded)}>{showMoreAdded ? 'Show Less' : `+${contactList?.filter((s: any) => s.status === 1).length === 13 ? "10" : contactList?.filter((s: any) => s.status === 1).length - 3} More`}</Button>}
          </>
          )
        }
        {/* <div className="contact-list">
          <h3>Contacts</h3>
          {searching && contactList.length === 0 &&
            <h3 style={{ textAlign: 'center' }}>No Results</h3>
          }
          {contactList?.filter((s: any) => s.status === 0 && s.email !== "").slice(0, showMoreContacts ? contactList.length : 3).map((c: any, index: number) =>
            <Contact key={index} data={c} />
          )}
        </div>
        {contactList?.filter((s: any) => s.status === 0 && s.email !== "").length > 3 && <Button className="show-more-btn" onClick={() => setShowMoreContacts(!showMoreContacts)}>{showMoreContacts ? 'Show Less' : 'Show More'}</Button>} */}
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { auth: any; userIsBack: boolean, googleAccessToken: string }) => ({
  googleAccessToken: state.auth?.googleAccessToken,
});

export default connect(mapStateToProps, { addContact, removeContact, getContacts, addEmail, getDBContacts })(ModalImportContacts);