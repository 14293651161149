import { RECENT_LOGOUT } from "./types";

export const setRecentLogout = (payload) => (dispatch) => {
  {
    dispatch({
      type: RECENT_LOGOUT,
      payload
    });
  }
};
