import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
} from "antd";

import { Fragment, useEffect, useState } from "react";
import ContactItem from "./ContactItem";
import { connect } from "react-redux";
import {
  getFriendApi,
  unFriendApi,
  blockFriendApi,
} from "../../../actions/social";
import { ACTIVE, PENDING } from "../../../enums/friendTypes";
import NoFriends from "../../../assets/images/illustration/empty-friends.svg";
import { FRIENDREQUESTSPAGE } from "../../../enums/SocialPageTypes";
import { contactsColors } from "../../../helpers/contactsColors";

const FriendsComponent = (props: {
  getFriendApi: Function;
  setActivePage: Function;
  unFriendApi: Function;
  searchTerm: String;
  blockFriendApi: Function;
}) => {
  const {
    getFriendApi,
    setActivePage,
    unFriendApi,
    searchTerm,
    blockFriendApi,
  } = props;
  const [friendsData, setFriendsData] = useState<any[]>([]);
  const [originalFriendsData, setOriginalFriendsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchFriends();
  }, []);

  useEffect(() => {
    const searchResults = originalFriendsData.filter((item) =>
      Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFriendsData(searchResults);
  }, [searchTerm]);

  const fetchFriends = async () => {
    setIsLoading(true);
    let data = await getFriendApi(ACTIVE);
    const friendDataWithColor = data.data.map((friend: any) => {
      return {
        ...friend,
        color:
          contactsColors[Math.floor(Math.random() * contactsColors.length)],
        mutualFriends: friend.mutualFriends.map((mutual: any) => {
          return {
            ...mutual,
            color:
              contactsColors[Math.floor(Math.random() * contactsColors.length)],
          };
        }),
      };
    });
    setFriendsData(friendDataWithColor);
    setOriginalFriendsData(friendDataWithColor);
    setIsLoading(false);
  };

  const unfriendHandler = async (id: String) => {
    let data = unFriendApi(id);
    const filteredItems = friendsData.filter((item: any) => item.id !== id);
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };
  const blockFriendHandler = async (emailAddress: String, id: String) => {
    let data = blockFriendApi(emailAddress);
    data = data.data;
    const filteredItems = friendsData.filter(
      (item: any) => item.id !== id
    );
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  const [colors, setColors] = useState<any[]>([]);

  useEffect(() => {
    const generatedColors = friendsData.map(() => {
      return contactsColors[Math.floor(Math.random() * contactsColors.length)];
    });
    setColors(generatedColors);
  }, []); // Empty dependency array ensures this only runs once on mount

  return (
    <div className="contact-list-container">
      <h3 className="list-title d-flex align-items-center">
        Friends <Badge showZero className="ml-1" count={friendsData.length} />
      </h3>
      <div className="contact-list">
        {friendsData &&
          friendsData.map((friend, index) => (
            <ContactItem
              key={friend.id}
              data={friend}
              unfriendHandler={unfriendHandler}
              blockFriendHandler={blockFriendHandler}
            />
          ))}

        {friendsData.length == 0 && !isLoading && (
          <div className="d-flex justify-content-center d-flex-column align-items-center mt-5">
            <img src={NoFriends} width="auto" height="400px" />
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={12}
              style={{ margin: "0 auto", flex: 0 }}
            >
              <div className="d-flex align-items-center d-flex-column">
                <h1
                  style={{ fontWeight: "bolder" }}
                  className="mt-4 mb-2 text-center"
                >
                  It looks like you have not added friends yet.
                </h1>
                <p className="text-center" style={{ color: "#9E9E9E" }}>
                  Start adding friends to go to events together
                </p>
                <Button
                  type={"primary"}
                  className="btn-breeze-primary"
                  onClick={() => setActivePage(FRIENDREQUESTSPAGE)}
                >
                  Add friends
                </Button>
              </div>
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { getFriendApi, unFriendApi, blockFriendApi })(
  FriendsComponent
);
