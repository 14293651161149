import {
  Button,
  PageHeader,
  Space,
  Table,
  Input,
  TablePaginationConfig,
  Menu,
  Dropdown,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";

import { Fragment, useEffect, useState } from "react";
import { connect } from "react-redux";
import { getMemberList } from "../../../actions/school";

import { Link, useNavigate } from "react-router-dom";
import { SchoolAdmin } from "../../../helpers/memberTypes";
import { FilterValue } from "antd/lib/table/interface";

const { Search } = Input;

const menu = (
  <Menu>
    <Menu.Item key="dashboard">
      <Link to="dashboard">more here</Link>
    </Menu.Item>
  </Menu>
);

const DropdownMenu = () => (
  <Dropdown key="more" overlay={menu} placement="bottomRight">
    <Button
      className="icon-rotate button-bordered"
      type="text"
      icon={<MoreOutlined style={{ fontSize: 20 }} />}
    />
  </Dropdown>
);

const SchoolAdminList = (props: any) => {
  let navigate = useNavigate();
  const columns = [
    {
      title: "Name",
      dataIndex: "firstName",
      key: "name",
      render: (text: any, record: any) => (
        <a>{record.firstName + " " + record.lastName}</a>
      ),
    },
    {
      title: "School",
      dataIndex: "schoolName",
      key: "school",
    },
    {
      title: "Job Title",
      dataIndex: "jobTitle",
      key: "jobtitle",
    },
    {
      title: "Email",
      dataIndex: "emailAddress",
      key: "email",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text: any) => <a>{text}</a>,
    },
    {
      title: "Action",
      key: "action",
      render: (text: any, record: any) => (
        <Space size="middle">
          <a id={record.memberId} onClick={() => editMemberHandler(record)}>
            Edit
          </a>
        </Space>
      ),
    },
  ];

  const { getMemberList } = props;
  const [statusFilter, setStatusFilter] = useState(-1);
  const [searchString, setSearchString] = useState("");
  const [schoolAdminList, setSchoolAdminList]: any = useState([]);
  const initialPagination = {
    current: 1,
    pageSize: 10,
    position: ["bottomLeft"],
    size: "small",
  };
  const [pagination, setPagination]: any = useState(initialPagination);
  const [isLoadingSchoolAdminList, setIsLoadingSchoolAdminList] =
    useState(false);

  const onfilterStatusHandler = (e: any) => {
    setStatusFilter(e.target.value);
  };

  const editMemberHandler = (record: any) => {
    navigate("/admin/school-admin/add-user", {
      state: {
        memberId: record?.memberId,
        firstName: record?.firstName,
        lastName: record?.lastName,
        schoolId: record?.schoolId,
        emailAddress: record?.emailAddress,
        jobTitle: record?.jobTitle,
      },
    });
  };

  const handleTableChange = async (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>
  ) => {
    fetchList(pagination);
  };

  const fetchList = async (parameters: object) => {
    setIsLoadingSchoolAdminList(true);
    let params;
    if (searchString) {
      params = {
        ...parameters,
        searchString,
        memberType: SchoolAdmin,
        status: statusFilter,
      };
    } else {
      params = { ...parameters, memberType: SchoolAdmin, status: statusFilter };
    }
    const data: any = await getMemberList(params);
    if (data.status === 200) {
      setSchoolAdminList(data.data.items);
      setPagination({
        current: data.data.page,
        pageSize: data.data.pageSize,
        total: data.data.totalItems,
      });
      setIsLoadingSchoolAdminList(false);
    }
  };

  useEffect(() => {
    fetchList(initialPagination);
  }, [searchString, statusFilter]);

  return (
    <Fragment>
      <div className="app-main-content-wrapper">
        <PageHeader
          className="site-page-header content-header"
          onBack={() => null}
          title="School Admin"
          extra={[
            <Button key="1">
              <Link to={"/admin/school-admin/add-user-bulk"}>
                Upload School Admins
              </Link>
            </Button>,
            <Button key="2" type="primary">
              <Link to={"/admin/school-admin/add-user"}>
                Add New School Admin
              </Link>
            </Button>,
            <DropdownMenu key="more" />,
          ]}
        />
        <div className="app-main-content">
          <div className="app-main-content-inner">
            <div className="d-flex h-100 w-100 d-flex-column">
              <div className="table-controls d-flex pb-3">
                <div className="my-auto mr-auto">
                  Status:{" "}
                  <select onChange={onfilterStatusHandler}>
                    <option value="-1">All</option>
                    <option value="1">Active</option>
                    <option value="2">Inactive</option>
                    <option value="0">Pending</option>
                  </select>
                </div>
                <div>
                  <Search
                    //onKeyUp={onFilterSearchHandler}
                    onSearch={(val) => setSearchString(val)}
                    allowClear
                    placeholder="Search by Name, School &amp; Job Title"
                    style={{ width: 280 }}
                  />
                </div>
              </div>
              <Table
                columns={columns}
                pagination={{
                  ...pagination,
                  position: ["bottomLeft"],
                  showTotal: (total: number, range: [number, number]) =>
                    `Total ${total} items`,
                  size: "medium",
                }}
                dataSource={schoolAdminList}
                loading={isLoadingSchoolAdminList}
                onChange={handleTableChange}
              />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default connect(null, { getMemberList })(SchoolAdminList);
