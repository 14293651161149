import { v4 as uuidv4 } from "uuid";
import { SET_PUSH_DATA, REMOVE_PUSH_DATA } from "./types";

export const setPushData = (payload) => (dispatch) => {
  {
    const id = uuidv4();
    dispatch({
      type: SET_PUSH_DATA,
      payload,
    });

    dispatch({ type: REMOVE_PUSH_DATA, payload });
  }
};
