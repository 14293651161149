import { connect } from "react-redux";
import { clearAuth, loadUser, logout, updateTokens } from "../../actions/auth";
import { Dropdown, Layout, Menu, Spin, message, Divider, Badge } from "antd";

import { authQrCode } from "../../actions/event";
import { useNavigate } from "react-router-dom";
import { Fragment, useEffect, useState } from "react";
import ModalLogout from "../layout/ModalLogout";
import { Content, Footer, Header } from "antd/lib/layout/layout";
import breezeHorizontalLogoBlue from "../../assets/images/logos/breeze-horizontal-blue.svg";
import PersonAdd from "../../assets/images/icons/person_add_b.svg";
import BlockImg from "../../assets/images/icons/block.svg";
import userNoPhoto from "../../assets/images/icons/user-no-photo.svg";
import flame from "../../assets/images/streak/flame-2.svg";
import { Link, Route, Routes, useLocation } from "react-router-dom";
import {
  MenuOutlined,
  CloseOutlined,
  CalendarOutlined,
  SettingOutlined,
  LogoutOutlined,
  ImportOutlined,
} from "@ant-design/icons";
import MemberRoute from "./routes/MemberRoute";
import Onboarding from "./onboarding/Onboarding";
import DefaultAvatar from "../widget/DefaultAvatar";

import {
  searchUpcomingEvents,
  searchSchoolWideEvents,
} from "../../actions/event";

import moment, { Moment } from "moment";
import Scanner from "../widget/Scanner";
import { setAlert } from "../../actions/alert";
import flutterConnect from "../../reducers/flutterConnect";

const MemberApp = (props: any) => {
  const [hideNav, setHideNav] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [dropdownVisible2, setDropdownVisible2] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);
  const [isFlyoutVisible, setIsFlyoutVisible] = useState(false);
  const [isQrExpanded, setIsQrExpanded] = useState(false);
  const [isScannerOpen, setIsScannerOpen] = useState(false);
  const [showQr, setShowQr] = useState(false);
  const [eventsToday, setEventsToday] = useState<any>([]);
  const navigate = useNavigate();

  const handleVisibleChange = (flag: boolean) => {
    setDropdownVisible(flag);
  };

  const handleVisibleChange2 = (flag: boolean) => {
    setDropdownVisible2(flag);
  };

  const [initializing, setInitializing] = useState(true);
  const { auth, flutterConnect } = props;
  const {
    auth: { isAuthenticated, displayName, member, loading, token },
    logout,
    clearAuth,
    updateTokens,
  } = props;
  const [modal2Visible, setModal2Visible] = useState(false);
  const location = useLocation();

  // var flutterConnect = true;

  const parseQr = async (qr: string) => {
    const parsedQr = qr.split(";", 3);

    if (parsedQr.length === 3) {
      message.success("QR Code Scanned Successfully!");
      const res = await props.authQrCode({
        connectionId: parsedQr[0],
        eventId: parsedQr[1],
        code: parsedQr[2],
      });
    } else {
      message.error("QR Code Failed to Scan.");
    }
  };

  const getMyEventsToday = async () => {
    const params = {
      page: 1,
      pageSize: 50,
      isDesc: false,
      schoolEventsOnly: false,
      from: moment().startOf("day").toISOString(),
      to: moment().endOf("day").toISOString(),
    };
    let clubEvents = await props.searchUpcomingEvents({
      ...params,
      MyEventsOnly: true,
      schoolEventsOnly: false,
    });

    let schoolwideEvents = await props.searchSchoolWideEvents({
      ...params,
      MyEventsOnly: false,
      schoolEventsOnly: true,
    });

    var events: any = [];

    if (
      clubEvents.data &&
      clubEvents.data.items &&
      clubEvents.data.items.length > 0
    )
      events = events.concat(clubEvents.data.items);

    if (
      schoolwideEvents.data &&
      schoolwideEvents.data.items &&
      schoolwideEvents.data.items.length > 0
    )
      events = events.concat(schoolwideEvents.data.items);

    if (events.length > 0) setShowQr(true);
    else setShowQr(false);

    const sortedEvents = events
      .filter((e: any) => e.membershipTypeName === "Moderator")
      .map((e: any) => {
        if (moment(e.endDate) < moment()) e.disabled = true;
        else e.disabled = false;

        return e;
      })
      .sort((e: any, f: any) => {
        return (
          f.disabled - e.disabled ||
          moment.utc(f.startDate).diff(moment.utc(e.startDate))
        );
      });
    setEventsToday(sortedEvents);
  };

  useEffect(() => {
    if (
      location.pathname.indexOf("events/add") > 0 ||
      location.pathname.indexOf("interests") > 0 ||
      location.pathname.indexOf("club-proposal") > 0
    ) {
      setHideNav(true);
    } else {
      setHideNav(false);
    }
  }, [location.pathname]);

  useEffect(() => {
    // const triggerInterceptor = async () => {
    //   await axiosInterceptors(auth, clearAuth, updateTokens);
    setInitializing(false);
    // };
    // triggerInterceptor();

    // loadUser();

    if (flutterConnect) {
      // Handle QR Value from native scanner
      (window as any).handleQrValue = (qr: any) => {
        parseQr(qr);
      };
    }

    const onScroll = () => {
      setScrollOffset(window.scrollY);
    };
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });

    // NOTE: Disabled for pivot
    // getMyEventsToday();

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  const onLogoutHandler = () => {
    // showBlurEffect2();
    setModal2Visible(true);
  };

  const onLogoutCancel = () => {
    // hideBlurEffect2();
    setModal2Visible(false);
  };

  const getUpdates = () => {
    getMyEventsToday();
  };

  const handleHideNotification = () => {
    if (dropdownVisible) setDropdownVisible(false);
    if (dropdownVisible2) setDropdownVisible2(false);
  };

  const handleScannerClose = () => {
    setIsScannerOpen(false);
  };

  let leftMenuItems = [
    // {
    //   key: "dashboard",
    //   label: <Link to={"/dashboard"}>Dashboard</Link>,
    // },
    {
      key: "calendar",
      label: <Link to={"/calendar"}>Calendar</Link>,
    },
    // {
    //   key: "events",
    //   label: <Link to={"/events"}>Events</Link>,
    // },
    // {
    //   key: "clubs",
    //   label: <Link to={"/clubs"}>Clubs</Link>,
    // },
    // {
    //   key: "profile",
    //   label: <Link to={"/profile"}>Profile</Link>,
    // },
    // {
    //   key: "myProfile",
    //   label: <Link to={"/dashboard"}>My Profile</Link>
    // },
    {
      key: "blocklist",
      label: <Link to={"/blocklist"}>Blocklist</Link>,
    },
    {
      key: "settings",
      label: <Link to={"/settings"}>Settings</Link>,
    },
  ];

  const isScrolled = window.innerHeight * 0.3;

  useEffect(() => {
    if (!member.baseInterestIds) {
      navigate("/interests");
    }
  }, []);

  return initializing ? (
    <div
      className={`d-flex justify-content-center w-100 h-100 ${
        flutterConnect ? "flutter" : ""
      }`}
    >
      <div className="my-auto">
        <Spin />
      </div>
    </div>
  ) : (
    <div className={`app-member-layout ${flutterConnect ? "flutter" : ""}`}>
      <ModalLogout
        onLogoutCancel={onLogoutCancel}
        modal2Visible={modal2Visible}
        logout={logout}
      />
      {!hideNav && (
        <Layout>
          {/* <Layout className="ant-layout-fixed-footer"> */}
          <Header
            className="desktop-header"
            style={{ position: "fixed", zIndex: 10, width: "100%" }}
          >
            <div className="menu-wrapper">
              <Link to={"/calendar"}>
                <div
                  className="logo"
                  style={{
                    backgroundImage: `url(${breezeHorizontalLogoBlue})`,
                  }}
                />
              </Link>
              <Menu className="secondMenus" mode="horizontal">
                {member?.loginStreak>1 && (
                  <Badge count={member?.loginStreak} color="#f35d38" offset={[0, 22]} size="small">
                    <img src={flame} className="badge"/>
                  </Badge>
                )}
                <Dropdown
                  key="more"
                  overlay={
                    <Menu>
                      <Menu.Item key="blocklist">
                        <Link to={"/contacts/blocklist"}>Blocklist</Link>
                      </Menu.Item>
                      {member.memberType === "Teacher" && (
                        <Menu.Item key="import">
                          <Link to={"/admin"}>Import Event</Link>
                        </Menu.Item>
                      )}
                      <Menu.Item key="settings">
                        <Link to={"/settings"}>Settings</Link>
                      </Menu.Item>
                      <Divider className="dropdown-divider" />
                      <Menu.Item key="logout">
                        <a onClick={() => onLogoutHandler()}>Logout</a>
                      </Menu.Item>
                    </Menu>
                  }
                  placement="bottomRight"
                >
                  <Menu.Item key="4">
                    <div className="user-container">
                      {member.profilePicture ? (
                        <div>
                          <img
                            src={member.profilePicture}
                            // src={member.profilePicture + "?" + Date.now()}
                            height={24}
                            width={24}
                          />{" "}
                          <h5>{member.firstName + " " + member.lastName}</h5>
                        </div>
                      ) : (
                        <Fragment>
                          <DefaultAvatar
                            firstName={member.firstName}
                            lastName={member.lastName}
                          />{" "}
                          <h5>{member.firstName + " " + member.lastName}</h5>
                        </Fragment>
                      )}
                    </div>
                  </Menu.Item>
                </Dropdown>
              </Menu>
            </div>
          </Header>
          <div className="mobile-header">
            <div className="left">
              <MenuOutlined
                className="nav-item"
                onClick={() => setIsFlyoutVisible(true)}
              />
              <div
                className="logo"
                style={{ backgroundImage: `url(${breezeHorizontalLogoBlue})` }}
              />
            </div>

            <div className="nav-items">
              {/* <QuestionCircleOutlined className="nav-item" /> */}
              {member?.loginStreak>1 && (
                  <Badge count={member?.loginStreak} color="#f35d38" offset={[0, 0]} size="small"> 
                    <img src={flame} className="badge"/>
                  </Badge>
                )}
              <div className="nav-item">
                <img
                  src={
                    member && member.profilePicture
                      ? member.profilePicture
                      : // ? member.profilePicture + "?" + Date.now()
                        userNoPhoto
                  }
                  height={24}
                  width={24}
                />
              </div>
            </div>
          </div>
          <div
            className={`mobile-flyout-backdrop fadein-fast ${
              flutterConnect ? "app" : ""
            }`}
            style={{
              display: `${isFlyoutVisible ? "block" : "none"}`,
            }}
            onClick={() => setIsFlyoutVisible(false)}
          >
            <div className="mobile-flyout" onClick={(e) => e.stopPropagation()}>
              <ul className="menu-list">
                <div>
                  <li className="flyout-profile">
                    <div className="user-container">
                      {member.profilePicture ? (
                        <div>
                          <img
                            src={member.profilePicture}
                            // src={member.profilePicture + "?" + Date.now()}
                            height={24}
                            width={24}
                          />{" "}
                          <h5>{member.firstName + " " + member.lastName}</h5>
                        </div>
                      ) : (
                        <Fragment>
                          <DefaultAvatar
                            firstName={member.firstName}
                            lastName={member.lastName}
                          />{" "}
                          <h5>{member.firstName + " " + member.lastName}</h5>
                        </Fragment>
                      )}
                    </div>
                    <CloseOutlined
                      className="mr-2"
                      onClick={() => setIsFlyoutVisible(false)}
                    />
                  </li>
                  <div className="divider" />
                  {/* <Link to={"/dashboard"}>
                    <li
                      className={`
                      ${
                        (location.pathname === "/dashboard" ||
                          location.pathname === "/") &&
                        "active"
                      }`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      Dashboard
                    </li>
                  </Link> */}
                  <Link to={"/calendar"}>
                    <li
                      className={
                        location.pathname === "/calendar" ? "active" : ""
                      }
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <CalendarOutlined style={{ marginRight: "10px" }} />
                      Calendar
                    </li>
                  </Link>
                  {/* <Link to={"/events"}>
                    <li
                      className={`
                      ${location.pathname === "/events" && "active"}`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      Events
                    </li>
                  </Link> */}
                  {/* <Link to={"/clubs"}>
                    <li
                      className={`
                      ${location.pathname === "/clubs" && "active"}`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      Clubs
                    </li>
                  </Link> */}
                  {/* <Link to={"/profile"}>
                    <li
                      className={`
                      ${location.pathname === "/profile" && "active"}`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      Profile
                    </li>
                  </Link> */}
                  <Link to={"/contacts/friend-requests"}>
                    <li
                      className={`
                      ${
                        location.pathname === "/contacts/friend-requests" &&
                        "active"
                      }`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <img src={PersonAdd} style={{ marginRight: "10px" }} />
                      Contacts
                    </li>
                  </Link>
                  <Link to={"/contacts/blocklist"}>
                    <li
                      className={`
                      ${
                        location.pathname === "/contacts/blocklist" &&
                        "active"
                      }`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <img src={BlockImg} style={{ marginRight: "10px" }} />
                      Blocklist
                    </li>
                  </Link>
                  {member.memberType === "Teacher" && (
                    <Link to={"/admin"}>
                      <li
                        className={`
                        ${
                          location.pathname === "/admin" &&
                          "active"
                        }`}
                        onClick={() => setIsFlyoutVisible(false)}
                      >
                        <ImportOutlined style={{ marginRight: "10px" }} />
                        Import Event
                      </li>
                    </Link>
                  )}
                  <Link to={"/settings"}>
                    <li
                      className={`
                      ${location.pathname === "/settings" && "active"}`}
                      onClick={() => setIsFlyoutVisible(false)}
                    >
                      <SettingOutlined style={{ marginRight: "10px" }} />
                      Settings
                    </li>
                  </Link>
                </div>
                <div>
                  <li
                    onClick={() => {
                      setIsFlyoutVisible(false);
                      setModal2Visible(true);
                    }}
                  >
                    <LogoutOutlined style={{ marginRight: "10px" }} />
                    Sign Out
                  </li>
                  <div className="divider" />
                  <Link to="privacy-policy">
                    <li className="privacy-policy">Privacy Policy</li>
                  </Link>
                </div>
              </ul>
            </div>
          </div>

          <Content className="site-layout">
            <MemberRoute />
          </Content>
          <Footer>
            <div className="footer-wrapper">
              <div className="d-flex justify-content-space-between">
                <h3 className="footer-text">
                  © Breeze 2022, All Rights Reserved.
                </h3>
                <div className="footer-links">
                  <Link to="privacy-policy">Privacy Policy</Link>
                  {/* <Link to="cookies">Cookies</Link>
                  <Link to="terms-of-use">Terms of Use</Link> */}
                </div>
              </div>
            </div>
          </Footer>
        </Layout>
      )}
      <Routes>
        <Route path="/interests" element={<Onboarding />} />
      </Routes>
    </div>
  );
};
const mapStateToProps = (state: { auth: any; flutterConnect: any }) => ({
  auth: state.auth,
  flutterConnect: state.flutterConnect,
});
export default connect(mapStateToProps, {
  logout,
  loadUser,
  clearAuth,
  updateTokens,
  searchUpcomingEvents,
  searchSchoolWideEvents,
  authQrCode,
})(MemberApp);
