import { Fragment } from "react";

const AlertContainer = ({ data }: any) => {
  let content = "";
  var indents = [];
  for (let property in data) {
    indents.push(
      <div key={property} className="ant-form-item-explain">
        <div role="alert" className="ant-form-item-explain-error">
          {data[property]}
        </div>
      </div>
    );
  }
  return <Fragment>{indents}</Fragment>;
};

export default AlertContainer;
