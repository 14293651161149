import moment from "moment";
import { notification, Button, Dropdown } from "antd";
import { useNavigate } from "react-router-dom";
import { EllipsisOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";
import { Fragment, useEffect } from "react";
import { connect } from "react-redux";
import { OK, BAD_REQUEST } from "../../helpers/statuscodes";
import { executeAction, getNotificationUrl } from "../../actions/notifications";

const menu = (
  <div></div>
  // <Menu
  //   className="overlay-item-controls"
  //   items={[
  //     {
  //       label: "Mark all as read",
  //       key: "mark-all-as-read",
  //       icon: <EyeOutlined />,
  //     },
  //     {
  //       label: "Notification Settings",
  //       key: "notif-settings",
  //       icon: <SettingOutlined />,
  //     },
  //     {
  //       label: "Open Notifications",
  //       key: "open-notifs",
  //       icon: <BellOutlined />,
  //     },
  //   ]}
  // />
);

const AlertPop = (props: {
  executeAction: Function;
  alerts: any;
  auth: any;
}) => {
  const {
    executeAction,
    alerts,
    auth: { member },
  } = props;

  let navigate = useNavigate();

  const openNotification = (alert: any) => {
    const key = `${Date.now()}`;

    const navigateToPage = () => {
      let url = getNotificationUrl(
        member.memberType,
        alert.data.notificationType,
        alert.data.recordId
      );

      navigate(url);
    };

    if (alert.data !== undefined && alert.data.actionRequired === "True") {
      const buttons = JSON.parse(alert.data.actions);

      var remarks = "";

      const sendRequest = async (action: any, checkReason: boolean) => {
        //setValidationMessages(new Array());
        //setIsLoading(true);

        if (action.status === "2" && checkReason) {
          var rootElement: any = document.getElementById(`reason_${key}`);
          rootElement.style.display = "";
        } else {
          const payload = JSON.stringify({
            activityLogId: alert.data.activityLogId,
            status: Number(action.status),
            remarks: remarks,
          });

          var res = await executeAction(payload);
          if (res.status == OK) {
            notification.close(key);
          } else if (res.status === BAD_REQUEST) {
          }
        }
      };

      notification.open({
        message: "",
        description: (
          <div className="notif-pop">
            {/* <div className="overlay-button">
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <div className="overlay-item">
                  <EllipsisOutlined />
                </div>
              </Dropdown>
            </div> */}
            <div
              className="notif-main"
              onClick={() => {
                navigateToPage();
              }}
            >
              {alert.data.profilePicture && alert.data.profilePicture !== "" ? (
                <div className="user-avatar">
                  <img
                    height="auto"
                    width="42px"
                    src={alert.data.profilePicture}
                  />
                </div>
              ) : (
                <div>
                  <div className="no-avatar">
                    <span>{alert.data.fullName.charAt(0)}</span>
                  </div>
                </div>
              )}

              <div className="notif-content">
                <p dangerouslySetInnerHTML={{ __html: alert.message }}></p>
                <div className="notif-timestamp">
                  <small>{moment(alert.data.createdOn).fromNow()}</small>
                </div>
                {alert.data.actions && (
                  <>
                    <div className="notif-controls">
                      {buttons.map((button: any) => (
                        <Button
                          id={`${button.label}_${key}`}
                          className={
                            button.status === "1"
                              ? "text-semibold ant-btn-primary btn-radius mr-3"
                              : "btn-radius"
                          }
                          //  disabled={disableButtons}
                          onClick={() => sendRequest(button, true)}
                        >
                          {button.label}
                        </Button>
                      ))}
                    </div>
                    {buttons.map((button: any) => (
                      <>
                        {button.additionalData && (
                          <div
                            className="notif-form"
                            id={`reason_${key}`}
                            style={{ display: "none" }}
                          >
                            <p>Reasons for declining the request</p>

                            <TextArea
                              rows={3}
                              placeholder="Input reason"
                              onChange={(e) => (remarks = e.target.value)}
                            />

                            <div className="notif-controls">
                              <Button
                                htmlType="submit"
                                className="text-semibold ant-btn-primary btn-radius mr-3"
                                onClick={() => sendRequest(button, false)}
                              >
                                Submit
                              </Button>
                              <Button
                                // disabled={notifData.loading === true}
                                className="btn-radius"
                                onClick={() => notification.close(key)}
                              >
                                Cancel
                              </Button>
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>
        ),
        key,
        duration: 0,
      });
    } else if (
      alert.data !== undefined &&
      alert.data.actionRequired === "False"
    ) {
      notification.open({
        message: "",
        description: (
          <div className="notif-pop">
            {/* <div className="overlay-button">
              <Dropdown
                overlay={menu}
                placement="bottomRight"
                trigger={["click"]}
              >
                <div className="overlay-item">
                  <EllipsisOutlined />
                </div>
              </Dropdown>
            </div> */}
            <div
              className="notif-main"
              onClick={() => {
                navigateToPage();
              }}
            >
              {alert.data.profilePicture && alert.data.profilePicture !== "" ? (
                <div className="user-avatar">
                  <img
                    height="auto"
                    width="42px"
                    src={alert.data.profilePicture}
                  />
                </div>
              ) : (
                <div>
                  <div className="no-avatar">
                    <span>{alert.data.fullName.charAt(0)}</span>
                  </div>
                </div>
              )}

              <div className="notif-content">
                <p dangerouslySetInnerHTML={{ __html: alert.message }}></p>
                <div className="notif-timestamp">
                  <small>{moment(alert.data.createdOn).fromNow()}</small>
                </div>
                <div className="notif-invite-status">
                    {alert.data.notificationType==="DeclineEventInvite" ? (
                      <small className="red">Invite Declined</small>
                      ):alert.data.notificationType==="AcceptEventInvite"&&(
                        <small>Invite Accepted</small>
                      )}
                  </div>
              </div>
            </div>
          </div>
        ),
        key,
        duration: 0,
      });
    } else {
      if (!alert.title && !alert.message)
        return;
      notification.open({
        message: alert.title ?? "",
        description: (
          <div className="notif-pop">
            <div className="overlay-button"></div>
            <div className="notif-main">
              {/* <div>
                <div
                  className={
                    alert.title == "Error" ? "error-avatar" : "success-avatar"
                  }
                >
                  <span>X</span>
                </div>
              </div> */}

              <div className={"notif-content"}>
                <p dangerouslySetInnerHTML={{ __html: alert.message }}></p>
              </div>
            </div>
          </div>
        ),
      });
    }
  };

  useEffect(() => {
    alerts !== null && alerts.map((alert: any) => openNotification(alert));
  }, [alerts]);

  return <Fragment />;
};

const mapStateToProps = (state: any) => ({
  alerts: state.alert,
  auth: state.auth,
});

// const mapStateToProps = (state: { auth: any }) => ({
//   alerts: state.alert,
//   auth: state.auth,
// });

export default connect(mapStateToProps, {
  executeAction,
})(AlertPop);
