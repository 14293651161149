import { useEffect, useState } from "react";
import { Button, Modal } from "antd";
import flame2 from "../../../assets/images/streak/flame-2.svg";
import flame3 from "../../../assets/images/streak/flame-3.svg";
import flame4 from "../../../assets/images/streak/flame-4.svg";
import flame5 from "../../../assets/images/streak/flame-5.svg";
import flame7 from "../../../assets/images/streak/flame-7.svg";

export const ModalStreak = (props: {
  isOpen: boolean;
  setIsOpen: Function;
  days: number;
}) => {
  const { isOpen, setIsOpen, days } = props;
  const [icon, setIcon] = useState(flame2);
  const [randomInt, setInt] = useState(0);

  const verbiages = [
    `${days} days streak! Keep the momentum going!`,
    `You're unstoppable! ${days} days and counting!`,
    `${days} days in a row! You're crushing it!`,
    `${days} days streak! You're on fire!`,
    `${days} days strong! Keep up the great work!`,
    `${days} days streak! You're a streak master!`,
    `${days} days, going strong! Amazing!`,
    `${days} days streak! You're rocking it!`,
    `${days} days in a row! Keep that fire burning!`,
    `${days} days and counting! Fantastic job!`,
    `${days} days strong! You're a powerhouse!`,
    `${days} days in a row! You're the life of every event!`,
    `${days} days streak! You're building a winning habit!`,
    `${days} days in a row! Consistency is key!`,
    `${days} days strong! Every day counts!`,
    `${days} days streak! Success is in your routine!`,
    `${days} days streak! You're doing awesome!`,
    `${days} days in a row! Keep it up!`,
    `${days} days in a row! Great job!`,
    `${days} days streak! You're nailing it!`,
    `${days} days strong! Keep showing up!`,
    `${days} days in a row! Keep it steady!`,
    `${days} days in a row! You're on a winning streak!`,
    `${days} days streak! You're making it happen!`,
    `${days} days in a row! Keep the streak alive!`,
  ];

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  useEffect(()=>{
    if(days>6){
      setIcon(flame7)
    }else if(days>=5){
      setIcon(flame5)
    }else if(days===4){
      setIcon(flame4)
    }else if(days===3){
      setIcon(flame3)
    }else{
      setIcon(flame2)
    }
    setInt(Math.floor(Math.random()*25));
  },[days])

  return (
    <Modal
      centered
      className="modal-streak"
      visible={isOpen}
      onCancel={onCancelHandler}
      closable={false}
      footer={null}
    >
      <img src={icon}/>
      <p className={days>6?"blue":""}>{verbiages[randomInt]}</p>
      <h4 className={days>6?"blue":"orange"}>
        {days} Days Streak
      </h4>
    </Modal>
  );
};

export default ModalStreak;
