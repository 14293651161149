import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { UPDATE_PROFILE_SUCCESS } from "../actions/types";
import { setAlert } from "./alert";
import { async } from "@firebase/util";
import { axiosApiInstance } from "../utils/axiosApiInstance";

export const updateProfile = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/profile/profile`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data,
    });

    dispatch(
      setAlert("Success", "User Profile Successfully updated", "success")
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateProfileDisplayAll = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    let req = formData
    delete req.gender;
    delete req.birthDate;
    delete req.memberType;
    const res = await axiosApiInstance.put(
      `/api/profile/profile`,
      req,
      config
    );
    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload:formData,
    });

    dispatch(
      setAlert("Success", "User Profile Successfully updated", "success")
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response?.status === METHOD_NOT_ALLOWED ||
      response?.status === NOT_FOUND
    ) {
      dispatch(setAlert(response?.status, response?.statusText, "error"));
    } else if (response?.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response?.data && response?.data.error ? response?.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateProfileInterest = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/profile/interest`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data,
    });

    if (!formData.isSkipValidation)
    {
      dispatch(
        setAlert("Success", "User Interest Successfully updated", "success")
      );
    }
    
    return res;
  } catch (err) {
    const response = err.response;
    // if (
    //   response.status === METHOD_NOT_ALLOWED ||
    //   response.status === NOT_FOUND
    // ) {
    //   dispatch(setAlert(response.status, response.statusText, "error"));
    // } else if (response.status === BAD_REQUEST) {
    //   dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    // }
    return response;
  }
};

export const changeProfilePassword = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Account/change-password`,
      formData,
      config
    );

    dispatch(
      setAlert(
        "Success",
        "User Profile Password Successfully updated",
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const uploadProfilePicture = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/profile/profile-picture`,
      formData,
      config
    );

    dispatch({
      type: UPDATE_PROFILE_SUCCESS,
      payload: res.data,
    });

    dispatch(setAlert("Success", "User Profile Picture Updated", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", "Check the image file.", "warning"));
    }
    return response;
  }
};

export const getMyClubs = (params) => async (dispatch) => {
  try {
    const searchString = params.searchString ? params.searchString : "";
    const res = await axiosApiInstance.get(
      `/api/profile/clubs?page=${params.page}&pageSize=${params.pageSize}` +
        `&searchString=${searchString}&membershipType=-1&status=${params.status}`
    );
    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};
let cancelToken;
export const getSuggestedClubList = (params) => async (dispatch) => {
  //Check if there are any previous pending requests
  if (typeof cancelToken != typeof undefined) {
    cancelToken.cancel("Operation canceled due to new request.");
  }
  cancelToken = axios.CancelToken.source();
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Profile/clubs/suggested` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(
          params.searchString ?? ""
        )}&status=${params.status}` +
        `&privacyTypes=${params.privacyTypes}` +
        `&interests=${params.interests}`,
      { cancelToken: cancelToken.token }
    );

    return res;
  } catch (err) {
    const response = err.response;
    //dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};
let cancelOTherClubToken;
export const getOtherClubList = (params) => async (dispatch) => {
  //Check if there are any previous pending requests
  if (typeof cancelOTherClubToken != typeof undefined) {
    cancelOTherClubToken.cancel("Operation canceled due to new request.");
  }
  cancelOTherClubToken = axios.CancelToken.source();
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Profile/clubs/others` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(
          params.searchString ?? ""
        )}&status=${params.status}` +
        `&privacyTypes=${params.privacyTypes}` +
        `&interests=${params.interests}`,
      { cancelToken: cancelOTherClubToken.token }
    );

    return res;
  } catch (err) {
    const response = err.response;
    //dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getMyClubsGrouped = (params, controller) => async (dispatch) => {
  try {
    const searchString = params.searchString ? params.searchString : "";
    const res = await axiosApiInstance.get(
      `/api/Profile/clubs/grouped?` +
        `&searchString=${searchString}` +
        `&privacyTypes=${params.privacyTypes}` +
        `&Interests=${params.interests}`,
      { signal: controller ? controller.signal : null } //pass this param to cancel multiple duplicate requests
    );
    return res;
  } catch (err) {
    const response = err.response;
    if(response){
      //dispatch(setAlert(response?.status, response?.statusText, "error"));
    }
    return response;
  }
};

export const getClubListImNotPartOf = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Profile/clubs/new` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(params.searchString ?? "")}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    //dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getMyProposedClubs = (params) => async (dispatch) => {
  try {
    const searchString = params.searchString ? params.searchString : "";
    const res = await axiosApiInstance.get(
      `/api/profile/clubs/proposed?page=${params.page}&pageSize=${params.pageSize}` +
        `&searchString=${searchString}`
    );
    return res;
  } catch (err) {
    const response = err.response;
    //dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const handleClubInvitation = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `api/Club/${params.clubId}/member/${params.type}`,
      params,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const getMyEvents = (params) => async (dispatch) => {
  try {
    const utcNow = new Date().toISOString();
    const searchString = params.searchString ? params.searchString : "";
    const other = params.showPastEvent ? `to=${utcNow}` : `from=${utcNow}`;
    let Interests = params.InterestIds
      ? `&interestIds=${params.InterestIds}`
      : "";
    let memberGroupIds = params.memberGroupIds
      ? `&MemberGroupIds=${params.memberGroupIds}`
      : "";
    return await axiosApiInstance.get(
      `/api/profile/events?page=${params.page}&pageSize=${params.pageSize}` +
        `&searchString=${searchString}&isDesc=${
          params.isDesc ? params.isDesc : false
        }&${other}${Interests}${memberGroupIds}`
    );
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getMyNotifications = (params) => async (dispatch) => {
  try {
    return await axiosApiInstance.get(
      `/api/Profile/notifications?page=${params.page}&pageSize=${params.pageSize}` +
        `&searchString=${params.searchString}`
    );
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const changePassword = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(
      `/api/Account/change-password`,
      formData,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    return response;
  }
};

export const getProfileSummary = () => async (dispatch) => {
  try {
    return await axiosApiInstance.get(`api/Profile/summary`);
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getProfilePoints = (params) => async (dispatch) => {
  var queryParameters = [
    { name: "memberId", value: params.memberId },
    { name: "type", value: params.type },
    { name: "from", value: params.from },
    { name: "to", value: params.to },
  ];

  try {
    const res = await axiosApiInstance.get(`/api/Profile/summary/points`, {
      params: parseParams(queryParameters),
    });
    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

const parseParams = (params) => {
  let searchParams = new URLSearchParams();
  params
    .filter((e) => e.value || e.isRequired)
    .forEach((e) => {
      const value = e.value ?? e.defaultValue ?? "";

      if (Array.isArray(value))
        value.forEach((f) => searchParams.append(e.name, f));
      else searchParams.append(e.name, value);
    });
  return searchParams;
};
