import {
    Button,
    Form,
    Space,
    Select,
    Input,
    DatePicker,
    Col,
    Row,
    message,
    Modal,
    Tag,
    Tooltip,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { PlusOutlined, SettingOutlined, LeftOutlined, RightOutlined } from "@ant-design/icons";
import ModalCustomForm from "../Modals/ModalCustomForm";
import type { RangePickerProps } from "antd/es/date-picker";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import { eventTypes } from "../../../helpers/eventTypes";
import PointDistributionInput from "../../super-admin/interest/PointDistribution";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import LocationField from "../../super-admin/select-fields/LocationField";
import StarG from '../../../assets/images/icons/star-g.svg'
import StarB from '../../../assets/images/icons/star-b.svg'
import StarGrey from '../../../assets/images/icons/star-grey.svg'
import DiversityG from '../../../assets/images/icons/diversity-g.svg'
import DiversityB from '../../../assets/images/icons/diversity-b.svg'
import DiversityGrey from '../../../assets/images/icons/diversity-grey.svg'
const { Search } = Input;

export const EventTagsModal = (props: {
    tags: any;
    isModalOpen: boolean;
    setIsModalOpen: Function;
    setSelectedTags: Function;
    selectedTags: any;
}) => {
    const { tags,
        isModalOpen,
        setIsModalOpen,
        setSelectedTags,
        selectedTags
    } = props;
    const [tagsForUpdate, setTagsForUpdate] = useState<any[]>(selectedTags);
    const [shownTags, setShownTags] = useState<any[]>(tags);
    const [searchString, setSearchString]: any = useState("");
    const [page, setPage] = useState(1);
    const totalPages = Math.ceil(shownTags.length / 10);

    const onFilterSearchHandler = (searchInput: String) => {
        setSearchString(searchInput);
    };

    const onUpdate = () => {
        setSelectedTags(tagsForUpdate);
        setIsModalOpen(false);
    }

    const InterestTag = (tag: any) => {
        const [isSelected, setIsSelected] = useState(tagsForUpdate.some(t => t.eventTagId === tag.eventTagId));
        let isDisabled = (tagsForUpdate.length >= 5 && !isSelected);

        const handleSelect = (tag: any, checked: boolean) => {
            const nextSelectedTags = !checked
                ? [...tagsForUpdate, tag]
                : tagsForUpdate.filter((t: any) => t.eventTagId !== tag.eventTagId);
            setTagsForUpdate(nextSelectedTags);
        };

        return (
            <div className={(isDisabled) ? "interest-tag-disabled" : isSelected ? "interest-tag-b" : "interest-tag"} onClick={() => { handleSelect(tag, isSelected) }}>
                <Tooltip title={tag.name}>
                    <p className={isSelected ? "tag-b" : "tag"}>
                        {tag.name}
                    </p>
                </Tooltip>
                <p className="tag-info">
                    <img src={isDisabled ? DiversityGrey : isSelected ? DiversityB : DiversityG} />
                    <span > 17 Friends</span>
                </p>
                <p className="tag-info">
                    <img src={isDisabled ? StarGrey : isSelected ? StarB : StarG} />
                    <span > Most Often Joined</span>
                </p>
            </div>
        )
    }

    useEffect(() => {
        if (searchString !== "") {
            setShownTags(tags.filter((t: any) => ((t.name).toLowerCase()).includes(searchString.toLowerCase())))
        } else {
            setShownTags(tags)
        }
    }, [searchString]);

    useEffect(() => {
        if (tags.length > 0) {
            setShownTags(tags)
        }
    }, [tags]);

    useEffect(() => {
        setTagsForUpdate(selectedTags);
    }, [selectedTags]);

    return (
        <Modal
            title="Event Tags"
            className="modal-interest-tags"
            centered
            visible={isModalOpen}
            onCancel={() => setIsModalOpen(false)}
            footer={
                <Button
                    className="mr-1 mobile-reactive-btn"
                    type="primary"
                    icon={<SettingOutlined />}
                    shape="round"
                    onClick={onUpdate}
                >
                    Update
                </Button>}
        >
            <div className="d-flex">
                <div className="d-flex-column w-100 mb-3">
                    <Search
                        className="search-interest"
                        allowClear
                        onSearch={onFilterSearchHandler}
                        placeholder="Search Event Tag"
                    />
                    <div className="tag-grid">
                        {selectedTags.map((t: any, index: number) =>
                            <InterestTag key={index} {...t} />
                        )}
                        {shownTags.filter((st) => !selectedTags.map((t: any) => t.name).includes(st.name)).slice((page === 1 ? 0 : (10 * (page - 1))), (10 * page)).map((t: any, index: number) =>
                            <InterestTag key={index} {...t} />
                        )}
                    </div>
                    <div className="interest-page-btns">
                        <Button onClick={() => setPage(page - 1)} disabled={page === 1}><LeftOutlined /></Button>
                        <p>{page}/{totalPages}</p>
                        <Button onClick={() => setPage(page + 1)} disabled={page === totalPages}><RightOutlined /></Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
};


const mapStateToProps = (state: any) => ({
    member: state.auth?.member,
});
export default connect(mapStateToProps, {
    getSoftSkillsNonPaged,
})(EventTagsModal);