import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Space, Divider, Progress } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from 'chart.js'
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels,
)

const Interest=(props:{
    data:any;
})=>{
    const{data} = props;

    let barColors=["#165BAA","#A155B9","#F765A3"];
    const [userData, setUserData] = useState({
        labels:data.map((d:any)=>`${moment(d.date).format('ddd DD')}`),
   
        datasets: [
            {
                label: data.map((d:any) => d.eventGroup[0]?.interestName),
                data: data.map((d:any) => d.eventGroup[0]?.eventCount),
                borderColor: "#165BAA",
                backgroundColor:"#165BAA",
                borderRadius:5,
                pointRadius:0,
                borderWidth: 2,
                fill: true,
            },
            {
                label: data.map((d:any) => d.eventGroup[1]?.interestName),
                data: data.map((d:any) => d.eventGroup[1]?.eventCount),
                borderColor: "#A155B9",
                backgroundColor:"#A155B9",
                borderRadius:5,
                pointRadius:0,
                borderWidth: 2,
                fill: true,
            },
            {
                label: data.map((d:any) => d.eventGroup[2]?.interestName),
                data: data.map((d:any) => d.eventGroup[2]?.eventCount),
                borderColor: "#F765A3",
                backgroundColor:"#F765A3",
                borderRadius:5,
                pointRadius:0,
                borderWidth: 2,
                fill: true,
            },
          ],
    })

    return(
        <div style={{height:'100%', width:'100%'}}>
            <Bar 
                data={
                    userData
                } 
                redraw 
                options={{
                    maintainAspectRatio: false,
                    scales: {
                        myScale: {
                            position: 'right',
                        },
                    },
                    plugins:{
                        legend: {
                            display: false,
                        },
                        datalabels: {
                            color: '#FFF',
                            rotation:270,
                            anchor:'start',
                            align:'end',
                            textAlign:'left',
                            offset:5,
                            formatter: function(value,context) {
                                let label = context.dataset?.label;
                                return label && label[context.dataIndex];
                            }
                        },
                        tooltip: {
                            enabled:false,
                            external: (context) => {
                                // Tooltip Element
                                let tooltipEl = document.getElementById('chartjs-tooltip');

                                // Create element on first render
                                if (!tooltipEl) {
                                    tooltipEl = document.createElement('div');
                                    tooltipEl.id = 'chartjs-tooltip';
                                    tooltipEl.innerHTML = '<table></table>';
                                    document.body.appendChild(tooltipEl);
                                }

                                // Hide if no tooltip
                                const tooltipModel = context.tooltip;
                                if (tooltipModel.opacity === 0) {
                                    tooltipEl.style.opacity = '0';
                                    return;
                                }

                                // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
                                tooltipEl.classList.remove('below', 'no-transform');


                                // Set HTML & Data
                                if (tooltipModel.body) {
                                    const index = Number(tooltipModel.dataPoints[0].dataIndex);
                                    const interestData = data[index];
                                    const colors = ["#165BAA","#A155B9","#F765A3","#E74731","#FFA800"]
                                    const interestBreakdown = interestData?.eventGroup?.sort((a: any, b: any) => (b?.eventCount) - (a?.eventCount));
                                    const totalCount = interestData?.totalEventCount;
                                    let top3Count = (interestData?.eventGroup?.length>0 ? interestData?.eventGroup?.slice(0,3).map(
                                        (currentValue:any,i:any) => {
                                            return currentValue.eventCount;
                                        }
                                    ):0)
                                    top3Count = top3Count.reduce((a:any, c:any) => a + c, 0)
                                    const innerHtml = `
                                    <div class="analytics-tooltip">
                                        <div class="title">
                                            ${tooltipModel.title}
                                        </div>
                                        <div class="total" style="margin-bottom:10px"><span>Events</span><span>${totalCount}</span></div>
                                        ${interestBreakdown.slice(0,3).map((ev:any,i:any)=>(
                                            `<div class="summary-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:${colors[i]}"></span>
                                                        <span>${ev.interestName}</span>
                                                    </div>
                                                </div>
                                                <p class="point-total" style="color:${colors[i]}">${ev.eventCount}</p>
                                            </div>`
                                        )).join("")}
                                        ${interestBreakdown.length> 3 ? (
                                            `<div class="summary-info">
                                                <div class="left">
                                                    <div class="label">
                                                        <span class="point" style="background-color:#5c5c5c"></span>
                                                        <span>Others</span>
                                                    </div>
                                                </div>
                                                <p class="point-total" style="color:#5c5c5c">${totalCount-top3Count}</p>
                                            </div>`
                                        ):""}
                                    </div>
                                `;

                                    tooltipEl.querySelector('table')!.innerHTML = innerHtml;
                                }

                                const position = context.chart.canvas.getBoundingClientRect();

                                // Display, position, and set styles for font
                                tooltipEl.style.opacity = '1';
                                tooltipEl.style.position = 'absolute';
                                tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                tooltipEl.style.pointerEvents = 'none';
                            }
                        },
                    },
                }}
            />
        </div>
    )
}

export default Interest;
