import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";
export const getClubList = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Club` +
        `?page=${page}&pageSize=${params.pageSize}&status=${params.status}` +
        `&searchString=${encodeURIComponent(params.searchString ?? "")}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubListNonPaged = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/Club/list`);
    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubListForStudentAndMod = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Profile/clubs` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(
          params.searchString ?? ""
        )}&status=${params.status}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubById = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(`/api/Club/${params.clubId}`);

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add club
export const addClub = (formData) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(`/api/Club/`, formData, config);
    if (formData && formData.noAlert) {
    } else {
      dispatch(setAlert("Success", "Club Added", "success"));
    }
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      // dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const updateClub = (formData, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.put(`/api/Club/${id}`, formData, config);

    dispatch(setAlert("Success", "Club Successfully updated", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const joinClub = (clubId) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Club/${clubId}/member/join`,
      {
        clubId,
      },
      config
    );
    return res;
  } catch (err) {
    const response = err.response;
    return response;
  }
};

export const deleteClub = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(
      `/api/Club/${params.memberGroupId}?remarks=${params.remarks}`
    );

    dispatch(setAlert("Success", "Club Deactivated", "success"));
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const getClubMemberList = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Club/${params.memberGroupId}/member/` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(params.searchString ?? "")}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getNonClubMember = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Club/${params.memberGroupId}/nonmember/` +
        `?membershipType=${params.membershipType}` +
        `&searchString=${encodeURIComponent(params.searchString ?? "")}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubSummary = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Club/summary/`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const getClubModeratorList = (params) => async (dispatch) => {
  let page = params?.page ? params.page : params.current;
  try {
    const res = await axiosApiInstance.get(
      `/api/Club/${params.memberGroupId}/moderator/` +
        `?page=${page}&pageSize=${params.pageSize}` +
        `&searchString=${encodeURIComponent(params.searchString ?? "")}`
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

//add club member
export const addClubMember = (formData, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Club/${id}/member/invite`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Club Member Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

//add club moderator
export const addClubModerator = (formData, id) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const res = await axiosApiInstance.post(
      `/api/Club/${id}/moderator/invite`,
      formData,
      config
    );

    dispatch(setAlert("Success", "Club Moderator Added", "success"));
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    } else if (response.status === BAD_REQUEST) {
      dispatch(setAlert("Validation", response.data && response.data.error ? response.data.error : "Check the fields", "warning"));
    }
    return response;
  }
};

export const handleClubProposal = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `/api/Club/${params.clubId}/${params.type}`,
      params,
      config
    );

    dispatch(
      setAlert(
        "Success",
        `Club Proposal Request has been ${
          params.type === "approve" ? "Approved" : "Declined"
        }`,
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const leaveClub = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.delete(
      `/api/Club/${params.clubId}/member/leave?clubId=${params.clubId}&memberId=${params.memberId}`
    );
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

//club approval
export const acceptClubJoinRequest = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `api/Club/${params.clubId}/member/${params.memberId}/approve`,
      params,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const declineClubJoinRequest = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `api/Club/${params.clubId}/member/${params.memberId}/reject`,
      params,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const dropClubMember = (params) => async (dispatch) => {
  try {
    const res = await axiosApiInstance.put(
      `/api/Club/${params.clubId}/member/${params.memberId}/drop`,
      params
    );
    return res;
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};

export const promoteMember = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.post(
      `/api/Club/${params.clubId}/member/${params.memberId}/promote`,
      params,
      config
    );
    return res;
  } catch (err) {
    const response = err.response;
    return response;
  }
};

export const acceptDropModerator = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `api/Club/${params.clubId}/moderator/${params.memberId}/drop/approve`,
      params,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const rejectDropModerator = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(
      `api/Club/${params.clubId}/moderator/${params.memberId}/drop/reject`,
      params,
      config
    );

    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const getClubFromSlug = (slug) => async (dispatch) => {
  try {
    return await axiosApiInstance.get(`/api/Club/name/${slug}`);
  } catch (err) {
    dispatch(
      setAlert("Error", "Something went wrong, try again later", "error")
    );
    return err;
  }
};