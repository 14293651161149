import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, PageHeader, Row, Space, Divider, Progress } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
  } from 'chart.js'
import { Line } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels,
)

const Day=(props:{
    data:any;
})=>{
    const{data} = props;

    const [userData, setUserData] = useState({
        labels:data.map((d:any)=>`${moment(d.date).format('ddd DD')}`),
        datasets: [
            {
              label: "Events",
              data: data.map((data:any) => data.totalEventCount),
              borderColor: "#02B8F6",
              pointRadius:0,
              borderWidth: 2,
              fill: true,
              backgroundColor:"#EBFAFF80",
            },
          ],
    })

    return(
        <div style={{height:'100%', width:'100%'}}>
            <Line 
                    data={userData} 
                    redraw 
                    options={{
                        maintainAspectRatio: false,
                        scales: {
                            myScale: {
                                position: 'right',
                                beginAtZero: true,
                            },
                        },
                        hover: {
                            mode: 'nearest',
                            intersect: false,
                        },
                        responsive: true,
                        interaction: {
                        mode: 'index',
                        intersect: false,
                        },
                        plugins:{
                            legend:{
                                align:'center',
                                labels:{
                                    boxWidth:5,
                                    boxHeight:5,
                                    usePointStyle:true,
                                }
                            },
                            datalabels: {
                                display:false,
                            },
                            tooltip:{
                                // external:customTooltip,
                                enabled: false,
                                external: (context) => {
                                    // Tooltip Element
                                    let tooltipEl = document.getElementById('chartjs-tooltip');

                                    // Create element on first render
                                    if (!tooltipEl) {
                                        tooltipEl = document.createElement('div');
                                        tooltipEl.id = 'chartjs-tooltip';
                                        tooltipEl.innerHTML = '<table></table>';
                                        document.body.appendChild(tooltipEl);
                                    }

                                    // Hide if no tooltip
                                    const tooltipModel = context.tooltip;
                                    if (tooltipModel.opacity === 0) {
                                        tooltipEl.style.opacity = '0';
                                        return;
                                    }

                                    // Set caret Position (above, below,no-transform ).As I need above I don't delete that class
                                    tooltipEl.classList.remove('below', 'no-transform');

                                    // Set HTML & Data
                                    if (tooltipModel.body) {
                                        const index = Number(tooltipModel.dataPoints[0].dataIndex);
                                        const interestData = data[index];
                                        const colors = ["#A155B9","#F765A3","#165BAA","#E74731","#FFA800"]
                                        const interestBreakdown = interestData?.eventGroup?.sort((a: any, b: any) => (b?.eventCount) - (a?.eventCount));
                                        const totalCount = interestData?.totalEventCount;
                                        let top5Count = (interestData?.eventGroup?.length>0 ? interestData?.eventGroup?.slice(0,5).map(
                                            (currentValue:any,i:any) => {
                                                return currentValue.eventCount;
                                            }
                                        ):0)
                                        top5Count = top5Count.reduce((a:any, c:any) => a + c, 0)
                                        const innerHtml = `
                                        <div class="analytics-tooltip">
                                            <div class="title">
                                                ${tooltipModel.title}
                                            </div>
                                            <div class="total" style="margin-bottom:10px"><span>Events</span><span>${totalCount}</span></div>
                                            ${interestBreakdown.slice(0,5).map((ev:any,i:any)=>(
                                                `<div class="summary-info">
                                                    <div class="left">
                                                        <div class="label">
                                                            <span class="point" style="background-color:${colors[i]}"></span>
                                                            <span>${ev.interestName}</span>
                                                        </div>
                                                    </div>
                                                    <p class="point-total" style="color:${colors[i]}">${ev.eventCount}</p>
                                                </div>`
                                            )).join("")}
                                            ${interestBreakdown.length> 5 ? (
                                                `<div class="summary-info">
                                                    <div class="left">
                                                        <div class="label">
                                                            <span class="point" style="background-color:#5c5c5c"></span>
                                                            <span>Others</span>
                                                        </div>
                                                    </div>
                                                    <p class="point-total" style="color:#5c5c5c">${totalCount-top5Count}</p>
                                                </div>`
                                            ):""}
                                        </div>
                                    `;

                                        tooltipEl.querySelector('table')!.innerHTML = innerHtml;
                                    }

                                    const position = context.chart.canvas.getBoundingClientRect();

                                    // Display, position, and set styles for font
                                    tooltipEl.style.opacity = '1';
                                    tooltipEl.style.position = 'absolute';
                                    tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
                                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                                    tooltipEl.style.pointerEvents = 'none';
                                }
                            }
                        },
                    }}
                    
                />
        </div>
    )
}

export default Day;
