import { REFRESH_CALENDAR } from "./types";

export const setRefreshCalendar = (payload) => (dispatch) => {
  {
    dispatch({
      type: REFRESH_CALENDAR,
      payload
    });
  }
};
