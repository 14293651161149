import { FLUTTER_CONNECTION_SUCCESS, FLUTTER_CONNECTION_ERROR } from "./types";

export const setFlutterConnect = (payload) => (dispatch) => {
  {
    dispatch({
      type: FLUTTER_CONNECTION_SUCCESS,
      payload
    });
  }
};
