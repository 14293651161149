import { Avatar } from "antd";

type IProps = { firstName: string; lastName: string; size?: number };

const DefaultAvatar = (props: IProps) => {
  const bgColor = (): string => {
    const colors = [
      "#096DD9",
      "#CF1322",
      "#D4380D",
      "#D46B08",
      "#D48806",
      "#D4B106",
      "#7CB305",
      "#389E0D",
      "#08979C",
      "#1D39C4",
      "#531DAB",
      "#C41D7F"
    ];
    const total = props.firstName?.charCodeAt(0) + props.lastName?.charCodeAt(0);
    return colors[total % 12];
  };

  return (
    <Avatar size={props.size} style={{ backgroundColor: bgColor() }}>
      {props?.firstName?.charAt(0) + props?.lastName?.charAt(0)}
    </Avatar>
  );
};
export default DefaultAvatar;
