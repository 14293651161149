import { Layout, Menu, Drawer } from "antd";
import jwt_decode from "jwt-decode";
import { Fragment, useState } from "react";
import {
  UserOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  AimOutlined,
  SoundOutlined,
  SecurityScanOutlined,
  BarChartOutlined,
  BarsOutlined,
  DashboardOutlined,
  CalendarOutlined,
  HighlightOutlined,
  GroupOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import SubMenu from "antd/lib/menu/SubMenu";
import logoSm from "./../../assets/images/logos/breeze-logo-blue.svg";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal-blue.svg";
import { text } from "node:stream/consumers";
import { memberStatus } from "../../helpers/memberStatus";

const { Sider } = Layout;
const SidebarMenu = (
  props: {
    token: any,
    onLogout: any,
    flutterConfig: any,
    member?: any,
  }
) => {
  const [collapsed, setCollapsed] = useState(false);
  const { flutterConfig, member } = props;

  const location = window.location.pathname;
  let decoded: any = jwt_decode(props.token);
  let userRole: String =
    decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  let menus: any;

  let menuOpenkeys: string[] | undefined = ["dashboardsub"];
  let defaultSelectedKeys: string[] | undefined = ["dashboard"];

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  const getFlutterSideMenu = () => (
    <div>
      <Drawer
        className="flutter-sidebar-nav"
        placement='left'
        onClose={() => {
          flutterConfig.onClose();
        }}
        closable={true}
        visible={flutterConfig.isOpen}
      >
        <Menu
          id="sidebarMenu"
          className="app-main-menu"
          mode="inline"
          defaultSelectedKeys={defaultSelectedKeys}
          defaultOpenKeys={menuOpenkeys}
          onChange={() => flutterConfig.onClose()}
        >
          {/* <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="dashboard">Dashboard</Link>
        </Menu.Item> */}
          {menus}
          <Menu.Item key="logout-admin" icon={<LogoutOutlined />}>
            <a onClick={props.onLogout}>Logout</a>
          </Menu.Item>
          <Menu.Item
            className="mt-auto"
            key="privacy-policy"

            icon={<SecurityScanOutlined />}
          >
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="privacy-policy">Privacy Policy</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </div>
  );

  const getWebSideMenu = () => (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      breakpoint="md"
      onBreakpoint={(broken) => {
        setCollapsed(broken);
      }}
    >
      <div
        className="logo"
        style={{ textAlign: collapsed ? "center" : "left" }}
      >
        <img height="30px" width="auto" src={logo} />
      </div>
      <Menu
        id="sidebarMenu"
        className="app-main-menu"
        mode="inline"
        defaultSelectedKeys={defaultSelectedKeys}
        defaultOpenKeys={menuOpenkeys}
      >
        {/* <Menu.Item key="dashboard" icon={<DashboardOutlined />}>
          <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="dashboard">Dashboard</Link>
        </Menu.Item> */}
        {menus}
        {/* <Menu.Item key="logout-admin" icon={<LogoutOutlined />}>
          <a onClick={props.onLogout}>Logout</a>
        </Menu.Item> */}
        {/* <Menu.Item
          className="mt-auto"
          key="privacy-policy"
          icon={<SecurityScanOutlined />}
        >
          <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="privacy-policy">Privacy Policy</Link>
        </Menu.Item> */}
      </Menu>
      <div className="sidebar-footer">
        <div className="trigger" onClick={toggle}>
          {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
        </div>
      </div>
    </Sider>
  );

  let logo = collapsed ? logoSm : breezeHorizontalLogo;
  //UI for active menu->submenus
  switch (location) {
    case "/admin":
      if (userRole === "SuperAdmin") {
        menuOpenkeys = ["accountssub"];
        defaultSelectedKeys = ["school-admin-list"];
      } else if(userRole === "Admin") {
        menuOpenkeys = ["accountssub"];
        // defaultSelectedKeys = ["student-list"];
        defaultSelectedKeys = ["poorvu"];
      } else{
        menuOpenkeys = ["accountssub"];
        defaultSelectedKeys = ["import-event"];
      }
      break;
    case "/admin/school-admins":
    case "/admin/school-admin/add-user":
    case "/admin/school-admin/add-user-bulk":
      menuOpenkeys = ["accountssub", "school-admin-sub"];
      defaultSelectedKeys = ["school-admin-list"];
      break;
    case "/admin/students":
    case "/admin/students/add":
    case "/admin/students/add-bulk":
      menuOpenkeys = ["accountssub", "student-sub"];
      defaultSelectedKeys = ["student-list"];
      break;
    case "/admin/teachers":
    case "/admin/teachers/add":
    case "/admin/teachers/add-bulk":
      menuOpenkeys = ["accountssub"];
      defaultSelectedKeys = ["teacher-list"];
      break;
    case "/admin/interests":
    case "/admin/interests/add":
      defaultSelectedKeys = ["interest-list"];
      break;
    case "/admin/softskills":
    case "/admin/softskills/add":
      defaultSelectedKeys = ["softskill-list"];
      break;
    case location.includes("clubs") && location:
      defaultSelectedKeys = ["club-list"];
      break;
    default:
      menuOpenkeys = ["dashboardsub"];
      defaultSelectedKeys = ["dashboard"];
      break;
  }
  switch (userRole) {
    case "SuperAdmin":
      menus = (
        <Fragment>
          <SubMenu key="accountssub" icon={<UserOutlined />} title="Accounts">
            <Menu.Item key="school-admin-list">
              <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="school-admins">Admin List</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="school-list" icon={<AimOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="schools">Schools</Link>
          </Menu.Item>
          <Menu.Item key="announcement-list" icon={<SoundOutlined />} >
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="announcements">Maintenance Announcement</Link>
          </Menu.Item>
        </Fragment>
      );
      break;
    case "Admin":
      menus = (
        <Fragment>
          <Menu.Item key="poorvu" icon={<BarChartOutlined />} className="new-menu-item">
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="poorvu">Poorvu</Link>
          </Menu.Item>
          <Menu.Item key="opac" icon={<BarsOutlined />} className="new-menu-item">
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="opac">OPAC</Link>
          </Menu.Item>
          {(window.location.href.includes("pivot-dev")||window.location.href.includes("pivot-stg")||window.location.href.includes("localhost") )&& <Menu.Item key="import-event" icon={<DashboardOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="import-event">Import Event</Link>
          </Menu.Item>}
          {/* <Menu.Item key="dashboard-list" icon={<DashboardOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="dashboard">Dashboard</Link>
          </Menu.Item>
          <SubMenu key="accountssub" icon={<UserOutlined />} title="Accounts">
            <Menu.Item key="student-list">
              <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="students">Students</Link>
            </Menu.Item>
            <Menu.Item key="teacher-list">
              <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="teachers">Teachers</Link>
            </Menu.Item>
          </SubMenu>
          <Menu.Item key="club-list" icon={<UsergroupDeleteOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="clubs">Clubs</Link>
          </Menu.Item>
          <Menu.Item key="interest-list" icon={<DatabaseOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="interests">Interests</Link>
          </Menu.Item>
          <Menu.Item key="softskill-list" icon={<SolutionOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="softskills">Softskills</Link>
          </Menu.Item>
          <Menu.Item key="event-list" icon={<SolutionOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="events">Events</Link>
          </Menu.Item>
          <Menu.Item key="location-list" icon={<AimOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="locations">Location</Link>
          </Menu.Item>
          <Menu.Item key="schoolyear-list" icon={<CalendarOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="schoolyears">School Year</Link>
          </Menu.Item>
          <Menu.Item key="announcement-list" icon={<SoundOutlined />} >
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="announcements">Announcements</Link>
          </Menu.Item> */}
        </Fragment>
      );
      break;
    case "Teacher":
      menus = (
        <Fragment>
          <Menu.Item key="import-event" icon={<DashboardOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="import-event">Import Event</Link>
          </Menu.Item>
          <Menu.Item key="calendar" icon={<CalendarOutlined />} className="new-menu-item">
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="/calendar">Calendar</Link>
          </Menu.Item>
          <Menu.Item key="promote-event" icon={<HighlightOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="promote-event">Promotions</Link>
          </Menu.Item>
          <Menu.Item key="clubs" icon={<GroupOutlined />}>
            <Link onClick={() => flutterConfig && flutterConfig.onClose()} to="club-links">Clubs</Link>
          </Menu.Item>
        </Fragment>
      );
      break;
    default:
      console.log("");
      break;
  }

  return flutterConfig ? getFlutterSideMenu() : getWebSideMenu();
};

export default SidebarMenu;
