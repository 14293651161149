import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND,
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

export const handleNotificationActions = (action) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.put(action.url, config);

    dispatch(
      setAlert(
        "Success",
        `Club Proposal Request has been ${
          action.label === "Accept" ? "Approved" : "Declined"
        }`,
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const executeAction = (action) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const res = await axiosApiInstance.post(
      `/api/notification/`,
      action,
      config
    );
    dispatch(
      setAlert(
        "Success",
        `Success!`,
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};

export const getNotificationUrl = (memberType, notificationType, recordId) => {
  var notifType = notificationType;

  if( notificationType.indexOf("Club") >= 0){
    notifType = "Club";
  }

  if( notificationType.indexOf("Event") >= 0){
    notifType = "Event";
  }

  switch (notifType) {
    case "Club":
      return memberType ==="Admin" ? `/admin/club/${recordId}` : `/club/${recordId}`;
    
    case "Event":
    case "AcceptRequestedInvite":
    case "DeclineRequestedInvite":
    case "RemoveInvite":
      return memberType ==="Admin" ? `/admin/events/${recordId}` : `/event/${recordId}`;

    default:
      return "";
  }
};