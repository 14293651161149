import { Col, Row } from "antd";
import { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PageBanner from "../layouts/PageBanner";

const PrivacyContent = () => {
  const location = useLocation();
  const [activeRef, setActiveRef] = useState("#page1");
  useEffect(() => {
    if (location.hash) {
      setActiveRef(location.hash);
    }
  }, [location.hash]);
  return (
    <Row gutter={24} className="privacy-policy">
      <Col className="gutter-row" lg={{ span: 16 }} xl={{ span: 17 }}>
        <div className="page-blog-content for-fix-widget">
          {/* <p>
            Last updated: <b>January 25, 2024</b>
          </p> */}
          <h5>
            This Privacy Policy describes Our policies and procedures on the
            collection, use and disclosure of Your information when You use the
            Service and tells You about Your privacy rights and how the law
            protects You.
          </h5>
          <h5>
          We use Your Personal data to provide and improve the Service. Some of our Application's 
          functionality can be used without revealing any Personal Data, but for features or Services
           related to event management, Personal Data is required. By using the Service, You agree to the 
           collection and use of information in accordance with this Privacy Policy.
          </h5>
          <br/>
          <br/>
          <h5 id="page1">Interpretation and Definitions</h5>
          <p>
            <b>Interpretation</b>
          </p>
          <p>
          The words of which the initial letter is capitalized have meanings defined under the following conditions. 
          Likewise, the following definitions shall have the same meaning regardless of whether they appear singular or plural.
          </p>
          <p>
            <b>Definitions</b>
          </p>
          <p>For the purposes of this Privacy Policy: </p>
          <ul>
            <li>
              <b>Account</b> means a unique account created for You to access our
              Service or parts of our Service.
            </li>
            <li>
              <b>Affiliate</b> means an entity that controls, is controlled by or is
              under common control with a party, where "control" means ownership
              of 50% or more of the shares, equity interest or other securities
              entitled to vote for election of directors or other managing
              authority.
            </li>
            <li>
              <b>Application</b> means the software program provided by the Company
              downloaded by You on any electronic device, named Breeze.
            </li>
            <li>
              <b>Company</b> (referred to as either "the Company", "We", "Us" or "Our"
              in this Agreement) refers to Breeze.
            </li>
            <li><b>Country</b> refers to United States.</li>
            <li>
              <b>Device</b> means any device that can access the Service such as a
              computer, a cellphone, or a digital tablet.
            </li>
            <li>
              <b>Personal Data</b> is any information that relates to an identified or
              identifiable individual.
            </li>
            <li><b>Service</b> refers to the Application.</li>
            <li>
              <b>Service Provider</b> means any natural or legal person who processes 
              the data on behalf of the Company. It refers to third-party companies 
              or individuals employed by the Company to facilitate the Service. 
              These third-party companies provide the Service on behalf of the Company, 
              perform services related to the Service, or assist the Company in 
              analyzing how the Service is used.
            </li>
            <li>
              <b>Usage Data</b> refers to data collected automatically, either
              generated by the use of the Service or from the Service
              infrastructure itself (for example, the duration of a page visit).
            </li>
            <li>
              <b>You</b> means the individual accessing or using the Service, or the
              company, or other legal entity on behalf of which such individual
              is accessing or using the Service, as applicable.
            </li>
          </ul>
          <h5 id="page2">Collecting and Using Your Personal Data</h5>
          <p>
            <b>Types of Data Collected</b>
          </p>
          <h3>Personal Data</h3>
          <p>
            While using Our Service, We may ask You to provide Us with certain
            personally identifiable information that can be used to contact or
            identify You. Personally identifiable information may include, but
            is not limited to:
          </p>
          <ul>
            <li>First name and last name</li>
            <li>Email address</li>
            <li>Student Identification Number</li>
            <li>School Name</li>
            <li>Club and/or Sports Affiliations</li>
          </ul>
          <h3>Usage Data</h3>
          <p>Usage Data is collected automatically when using the Service. </p>

          <p>
            Usage Data may include information such as Your Device's Internet
            Protocol address (e.g. IP address), browser type, browser version,
            the pages of our Service that You visit, the time and date of Your
            visit, the time spent on those pages, unique device identifiers and
            other diagnostic data.
          </p>

          <p>
            When You access the Service by or through a mobile device, We may
            collect certain information automatically, including, but not
            limited to: the type of mobile device You use, Your mobile device
            unique ID, the IP address of Your mobile device, Your mobile
            operating system, the type of mobile Internet browser You use,
            unique device identifiers, and other diagnostic data.
          </p>

          <p>
            We may also collect information that Your browser sends whenever You
            visit our Service or when You access the Service by or through a
            mobile device.
          </p>
          <h3>Information Collected while Using the Application</h3>
          <p>
            In order to provide features of Our Application while using It, We may collect, with Your prior permission:
          </p>
          <ul>
            <li>Name of event</li>
            <li>Date of event</li>
            <li>Time of event</li>
            <li>Venue of event</li>
          </ul>
          <h3>Google User Data</h3>
          <p>
            This application interacts with Google user data through
            OAuth2 login. Upon user authentication, the application
            receives the following data:
            <li>The user's name</li>
            <li>Email Address</li>
            <li>Profile Picture</li>
            <li>Calendar Events</li>
            This information is stored except for the calendar events for the following purposes:
            <li>Displaying it back to the user</li>
            <li>Verifying user identity for accessing additional content.</li>
          </p>
          <p>
            The user's calendar events will not be stored and will only be displayed back to the user
            and allow them to add and remove calendar events provided by the application.
          </p>
          <p>
            The application will comply to the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Google API Services User Data Policy</a>, including the Limited Use requirements.
            No data is shared with external parties.
          </p>
          <h3>Information regarding your location</h3>
          <p>
            We use this information to provide features of Our Service, to
            improve and customize Our Service. The information may be uploaded
            to the Company's servers and/or a Service Provider's server or it
            may be simply stored on Your device.
          </p>

          <p>
            You can enable or disable access to this information at any time,
            through Your Device settings.
          </p>
          <h5 id="page3">Use of Your Personal Data</h5>
          <p>The Company may use Personal Data for the following purposes:</p>
          <ul>
            <li>
              To provide and maintain our Service. We use Personal Data collected 
              to monitor the usage of our Service.
            </li>
            <li>
              To manage Your Account. We use Personal Data collected to manage Your 
              registration as a user of the Service. The Personal Data You provide 
              can give You access to different functionalities of the Service that are 
              available to You as a registered user.
            </li>
            <li>
              For the performance of a contract. The development, compliance and undertaking 
              of the purchase contract for the products, items, or services You have purchased 
              or of any other contract with Us through the Service are monitored using Your Personal Data.
            </li>
            <li>
              To contact You. We use Personal Data collected to contact You by email, telephone calls, SMS, 
              or other equivalent forms of electronic communication, such as a mobile application's push notifications 
              regarding updates or informative communications related to the functionalities, products, or contracted 
              services, including the security updates, when necessary or reasonable for their implementation.
            </li>
            <li>
              To manage Your requests. We use Your Personal Data to attend and manage Your requests to Us.
            </li>
            <li>
              For other purposes. We may use Your information for other
              purposes, such as data analysis, identifying usage trends,
              determining the effectiveness of our promotional campaigns and to
              evaluate and improve our Service, products, services, marketing,
              and your experience.
            </li>
          </ul>
          <p>
            We may share Your personal information in the following situations:
          </p>
          <ul>
            <li>
              With Service Providers. We may share Your personal information
              with Service Providers to monitor and analyze the use of our
              Service, and to contact You.
            </li>
            <li>
              With Affiliates. We may share Your information with Our
              affiliates, in which case we will require those affiliates to
              honor this Privacy Policy. Affiliates include Our parent company
              and any other subsidiaries, joint venture partners or other
              companies that We control or that are under common control with
              Us.
            </li>
            <li>
              With other users. When You share personal information or otherwise
              interact in the public areas with other users, such information
              may be viewed by all users and may be publicly distributed
              outside.
            </li>
            <li>
              For other purposes. We may disclose Your personal information for
              any other purpose with Your consent.
            </li>
          </ul>
          <h5 id="page4">Retention of Your Personal Data</h5>
          <p>
            The Company will retain Your Personal Data only for as long as is
            necessary for the purposes set out in this Privacy Policy. We will
            retain and use Your Personal Data to the extent necessary to comply
            with our legal obligations (for example, if we are required to
            retain your data to comply with applicable laws), resolve disputes,
            and enforce our legal agreements and policies.
          </p>
          <p>
            The Company will also retain Usage Data for internal analysis
            purposes. Usage Data is generally retained for a shorter period of
            time, except when this data is used to strengthen the security or to
            improve the functionality of Our Service, or We are legally
            obligated to retain this data for longer time periods.
          </p>
          <h5 id="page5">Transfer of Your Personal Data</h5>
          <p>
            Your information, including Personal Data, is processed at the
            Company's operating offices and in any other places where the
            parties involved in the processing are located. It means that this
            information may be transferred to — and maintained on — computers
            located outside of Your state, province, country or other
            governmental jurisdiction where the data protection laws may differ
            than those from Your jurisdiction.
          </p>
          <p>
            Your consent to this Privacy Policy followed by Your submission of
            such information represents Your agreement to that transfer.
          </p>
          <p>
            The Company will take all steps reasonably necessary to ensure that
            Your data is treated securely and in accordance with this Privacy
            Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in
            place including the security of Your data and other personal
            information.
          </p>
          <h5 id="page6">Delete Your Personal Data</h5>
          <p>
            You have the right to delete or request that We assist in deleting
            the Personal Data that We have collected about You.
          </p>

          <p>
            Our Service may give You the ability to delete certain information
            about You from within the Service.
          </p>

          <p>
            You may update, amend, or delete Your information at any time by
            signing in to Your Account, if you have one, and visiting the
            account settings section that allows you to manage Your personal
            information. You may also contact Us to request access to, correct,
            or delete any personal information that You have provided to Us.
          </p>

          <p>
            Please note, however, that We may need to retain certain information
            when we have a legal obligation or lawful basis to do so.
          </p>
          <h5 id="page7">Disclosure of Your Personal Data</h5>
          <span>Business Transactions</span>
          <p>
            If the Company is involved in a merger, acquisition or asset sale,
            Your Personal Data may be transferred. We will provide notice before
            Your Personal Data is transferred and becomes subject to a different
            Privacy Policy.
          </p>
          <span>Law enforcement</span>
          <p>
            Under certain circumstances, the Company may be required to disclose
            Your Personal Data if required to do so by law or in response to
            valid requests by public authorities (e.g. a court or a government
            agency).
          </p>
          <span>Other Legal Requirements</span>
          <p>
            The Company may disclose Your Personal Data in the good faith belief
            that such action is necessary to:
          </p>
          <ul>
            <li>Comply with a legal obligation</li>
            <li>Protect and defend the rights or property of the Company</li>
            <li>
              Prevent or investigate possible wrongdoing in connection with the
              Service
            </li>
            <li>
              Protect the personal safety of Users of the Service or the public
            </li>
            <li>Protect against legal liability</li>
          </ul>
          <h5 id="page8">Security of Your Personal Data</h5>
          <p>
            The security of Your Personal Data is important to Us, but remember
            that no method of transmission over the Internet, or method of
            electronic storage is 100% secure. While We strive to use
            commercially acceptable means to protect Your Personal Data, We
            cannot guarantee its absolute security.
          </p>
          <h5 id="page9">Children's Privacy</h5>
          <p>
            Our Service does not address anyone under the age of 13. We do not
            knowingly collect personally identifiable information from anyone
            under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please
            contact Us. If We become aware that We have collected Personal Data
            from anyone under the age of 13 without verification of parental
            consent, We take steps to remove that information from Our servers.
          </p>
          <p>
            If We need to rely on consent as a legal basis for processing Your
            information and Your country requires consent from a parent, We may
            require Your parent's consent before We collect and use that
            information.
          </p>
          <h5 id="page10">Links to Other Websites</h5>
          <p>
            Our Service may contain links to other websites that are not
            operated by Us. If You click on a third-party link, You will be
            directed to that third party's site. We strongly advise You to
            review the Privacy Policy of every site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third-party sites or
            services.
          </p>
          <h5 id="page11">Changes to this Privacy Policy</h5>
          <p>
            We may update Our Privacy Policy from time to time. We will notify
            You of any changes by posting the new Privacy Policy on this page.
          </p>
          <p>
            We will let You know via email and/or a prominent notice on Our
            Service, prior to the change becoming effective and update the "Last
            updated" date at the top of this Privacy Policy.
          </p>
          <p>
            You are advised to review this Privacy Policy periodically for any
            changes. Changes to this Privacy Policy are effective when they are
            posted on this page.
          </p>
          <h5 id="page12">Contact Us</h5>
          <p>
            If you have any questions about this Privacy Policy, You can contact
            us:
          </p>
          <p>
            By email: <b>sales@breeze.school</b>
          </p>
        
        </div>
      </Col>
      <Col className="gutter-row" span={0} lg={{ span: 8 }} xl={{ span: 7 }}>
        <div className="page-table-of-content-widget">
          <h5 className="widget-title">Table of Contents</h5>
          <ul>
            <li className={activeRef === "#page1" ? "active" : ""}>
              <a href={`${window.location.pathname}#page1`}>
                1. Interpretation and Definitions
              </a>
            </li>
            <li className={activeRef === "#page2" ? "active" : ""}>
              <a href={`${window.location.pathname}#page2`}>
                2. Collecting and Using Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page3" ? "active" : ""}>
              <a href={`${window.location.pathname}#page3`}>
                3. Use of Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page4" ? "active" : ""}>
              <a href={`${window.location.pathname}#page4`}>
                4. Retention of Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page5" ? "active" : ""}>
              <a href={`${window.location.pathname}#page5`}>
                5. Transfer of Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page6" ? "active" : ""}>
              <a href={`${window.location.pathname}#page6`}>
                6. Delete Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page7" ? "active" : ""}>
              <a href={`${window.location.pathname}#page7`}>
                7. Disclosure of Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page8" ? "active" : ""}>
              <a href={`${window.location.pathname}#page8`}>
                8. Security of Your Personal Data 
              </a>
            </li>
            <li className={activeRef === "#page9" ? "active" : ""}>
              <a href={`${window.location.pathname}#page9`}>
                9. Children's Privacy 
              </a>
            </li>
            <li className={activeRef === "#page10" ? "active" : ""}>
              <a href={`${window.location.pathname}#page10`}>
                10. Links to Other Websites 
              </a>
            </li>
            <li className={activeRef === "#page11" ? "active" : ""}>
              <a href={`${window.location.pathname}#page11`}>
                11. Changes to this Privacy Policy 
              </a>
            </li>
            <li className={activeRef === "#page12" ? "active" : ""}>
              <a href={`${window.location.pathname}#page12`}>12. Contact Us </a>
            </li>
          </ul>
        </div>
      </Col>
    </Row>
  );
};

export default PrivacyContent;
