import {
    Button, 
    Form, 
    Space, 
    Select, 
    Input,
    DatePicker,
    Col,
    Row,
    message
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import { PlusOutlined } from "@ant-design/icons";
import ModalCustomForm from "../Modals/ModalCustomForm";
import type { RangePickerProps } from "antd/es/date-picker";
import { getSoftSkillsNonPaged } from "../../../actions/softskill";
import {eventTypes} from "../../../helpers/eventTypes";
import PointDistributionInput from "../../super-admin/interest/PointDistribution";
import ParticipantField from "../../super-admin/select-fields/ParticipantField";
import LocationField from "../../super-admin/select-fields/LocationField";

export const CreateEventModal = (props: {
  member: any;
  title: string;
  handleSubmit: any;
  isOpen: boolean;
  setIsOpen: Function;
  form: any;
  clubs: any;
  interests: any;
  interestList: any;
  isLoading: boolean;
  getSoftSkillsNonPaged:Function;
}) => {
    const {
        title,
        handleSubmit,
        isOpen,
        setIsOpen,
        form,
        isLoading,
        member,
        clubs,
        interests,
        interestList,
        getSoftSkillsNonPaged,
    } = props;
    const { RangePicker } = DatePicker;
    const [eventType, setEventType] = useState(2);
    const [dateRangeComponentValue, setDateRangeComponentValue] = useState<any[]>([]);
    const [validationMessages, setValidationMessages]: any = useState({});

    const [softSkillList, setSoftSkillList] = useState([]);
    const [isLoadingSoftSkillList, setIsLoadingSoftSkillList] = useState(false);
    const [isSoftSkillValid, setIsSoftSkillValid] = useState(true);
    const [selectedSoftSkill, setSelectedSoftSkill]: any = useState({});
    const [softSkillScores, setSoftSkillScores] = useState<any>();
    const [selectedSkill, setSelectedSkill] = useState<{
      name: string;
      description: string;
    }>();

    const onDateChange: RangePickerProps["onChange"] = (dates, dateStrings,) => {
        if (dates) {
          setDateRangeComponentValue(dateStrings);
          form.setFieldsValue({date:[moment(dates[0]),moment(dates[1])]});
        } else {
          setDateRangeComponentValue([]);
        }
      };

    const onInterestChange = (id: any) => {
        const interest = interestList.find((f:any) => f.interestId === id);
        let count = 0;
        const scores = interest?.softSkillScores.map((score: any) => ({
            key: count++,
            id: score.softSkillId,
            name: score.name,
        }));
        setSelectedSoftSkill({});
        setSoftSkillScores([]);
        form.setFieldsValue({interestId:id});
        form.setFieldsValue({
            softSkillScores: interest?.softSkillScores,
        });
        scores.map((s:any)=>{changeSoftSkillHandler(s.key,s.id)})
    };

    const changeSoftSkillHandler = (key: number, e: any) => {
        setSelectedSoftSkill((selectedSoftSkill:any)=>({
            ...selectedSoftSkill,
            [key]: { key: key, id: e },
        }));
        validateSoftSkillScore();
    };

    const validateSoftSkillScore = (): boolean => {
        let sum = 0;
        const scores = form.getFieldValue("softSkillScores");
        if (scores) {
          sum = scores.reduce((p: any, c: any) => p + c.percentage, 0);
          setSoftSkillScores(
            scores.map((score: any) => ({
              softSkillId: score.softSkillId,
              percentage: score.percentage,
            }))
          );
        }
        setIsSoftSkillValid(sum === 100);
        return sum === 100;
      };

    const handleFormErrors = (data: any) => {
      let responseValidation = data && data.errors ? data.errors : data;
      var obj = {};
      for (const [key, value] of Object.entries(responseValidation)) {
        Object.assign(obj, { [key]: value });
      }
      setValidationMessages(obj);
    };

    const onReset = () => {
        setSoftSkillScores([]);
        setSelectedSoftSkill({});
        setDateRangeComponentValue([]);
        setEventType(2);
        form.resetFields();
    };

    const onSubmit = () => {
        form.setFieldsValue({softSkillScores:softSkillScores}); 
        if(form.getFieldValue('eventType')!==2){
            form.setFieldsValue({eventType:eventType});  
        } 
        let canContinue = 
            (form.getFieldValue('title')!==undefined &&
            form.getFieldValue('locationId')!==undefined &&
            form.getFieldValue('interestId')!==undefined &&
            form.getFieldValue('softSkillScores')!==undefined &&
            form.getFieldValue('date')[0] !==undefined &&
            form.getFieldValue('date')[1] !==undefined &&
            form.getFieldValue('memberGroupId')!==undefined &&
            form.getFieldValue('eventType')!==2 &&
            isSoftSkillValid===true);
 
        if(canContinue===true){
            form.submit();
        }else{
            message.error('Missing Fields')
        }
    };

    useEffect(() => {
        const initialize = async () => {
          setIsLoadingSoftSkillList(true);
          const softSkillData = await getSoftSkillsNonPaged();
          setSoftSkillList(softSkillData?.data);
          setIsLoadingSoftSkillList(false);
          onReset();
        };
        initialize();
      }, []);

      useEffect(() => {
        if(isOpen===false){
            setSoftSkillScores([]);
            setSelectedSoftSkill({});
            setDateRangeComponentValue([]);
            setEventType(2);
        }
      }, [isOpen]);

  return (
    <ModalCustomForm
    title={title}
    handleSubmit={handleSubmit}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    form={form}
    isLoading={isLoading}
    width={1000}
    footerArray={
     [
      <Button
        type="primary"
        key="submit"
        loading={isLoading}
        onClick={onSubmit}
      >
        Save Event
      </Button>,
      <Button
        key="cancel"
        // loading={isLoadingModalDeclineReason}
        onClick={() => {
          onReset();
          setIsOpen(false);
        }}
      >
        Cancel
      </Button>,
    ]
    }
  >

        <Fragment>
            <Row gutter={{ sm: 24, md: 12}}>
                <Col xs={24} sm={24} md={12}>
                    <Form.Item
                        className="mb-2"
                        name={'memberGroupId'}
                        label={"Club:"}
                        rules={[{ required: true, message: "Enter Club" }]}
                    >
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Select defaultValue={null} placeholder="Select Club"
                            onChange={(val)=>form.setFieldsValue({memberGroupId:val})}>
                                {clubs.map((c:any)=>
                                <Select.Option value={c.memberGroupId}>
                                    {c.name}
                                </Select.Option>
                                )}
                            </Select>
                        </Space>
                    </Form.Item>
                    <Form.Item
                        className="mb-2"
                        name={'title'}
                        label={'Title:'}
                        rules={[{ required: true, message: "Enter Title" }]}
                    >
                        <Input
                        placeholder="Enter Event title"
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-0"
                        // name={'Participants'}
                    >
                        <ParticipantField
                          className="pb-0"
                          validationMessages={validationMessages}
                          extendedView={false}
                          form={form}
                        />
                    </Form.Item>
               
                       <LocationField
                            className="pb-1"
                            validationMessages={validationMessages}
                            
                        />
                    <Form.Item
                        className="mb-2"
                        name={'onlineLink'}
                        label={'Meeting Link (optional)'}
                    >
                        <Input
                            placeholder="Enter Meeting Link"
                        />
                    </Form.Item>
                    <Form.Item
                        className="mb-2"
                        name={'date'}
                        label={'Date:'}
                        rules={[{ required: true, message: "Enter Date" }]}
                    >
                        <div className="date-filter-container">
                        <RangePicker
                            defaultValue={dateRangeComponentValue && dateRangeComponentValue?.length === 2
                            ? [moment(dateRangeComponentValue[0]), moment(dateRangeComponentValue[1])]
                            : null}
                            value={dateRangeComponentValue && dateRangeComponentValue?.length === 2
                            ? [moment(dateRangeComponentValue[0]), moment(dateRangeComponentValue[1])]
                            : null}
                            style={{ height: "40px", width:'100%', borderRadius:4 }}
                            placeholder={["From", "To"]}
                            ranges={{
                            Today: [moment(), moment()],
                            "This Week": [
                                moment().startOf("week"),
                                moment().endOf("week"),
                            ],
                            "This Month": [
                                moment().startOf("month"),
                                moment().endOf("month"),
                            ],
                            }}
                            showTime={{ format: 'HH:mm' }}
                            format="YYYY-MM-DD"
                            onChange={onDateChange}
                            disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
                        />
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={12}>
           
                    <Form.Item
                        className="mb-2"
                        name={'interestId'}
                        label={'Interest:'}
                        rules={[{ required: true, message: "select interest" }]}
                    >
                        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
                            <Select defaultValue={null} placeholder="Select Interest" options={interests} 
                            onChange={onInterestChange}/>
                            {/* onChange={(val)=>form.setFieldsValue({interestId:val})}/> */}
                        </Space>
                    </Form.Item>
                    <Form.Item 
                        name="eventType" 
                        label="Event Type"
                        rules={[{ required: true, message: "select interest" }]}
                    >
                        <Button
                            className={`${eventType === 0 && "btn-active"}`}
                            style={{marginRight:5}}
                            onClick={() => setEventType(0)}
                        >
                            Public
                        </Button>
                        <Button
                            className={`${eventType === 1 && "btn-active"}`}
                            onClick={() => setEventType(1)}
                        >
                            Private
                        </Button>
                    </Form.Item>
                    
                    <p className="pb-0"><span className="red-asterisk">* </span>Points Distribution</p>
                    <Form.List name="softSkillScores">
                    {(fields, { add, remove }) => (
                        <>
                        <div className="point-list">
                            {fields.map(({ key, name, ...restField }) => (
                                <PointDistributionInput
                                key={key}
                                name={name}
                                restField={restField}
                                remove={remove}
                                softSkillList={softSkillList}
                                isLoadingSoftSkillList={isLoadingSoftSkillList}
                                changeSoftSkillHandler={changeSoftSkillHandler}
                                selectedSoftSkill={selectedSoftSkill}
                                setSelectedSkill={setSelectedSkill}
                                />
                            ))}
                        </div>
                        
                        <Form.Item>
                            <Button
                            key="submit"
                            shape="round"
                            type="primary"
                            onClick={() => {
                                add();
                                setIsSoftSkillValid(true);
                            }}
                            icon={<PlusOutlined />}
                            >
                            Add Soft Skill
                            </Button>
                        </Form.Item>
                        </>
                    )}
                    </Form.List>
                    {isSoftSkillValid===false && (
                    <h5 className="red-asterisk">
                        Total score must be equivalent to 100.
                    </h5>
                    )}

                    <Form.Item
                        className="mb-0"
                        name={'description'}
                        label={'Details:'}
                        rules={[{ required: false, message: "Enter details of event" }]}
                    >
                        <TextArea
                        // onChange={(e) => setCount(e.target.value.length)}
                        rows={2}
                        placeholder="Enter details of event"
                        maxLength={1000}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </Fragment>
  </ModalCustomForm>
  );
};


const mapStateToProps = (state: any) => ({
    member: state.auth?.member,
  });
  export default connect(mapStateToProps, {
    getSoftSkillsNonPaged,
  })(CreateEventModal);