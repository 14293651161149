export const softSkillColors = [
  "#13C2C2",
  "#EB2F96",
  "#FA8C16",
  "#52C41A",
  "#FADB14",
  "#722ED1",
  "#1890FF",
  "#F5222D",
  "#FA541C",
  "#FAAD14",
  "#A0D911",
  "#2F54EB",
];
