import { Fragment, useState } from "react";
import { Button, Col, PageHeader, Row, Space, Grid } from "antd";
import { useLocation, useNavigate } from "react-router-dom";

import LoginsAnalytics from "./poorvu/LoginsAnalytics";
import EventsBreakdown from "./poorvu/EventsBreakdown";
import EventsAnalytics from "./poorvu/EventsAnalytics";

const Poorvu = (dates:any, filterType:any) => {

    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content">
                    <Row gutter={[16, 16]} >
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <LoginsAnalytics {... dates} {...filterType}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}>
                            <EventsBreakdown {... dates}/>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
                            <EventsAnalytics {... dates}/>
                        </Col>
                    </Row>
                </div>
            </div>
        </Fragment >
    );
};

export default Poorvu;
