import React from "react";
import { connect } from "react-redux";
import { Route, Navigate, Outlet } from "react-router-dom";
import { Admin, Teacher } from "../helpers/userRoles";

const AdminPrivateRoute = (props: any) => {
  const { auth } = props;
  const redirectNoToken = false;
  // const auth = {
  //   userType: "USER_TYPE_SUPER_ADMIN",
  // };

  // If authorized, return an outlet that will render child elements
  // If not, return element that will navigate to login page
  if (auth.token) {
    //only authorize admin for this route
    if (auth.member.memberType === Admin || auth.member.memberType === Teacher) {
      return <Outlet />;
    } else {
      return <Navigate to="/calendar" />;
    }
  } else {
    return <Navigate to={
      redirectNoToken
        ? `/?r=${encodeURIComponent(window.location.pathname + window.location.search)}`
        : '/'
    } />;
  }
  return <Navigate to="/login" />;
};

const mapStateToProps = (state: any) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(AdminPrivateRoute);
