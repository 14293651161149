import { Row, Col, Tooltip } from "antd";
import moment from "moment";

const InterestTooltip = ({ dates, filterType, overViewData }: { dates: any, filterType: any, overViewData: any }) =>{
    const colors=['#169F5B','#165BAA','#E74731','#F765A3','#FFA800']
    const keys = Object.keys(overViewData?.summary??{});
    const values = Object.values<number>(overViewData?.summary??{});
    const entries = keys.map((key,i) => ({name:key, value:values[i]})).sort((a: any, b: any) => (b.value) - (a.value))
    const totalCount = (values.length>0?values?.reduce(
        (accumulator, currentValue) => accumulator + currentValue,0,
    ):0)
    const top5Count = (values.length>0?values?.reduce(
        (accumulator, currentValue,i) => {
            if(i===4) values?.splice(1)
            return accumulator + currentValue;
        }
    ):0)
    return(
        <div className="interest-tooltip" style={{border:"none"}}>
            <div className="title">
            {dates[0]===null?moment().format('MMMM YYYY'):(filterType==='Month' ? moment(dates[0]).format('MMMM YYYY') : `${moment(dates[0]).format('MMM DD')} - ${moment(dates[1]).format('MMM DD')}`)}
            </div>
            <div className="total"><span>Total Events</span><span>{overViewData?.totalEvents??'No Data'}</span></div>
           {entries.slice(0,5).map((val,i)=>{
            return(
                <div className="point-info">
                    <div className="left">
                        <div className="label">
                            <span className="point" style={{backgroundColor:colors[i]}}/>
                            <span style={{color:colors[i]}}>{val?.name}</span>
                        </div>
                    </div>
                    <p className="point-total" style={{color:colors[i]}}>{val?.value}</p>
                </div>
                )
            })}
            {totalCount-top5Count>0 && (
                <div className="point-info">
                    <div className="left">
                        <div className="label">
                            <span className="point" style={{backgroundColor:'#5c5c5c'}}/>
                            <span style={{color:'#5c5c5c'}}>Others</span>
                        </div>
                    </div>
                    <p className="point-total" style={{color:'#5c5c5c'}}>{totalCount-top5Count}</p>
                </div>
            )}
        </div>
    )
}

export default InterestTooltip;