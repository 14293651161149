import { Fragment, useState, useEffect } from "react";
import { 
    Button, 
    Form, 
    Input, 
    DatePicker, 
    Radio,
    Select,
    Upload,
    Table,
    Space,
    message,
    TableProps,
    Modal,
    Spin, 
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { RcFile } from "antd/lib/upload";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import type { UploadProps, SelectProps } from 'antd';
import importCSVImg from "../../../../assets/images/illustration/illus-import-csv.svg"
import Dragger from "antd/lib/upload/Dragger";
import FormItem from "antd/lib/form/FormItem";
import { createEventPromotion, getPromoInfo, updateEventPromotion } from "../../../../actions/promotions";
import { getEventsListByDate } from "../../../../actions/event";
import { RangePickerProps } from "antd/lib/date-picker";
type ColumnsType<T extends object = object> = TableProps<T>['columns'];

const { RangePicker } = DatePicker;
const defaultBanner =
  "https://stbreeze001.blob.core.windows.net/dev/default_banners/Default.png";

const CreatePromotions = (props:{
    setPage:Function,
    editPromoId?:any,
    promoList?:any,
    getPromoInfo:Function,
    getEventsListByDate:Function,
    createEventPromotion:Function,
    updateEventPromotion:Function,
}) => {
    const {
        setPage,
        editPromoId,
        promoList,
        getPromoInfo,
        getEventsListByDate,
        createEventPromotion,
        updateEventPromotion,
    } = props;
    const [fileRead, setFileRead] = useState<any>();
    const [imageBlob, setImageBlob] = useState<any>();
    const [eventSelections, setEventSelections] = useState<any>([]);
    const [events, setEvents] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const [form] = Form.useForm();
    const [showViewEvent, setShowViewEvent] = useState(false); 
    const [viewEvent, setViewEvent] = useState<any>(null); 
    //input values
    const [selectedTab, setSelectedTab] = useState(0); //promoType
    const [selectedPrio, setSelectedPrio] = useState(null); //prioType
    const [editPromoName, setEditPromoName] = useState<any>(null); 
    const [image, setImage] = useState<any>(null); 

    const disabledDate: RangePickerProps["disabledDate"] = (current) => {
        // Can not select days before today and today
        return moment().add(-1, "days") >= current;
    };

    interface DataType {
        title:{
            title: string;
            eventId: string;
        }
        date: {
          from: string;
          to: string;
        };
        eventId: string;
      
    }

    const changePrio = (id:any, direction:any)=>{
        const index = (eventSelections.map((e:any)=>e.eventId).indexOf(id))
        let copy = eventSelections;
        if(index===0 && direction==="up"){
            message.error("Event is already at the top!")
        }else if (index===(eventSelections?.length-1) && direction==="down"){
            message.error("Event is already at the bottom!")
        }else{
            setIsLoading(true);
            let f = copy.splice(index, 1)[0];
            copy.splice(direction==="up"?index-1:index+1, 0, f);
            setEventSelections([]);
            setTimeout(()=>setEventSelections(copy), 1);    //slight delay to render changes
            setIsLoading(false);
        }
    }
    
    const onView = (id:any)=>{
       setViewEvent(events.find((e:any)=>e.eventId===id));
       setShowViewEvent(true);
    }

    const columns: ColumnsType<DataType> = [
        {
          title: 'Event Name',
          dataIndex: ['title', 'data'],
          key: 'title',
          render: (title:any, data:any) => (
            <div className="event-name">
                {selectedTab===2 && (
                    <div className="sort-btns">
                        <Button onClick={()=>changePrio(data?.eventId,"up")}><UpOutlined/></Button>
                        <Button onClick={()=>changePrio(data?.eventId,"down")}><DownOutlined/></Button>
                    </div>
                )}
                <p>{data?.title}</p>
            </div>
        ),
        },
        {
          title: 'Date',
          dataIndex: ['date', 'data'],
          key: 'date',
          render: (date:any, data:any) => (
              <p>{moment(data?.startDate).format('MMM DD - ')}{moment(data?.endDate).format('DD, YYYY')}</p>
          ),
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'eventId',
          render: (eventId:any) => (
            <Space size="middle">
              <a onClick={()=>onView(eventId)}>View</a>
              <a onClick={()=>setEventSelections(eventSelections.filter((e:any)=>e.eventId!==eventId))}>Delete</a>
            </Space>
          ),
        },
    ];

    const onBeforeUpload = (file: RcFile) => {
        setFileRead(file);
        return false;
      };
    
      
    const onFinish = async (formData: any) => {
        setIsCreating(true);
        let promoNames = promoList?.map((n:any)=>(n?.promotionName).toLowerCase());
        if(promoNames.includes((formData?.name).toLowerCase())){
            message.error(`Promotion Name Already Exists!`);
            setIsCreating(false);
            return;
        }
        if(formData?.type===0){
            if(imageBlob===undefined){
                message.error(`Missing Image!`);
                setIsCreating(false);
                return;
            }
            if(formData?.url===undefined){
                message.error(`Missing URL!`);
                setIsCreating(false);
                return;
            }
            if(formData?.priority===undefined){
                message.error(`Select Priority!`);
                setIsCreating(false);
                return;
            }
            formData = {
                promotionName:formData?.name,
                promotionType:formData?.type,
                from:(formData?.duration[0]).startOf('day').toISOString(),
                to:(formData?.duration[1]).endOf('day').toISOString(),
                isActive:true,
                file: imageBlob,
                url: formData?.url,
                priority: formData?.priority,
            };
        }else{
            if(eventSelections?.length===0){
                message.error(`No Events Selected!`);
                setIsCreating(false);
                return;
            }
            formData = {
                promotionName:formData?.name,
                promotionType:formData?.type,
                from:(formData?.duration[0]).startOf('day').toISOString(),
                to:(formData?.duration[1]).endOf('day').toISOString(),
                eventIds:eventSelections.map((e:any)=>e.eventId)??[],
                isActive:true,
            }; 
        }

        let res = await createEventPromotion(formData);
        if(res.status===201){
            message.success(`Promotion "${formData.promotionName}" successfully created!`);
            setPage(0);
        }else{
            message.error(`Promotion creation failed!`);
        }
        setIsCreating(false);
    }
    
    const onUpdate = async (formData: any) => {
        setIsCreating(true);
        let promoNames = promoList?.map((n:any)=>(n?.promotionName).toLowerCase());
        if( editPromoName!=(formData?.name) && promoNames.includes((formData?.name).toLowerCase())){
            message.error(`Promotion Name Already Exists!`);
            setIsCreating(false);
            return;
        }
        if(formData?.type===0){
            formData = {
                eventPromotionId:editPromoId,
                promotionName:formData?.name,
                promotionType:formData?.type,
                from:(formData?.duration[0]).toISOString(),
                to:(formData?.duration[1]).toISOString(),
                isActive:true,
                file: imageBlob,
                url: formData?.url,
                priority: formData?.priority,
            };
        }else{
            if(eventSelections?.length===0){
                message.error(`No Events Selected!`);
                setIsCreating(false);
                return;
            }
            formData = {
                eventPromotionId:editPromoId,
                promotionName:formData?.name,
                promotionType:formData?.type,
                from:(formData?.duration[0]).toISOString(),
                to:(formData?.duration[1]).toISOString(),
                eventIds:eventSelections.map((e:any)=>e.eventId)??[],
                isActive:true,
            }; 
        }
        let res = await updateEventPromotion(formData);
        if(res.status===200){
            message.success(`Promotion successfully updated!`);
            setPage(0);
        }else{
            message.error(`Promotion update failed!`);
        }
        setIsCreating(false);
    }

    const handleChange = (value: string[]) => {
        setEventSelections([... eventSelections, events.find((e:any)=>e.eventId===value)].filter((i:any)=>i))
    };
    
    const cancelCreation = () => {
        setPage(0);
        setEventSelections([]);
    };

    const controller = new AbortController();
    const fetchUpcomingEvents = async () => {   //upcoming within one month
        setEvents([]);
        setIsLoading(true);
        let params;
        params = {
            // from: moment().toISOString(),
            from: moment().startOf('month').toISOString(),
            to :moment().add(1,'month').endOf('day').toISOString(),
        }
        const data: any = await getEventsListByDate(params, controller);
        if(data?.status===200){
            setEvents(data?.data.sort((a: any, b: any) => (a?.title).localeCompare(b?.title)));
            if(editPromoId!==null){
                onFetchInfo(editPromoId, data?.data);
            }
        }
        setIsLoading(false);
      };
    
    const onFetchInfo = async (eventPromoId: any, upcomingEvents:any) => {
        setIsLoading(true);
        let res = await getPromoInfo(eventPromoId);
        if(res.status===200){
            message.success(`Information loaded!`);
            setSelectedTab(res?.data.promotionType);
            setEditPromoName(res?.data.promotionName);
            setEventSelections(((res?.data.eventIds).map((d:any)=>(upcomingEvents.find((e:any)=>e.eventId===d)))).filter((item:any) => item));  //events that have completed are no longer shown
            setImage(`${res?.data?.bannerFileUrl}?time=${moment}`);
            setSelectedPrio(res?.data?.priority);
            form.setFieldsValue({
                name:res?.data.promotionName,
                type:res?.data.promotionType,
                duration:[moment(res?.data.from), moment(res?.data.to)],
                url:res?.data.url,
                priority: res?.data.priority,
            })
        }else{
            message.error(`Information failed to load!`);
        }
        setIsLoading(false);
    }

    useEffect(()=>{
        fetchUpcomingEvents();
    },[])

    useEffect(() => {
        if (fileRead) {
            setImage(null);
            const reader = new FileReader();
            reader.onload = () => setImageBlob(reader.result);
            reader.readAsDataURL(fileRead);
        }
    }, [fileRead]);

    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content">
                        <div className="create-promotions-page">
                            <h1 className="title">{editPromoId!==null?'Update':'Create'} Promotion
                            <Spin spinning={isLoading} style={{marginLeft:'10px'}}/>
                            </h1>
                            <Form
                                form={form}
                                layout="vertical"
                                size="middle"
                                onFinish={editPromoId===null?onFinish:onUpdate}
                            >
                                <Form.Item
                                    name="name"
                                    label="Promotion Name"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Please Provide a Name",
                                        },
                                    ]}
                                >
                                    <Input
                                        placeholder="Enter promotion name"
                                    />
                                </Form.Item>
                                <Form.Item name="type" label="Type"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Please Select a Type",
                                        },
                                    ]}
                                >
                                    <Radio.Group onChange={(e)=>{
                                        setSelectedTab(e.target.value);
                                        setSelectedPrio(null);
                                        if(e.target.value===0){
                                            setEventSelections([]);
                                        }
                                        }} value={selectedTab}>
                                        <Radio.Button value={0}>Front Page Banner</Radio.Button>
                                        <Radio.Button value={1}>Highlight</Radio.Button>
                                        <Radio.Button value={2}>Result Priority</Radio.Button>
                                    </Radio.Group>
                                </Form.Item>
                                <Form.Item
                                    label="Duration"
                                    name="duration"
                                    rules={[
                                        {
                                        required: true,
                                        message: "Please Select Duration",
                                        },
                                    ]}
                                    >
                                        <RangePicker
                                        format="YYYY-MM-DD"
                                        disabledDate={disabledDate}
                                        />
                                </Form.Item>
                                {selectedTab>0 && (
                                <Form.Item
                                    label="Event Selection"
                                    name="selection"
                                >
                                    <Select
                                        disabled={isLoading}
                                        loading={isLoading}
                                        showSearch
                                        allowClear
                                        optionFilterProp="label"
                                        placeholder="Type or search event here"
                                        onChange={handleChange}
                                        className="promo-select"
                                        options={events?.filter((t:any)=> !(eventSelections.includes(t)) && (moment(t.startDate).isSameOrAfter(moment())))?.map((ev:any)=>({'value':ev.eventId,'label':`${ev.title} (${moment(ev.startDate).format('MMM DD')}-${moment(ev.endDate).format('MMM DD')})`}))}
                                    />   
                                </Form.Item>
                                )}
                                {selectedTab===0 && (
                                    <>
                                    <Form.Item name="priority" label="Priority"
                                    >
                                        <Radio.Group onChange={(e)=>{setSelectedPrio(e.target.value)}} value={selectedPrio}>
                                            <Radio.Button value={0}>High</Radio.Button>
                                            <Radio.Button value={1}>Medium</Radio.Button>
                                            <Radio.Button value={2}>Low</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>
                                    <Form.Item
                                        label="URL"
                                        name="url"
                                    >
                                        <Input placeholder="https://www"/>
                                    </Form.Item>
                                    <Form.Item name="bannerImage" label="Banner Image">
                                        <Upload
                                            multiple={false}
                                            beforeUpload={onBeforeUpload}
                                            fileList={fileRead ? [fileRead] : []}
                                            onRemove={() => setFileRead(undefined)}
                                            accept="image/png, image/jpeg"
                                        >
                                            <div className="ie-inner">
                                                <img src={image?.includes('undefined')?importCSVImg: image?image:fileRead ? URL.createObjectURL(fileRead) : importCSVImg} width={"100%"} className="ie-img"/>
                                                <div className="d-flex w-100 justify-content-center">
                                                    <div className="d-flex align-items-center">
                                                        <p style={{ marginRight: 10 }}>Drag and Drop file here  or </p>
                                                        <Button key="choose-file" type="primary" style={{ borderRadius: 5 }}>
                                                            Choose file
                                                        </Button>
                                                    </div>

                                                </div>
                                                <small>Supported Formats: jpg, png</small>
                                            </div>
                                        </Upload>
                                    </Form.Item>
                                    </>
                                )}
                                {selectedTab>0 && (
                                    <Table
                                        className="create-promo-table"
                                        loading={isLoading}
                                        rowKey="key"
                                        columns={columns}
                                        dataSource={eventSelections}
                                    />
                                )}
                                <Button htmlType="submit" loading={isCreating} type="primary">Save Promotion</Button>
                                <Button onClick={cancelCreation} style={{marginLeft:'10px'}}>Cancel</Button>
                            </Form>
                        </div>
                </div>
            </div>
            <Modal
                centered
                title={viewEvent?.title}
                className="view-promo-event"
                visible={showViewEvent}
                onCancel={()=>setShowViewEvent(false)}
                closable={true}
                footer={null}
                >
                <p>
                    {`${moment(viewEvent?.startDate).format("D MMMM YYYY")} ${moment(viewEvent?.startDate).format("h:mm a")} -
                    ${moment(viewEvent?.startDate).isSame(
                        viewEvent?.endDate,
                        "day"
                    )
                    ? moment(viewEvent?.endDate).format(
                        "h:mm a"
                    )
                    : moment(viewEvent?.endDate).format(
                        "MMM D, h:mm a"
                    )}`}
                </p>
                <p>{viewEvent?.description}</p>
            </Modal>
        </Fragment >
    );
};

const mapStateToProps = (state: {
    auth: any;
  }) => ({
    member: state.auth?.member,
  });

export default connect(mapStateToProps, { 
    createEventPromotion, 
    getEventsListByDate, 
    getPromoInfo, 
    updateEventPromotion,
 })(CreatePromotions);
