type WindowWithDataLayer = Window & {
  dataLayer: Record<string, any>[];
};

declare const window: WindowWithDataLayer;

export const tagLoginDetails = (memberId: string) => {
  window.dataLayer.push({
    event: "login",
    user_id: memberId,
  });
};

export const tagComingSoon = (name: string) => {
  window.dataLayer.push({
    event: 'Coming Soon',
    page_label: name,
  });
};

export const tagQrAuth = (memberId: string) => {
  window.dataLayer.push({
    event: 'QR Auth',
    user_id: memberId,
  });
};
