import { clearAuth, updateTokens } from "../actions/auth";
import { CLEAR_PROFILE, LOGOUT, UPDATE_TOKEN } from "../actions/types";

import store from "../store";

const axios = require("axios");

export const axiosApiInstance = axios.create();

axiosApiInstance.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;
var token = localStorage.token;
var refreshToken = localStorage.refreshToken;
// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config: any) => {
    config.headers = {
      Authorization: `Bearer ${localStorage.token}`,
      Accept: "application/json",
      "Content-Type": "application/json; charset=utf-8",
    };
    return config;
  },
  (error: any) => {
    Promise.reject(error);
  }
);

// axiosApiInstance.interceptors.response.use(
//   (res: any) => {
//     return res;
//   },
//   async (err: any) => {
//     const originalConfig = err.config;
//     let token = localStorage.token;
//     let refreshToken = localStorage.refreshToken;

//     if (err.response) {
//       if (err.response.status === 401 && !originalConfig._retry) {
//         originalConfig._retry = true;
//         // Access Token was expired, renewing
//         try {
//           const response = await axiosApiInstance.post(
//             `/api/Auth/refresh-token/`,
//             {
//               token: token,
//               refreshToken: refreshToken,
//             }
//           );
//           console.log("new token", response.data.token);
//           token = response.data.token;
//           refreshToken = response.data.refreshToken;

//           store.dispatch({
//             type: UPDATE_TOKEN,
//             payload: response.data,
//           });

//           axiosApiInstance.defaults.headers.common[
//             "Authorization"
//           ] = `Bearer ${response.data.token}`;
//           return axiosApiInstance(originalConfig);
//         } catch (_error: any) {
//           //redirect to session expired page
//           console.log("failed");
//           store.dispatch({
//             type: LOGOUT,
//           });
//           store.dispatch({
//             type: CLEAR_PROFILE,
//           });

//           // window.location.href = "/session-expired";
//           if (_error.response && _error.response.data) {
//             return Promise.reject(_error.response.data);
//           }
//           return Promise.reject(_error);
//         }
//       }
//       if (err.response.status === 403 && err.response.data) {
//         return Promise.reject(err.response.data);
//       }
//     }
//     return Promise.reject(err);
//   }
// );

// for multiple requests
let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: any, token = null) => {
  failedQueue.forEach((prom: any) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

axiosApiInstance.interceptors.response.use(
  function (response: any) {
    return response;
  },
  function (error: any) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return axiosApiInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      let token = localStorage.token;
      let refreshToken = localStorage.refreshToken;
      return new Promise(function (resolve, reject) {
        axiosApiInstance
          .post("/api/Auth/refresh-token/", { token, refreshToken })
          .then(({ data }: any) => {
            data.googleAccessToken = data.accessToken;
            window.localStorage.setItem("token", data.token);
            window.localStorage.setItem("refreshToken", data.refreshToken);
            window.localStorage.setItem("googleAccessToken", data.googleAccessToken);
            axiosApiInstance.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] = "Bearer " + data.token;
            store.dispatch({
              type: UPDATE_TOKEN,
              payload: data,
            });
            processQueue(null, data.token);
            resolve(axiosApiInstance(originalRequest));
          })
          .catch((err: any) => {
            store.dispatch({
              type: LOGOUT,
            });
            store.dispatch({
              type: CLEAR_PROFILE,
            });
            processQueue(err, null);
            reject(err);
          })
          .finally(() => {
            isRefreshing = false;
          });
      });
    }

    return Promise.reject(error);
  }
);
