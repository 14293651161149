import { Fragment, useRef, useState } from "react";
import ModalFullView from "../../member-app/Modals/ModalFullView";
import { Button, Col, Row, Slider } from "antd";
import Dragger from "antd/lib/upload/Dragger";
import type { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import {
    MinusOutlined,
    PlusOutlined,
    LoadingOutlined,
    ExclamationCircleOutlined,
} from "@ant-design/icons";
import changeProfilePlaceholder from "../../../assets/images/change-profile-placeholder.png";
import AvatarEditor from "react-avatar-editor";
import { uploadProfilePicture } from "../../../actions/profile";
import { CREATED, OK } from "../../../helpers/statuscodes";
import { connect } from "react-redux";

const MAX_FILE_SIZE = 5120;

export interface IProfilePictureModal {
    isOpen: boolean;
    setIsOpen: Function;
    uploadProfilePicture: Function;
}
const ChangeProfilePictureModal: React.FC<IProfilePictureModal> = ({ isOpen, setIsOpen, uploadProfilePicture }) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const [isFileSizeValid, setIsFileSizeValid] = useState(true);
    const [isFileTypeValid, setIsFileTypeValid] = useState(true);
    const [pictureUrl, setPictureUrl]: any = useState();
    const cropImageRef = useRef(null);
    const [isChangeProfilePictureModalOpen, setIsChangeProfilePictureModalOpen] =
        useState(false);
    const [scaleLevel, setScaleLevel] = useState(1);

    const uploadProps: UploadProps = {
        onRemove: (file: any) => {
            const index = fileList.indexOf(file);
            setFileList([]);
        },
        beforeUpload: async (file: any) => {
            setIsFileSizeValid(true);
            setIsFileTypeValid(true);
            const isJpgOrPng =
                file.type === "image/jpeg" || file.type === "image/png";
            if (!isJpgOrPng) {
                setIsFileTypeValid(false);
                // message.error("You can only upload JPG/PNG file!", 15);
            }
            if (file.size / 1024 > MAX_FILE_SIZE) {
                setIsFileSizeValid(false);
                // message.error("You can only upload up to 5MB file size!", 15);
            }
            if (file.size / 1024 < MAX_FILE_SIZE && isJpgOrPng) {
                setIsFileSizeValid(true);
                setIsFileTypeValid(true);
                setFileList([file]);
                let reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => {
                    setPictureUrl(reader.result);
                };
                reader.onerror = function (error) {
                    console.log("Error: ", error);
                };
            }

            return false;
        },
        fileList,
    };

    const getImageUrl = async () => {
        let x: any = cropImageRef;
        return x.current.getImageScaledToCanvas().toDataURL();
    };


    const handleSave = async () => {
        setIsLoading(true);
        const imageURL = await getImageUrl();
        const response = await fetch(imageURL);
        // here image is url/location of image
        const blob = await response.blob();
        const imageFile = new File([blob], "imageProfile", { type: blob.type });

        const formData = new FormData();
        formData.append("imageFile", imageFile);
        const res = await uploadProfilePicture(formData);
        if (res.status == OK || res.status == CREATED) {
            setIsChangeProfilePictureModalOpen(false);
            setFileList([]);
            setPictureUrl("");
        }
        setIsLoading(false);
    };


    return (
        <ModalFullView
            // handleSubmit={handleOk}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isLoading={isLoading}
        >
            <div className="profile-wrapper">
                <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
                    <Col
                        className="gutter-row "
                        xs={{ span: 24 }}
                        sm={{ span: 18 }}
                        md={{ span: 14 }}
                        lg={{ span: 12 }}
                        xl={{ span: 8 }}
                    >
                        {fileList.length === 0 && (
                            <div className="change-profile-picture-wrapper">
                                <h3 className="text-head">Edit your profile picture</h3>

                                <Dragger {...uploadProps}>
                                    <div className="change-profile-picture-container">
                                        {!isFileSizeValid || !isFileTypeValid ? (
                                            <Fragment>
                                                <ExclamationCircleOutlined
                                                    style={{ fontSize: "42px" }}
                                                    className="text-error"
                                                />
                                                {!isFileSizeValid && (
                                                    <h3 className="text-error text-validation">
                                                        Please provide a file smaller than 2MB
                                                    </h3>
                                                )}

                                                {!isFileTypeValid && (
                                                    <h3 className="text-error text-validation">
                                                        You can only upload JPG/PNG file!
                                                    </h3>
                                                )}
                                                <h3 className="text-validation">
                                                    Click this area or drop your photo.
                                                </h3>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <img src={changeProfilePlaceholder} />
                                                <h3>Click or Drop your photo here to upload it</h3>
                                            </Fragment>
                                        )}

                                        {/* <Upload {...uploadProps}> */}
                                        {/* <Button shape="round" icon={<PictureOutlined />}>
                            Select File
                          </Button> */}
                                        {/* </Upload> */}
                                        {/* <Button shape="round" icon={<PictureOutlined />}>
                          Upload
                        </Button> */}
                                    </div>
                                </Dragger>
                                <h5>
                                    It should be smaller than 2MB, and it should show your face.
                                    That way, your friends and family will know it’s you.
                                </h5>
                                {/* <div className="d-flex justify-content-center mt-5">
                        <Button shape="round">Save Photo</Button>
                      </div> */}
                            </div>
                        )}
                        {fileList.length > 0 && (
                            <div className="crop-profile-picture-wrapper">
                                <Fragment>
                                    <div className="avatar-editor-wrapper">
                                        <AvatarEditor
                                            ref={cropImageRef}
                                            image={pictureUrl}
                                            // width={365}
                                            // height={365}
                                            border={40}
                                            borderRadius={100}
                                            color={[0, 0, 0, 0.3]} // RGBA
                                            scale={scaleLevel}
                                            rotate={0}
                                        />
                                        {isLoading && (
                                            <div className="loading-overlay">
                                                <div className="loading-container">
                                                    <div>
                                                        <LoadingOutlined />
                                                    </div>
                                                    <div>Uploading...</div>
                                                </div>
                                                <h5>
                                                    It should be smaller than 2MB, and it should show
                                                    your face. That way, your friends and family will
                                                    know it’s you.
                                                </h5>
                                            </div>
                                        )}
                                    </div>

                                    {!isLoading && (
                                        <Fragment>
                                            <div className="zoom-slider">
                                                <MinusOutlined
                                                    onClick={() => setScaleLevel(scaleLevel - 0.1)}
                                                />
                                                <Slider
                                                    value={scaleLevel}
                                                    onChange={(value: number) => setScaleLevel(value)}
                                                    defaultValue={scaleLevel}
                                                    min={0.5}
                                                    step={0.1}
                                                    max={2}
                                                />
                                                <PlusOutlined
                                                    onClick={() => setScaleLevel(scaleLevel + 0.1)}
                                                />
                                            </div>
                                            <h4 className="text-center">
                                                Make sure your face is visible.
                                            </h4>
                                            <div className="d-flex justify-content-center">
                                                <Button
                                                    loading={isLoading}
                                                    htmlType="submit"
                                                    className="btn-regular mt-3"
                                                    shape="round"
                                                    type="primary"
                                                    onClick={() => handleSave()}
                                                >
                                                    Save Photo
                                                </Button>
                                            </div>
                                            <a
                                                className="text-center mt-5"
                                                onClick={() => setFileList([])}
                                            >
                                                Choose Another Photo
                                            </a>
                                        </Fragment>
                                    )}
                                </Fragment>
                            </div>
                        )}
                    </Col>
                </Row>
            </div>
        </ModalFullView>
    )
}

const mapStateToProps = (state: any) => ({
    auth: state.auth,
});

export default connect(mapStateToProps, {
    uploadProfilePicture,
})(ChangeProfilePictureModal);
