import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AddEventForm from "./AddEventForm";
import importCSVImg from "../../../assets/images/illustration/illus-import-csv.svg"
import excelIcon from "../../../assets/images/illustration/excel-icon.svg"
import { message, Upload } from 'antd';
import type { UploadProps } from 'antd';
import Dragger from "antd/lib/upload/Dragger";
const AddEvent = () => {
    const [fileList, setFileList] = useState<any>([]);
    const props: UploadProps = {
        accept: ",xls,.xlsx",
        name: 'xlsxFile',
        multiple: false,
        action: `${process.env.REACT_APP_API_ENDPOINT}/api/Scrape/upload`,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {
                console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                if (info.fileList.find((i) => i.uid === info.file.uid)) {
                    message.success(`${info.file.name} file import success.`);
                }
            } else if (status === 'error') {
                if (info.fileList.find((i) => i.uid === info.file.uid)) {
                    message.error(`${info.file.name} file import failed.`);
                }
            }
            setFileList(info.fileList);
        },
        beforeUpload: (file: any) => {
            const isCorrectFormat = file.type == "application/vnd.ms-excel" || file.type == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
            if (!isCorrectFormat) {
                message.error(`${file.name} is not a xlsx or xls file`);
                return false;
            }
        },
        onRemove(file) {
            const index = fileList.indexOf(file);
            const newFileList = fileList.slice();
            newFileList.splice(index, 1);
            setFileList(newFileList);
            message.success(`${file.name} file is deleted.`);
            return;
        },
        fileList
    };
    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content">
                    <div className="d-flex justify-content-center align-items-center">
                        <div className="import-event-wrapper">
                            <h3>Upload Template File</h3>
                            <div className="import-event-container">
                                <Dragger {...props}>
                                    <div className="ie-inner">
                                        <div className="ie-img">
                                            <img src={importCSVImg} width={"100%"} />
                                        </div>
                                        <div className="d-flex w-100 justify-content-center">
                                            <div className="d-flex align-items-center">
                                                <p style={{ marginRight: 10 }}>Drag and Drop file here  or </p>
                                                <Button key="choose-file" type="primary" style={{ borderRadius: 5 }}>
                                                    Choose file
                                                </Button>
                                            </div>

                                        </div>
                                        <small>Supported Formats: xls, xlsx</small>
                                    </div>
                                </Dragger>
                            </div>
                            <h3 className="mt-4">Download Template</h3>
                            <div className="import-event-container" style={{ padding: "24px 48px" }}>

                                <div className="d-flex align-items-center">
                                    <div className="mr-2">
                                        <div className="d-flex mb-2">
                                            <img src={excelIcon} />
                                            <h3 className="mb-0 ml-2">Excel</h3>
                                        </div>
                                        <p className="mb-4">You can download the attached example and use them as starting point for your own file. </p>
                                    </div>
                                    <a href="https://stbreeze001.blob.core.windows.net/pivot/templates/event_upload_template.xlsx"><Button type="primary" style={{ borderRadius: 5 }}>
                                        Download
                                    </Button></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment >
    );
};

export default AddEvent;
