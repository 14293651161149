import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from '@react-oauth/google';
import axios from "axios";
// import { Helmet } from "react-helmet";

axios.defaults.baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;

ReactDOM.render(
  // <GoogleOAuthProvider clientId="1045071403084-kir5nplhuv7fhuf7drm8bnb14v4n4r09.apps.googleusercontent.com">
  <GoogleOAuthProvider clientId={`${process.env.REACT_APP_GOOGLE_CLIENT_ID}`}>
    <React.StrictMode>
      {/* <Helmet>
        <meta name="google-site-verification" content={`${process.env.REACT_APP_GOOGLE_SEARCH_CONSOLE}`} />
      </Helmet> */}
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
