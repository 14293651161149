import jwt_decode from "jwt-decode";
import { CheckOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { Student, Teacher } from "../../helpers/userRoles";
import { connect } from "react-redux";
import breezeHorizontalLogo from "../../assets/images/logos/breeze-horizontal.svg";
import welcomeBg from "../../assets/images/welcome-bg.svg";
import userIco from "../../assets/images/icons/user-ico.svg";

const WelcomePage = (props: any) => {
  const {
    auth: { token }
  } = props;
  let fading = false;
  let decoded: any = jwt_decode(token);
  let userRole: String =
    decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
  let url;
  if (userRole === Student || userRole === Teacher) {
    url = "/dashboard";
  } else {
    url = "/admin";
  }
  return (
    <div className={`login-success-page ${fading && "fadeout"}`}>
      <div
        className={`anim-container fadein ${fading && "fadeout"}`}
        style={{ backgroundImage: `url(${welcomeBg})` }}
      >
        <div className="main-container">
          <div>
            <img height="38px" width="auto" src={breezeHorizontalLogo} />
          </div>
          <h1 className="text-head">Welcome to Team Breeze!</h1>
          <p className="text-desc">
            By using your school {userRole} account your can now utilize the
            following features:
          </p>
          <ul>
            <li>
              <CheckOutlined className="mr-1" />
              Manage school users
            </li>
            <li>
              <CheckOutlined className="mr-1" />
              Create Events
            </li>
            <li>
              <CheckOutlined className="mr-1" />
              Monitor Events
            </li>
            <li>
              <CheckOutlined className="mr-1" />
              Track Student Progress
            </li>
            <li>
              <CheckOutlined className="mr-1" />
              Manage Interests and Softskills
            </li>
            <li>
              <CheckOutlined className="mr-1" />
              and more features to come...
            </li>
          </ul>
          <Link to={url}>
            <Button className="btn-radius">
              <img src={userIco} height="30px" width="30px" />
              Continue to your dashboard
              <ArrowRightOutlined className="ml-2" />
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth
});

export default connect(mapStateToProps)(WelcomePage);
