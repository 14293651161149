import {
  RECENT_LOGOUT
} from "../actions/types";

const initialState: Boolean = false;

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case RECENT_LOGOUT:
      return payload;
    default:
      return state;
  }
}