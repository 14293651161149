import { Fragment, useState, useEffect } from "react";
import { Button, Modal, Tooltip, message, Form, Divider, Tag, Space, Avatar } from "antd";
import moment from "moment";
import { connect } from "react-redux";
import { ExclamationCircleOutlined, CheckCircleOutlined, LeftOutlined, UserOutlined } from "@ant-design/icons";
import { acceptEventInvite, declineEventInvite, deleteEvent } from "../../actions/event";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import { OK, NO_CONTENT } from "../../helpers/statuscodes";
import Calendar from '../../assets/images/icons/calendar-blue.svg';
import MapPin from '../../assets/images/icons/map-pin-blue.svg';
import Target from '../../assets/images/icons/target-blue.svg';
import Category from '../../assets/images/icons/category.svg';
import { contactsColors } from "../../helpers/contactsColors";

const ModalContacts = (props: {
    contacts: any,
    visible: boolean,
    onCancel: Function,
    onBack: Function,
}) => {
  const {
    contacts,
    visible,
    onCancel,
    onBack,
  } = props;

  return (
    <>
    <Modal
      className="modal-attending-contacts"
      centered
      visible={visible}
      onCancel={() => onCancel()}
      footer={null}
      title="Attending Contacts"
    >
      <Button onClick={()=>onBack()} icon={<LeftOutlined/>}>
        Back
      </Button>
      <div className="contact-list">
      {/* {contacts?.map((c:any)=>
        <div className="contact">
          <Avatar size={50} style={{ backgroundColor: contactsColors[Math.floor(Math.random() * 6)] }} src={c.image??null}>{(c.name).match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</Avatar>
          <div className="text-group">
            <h3>
              {c.name}
            </h3>
            <div className="mutuals">
              <Avatar size={20} style={{ fontSize:'12px', backgroundColor: '#0098EE' }} src={<UserOutlined/>}/>
              <p>
                n Mutual Friends
              </p>
            </div>
          </div>
        </div>
        )} */}
        {/* NOTE: Temporary removal of mutual friends due to implementation */}
        {contacts?.map((c:any)=>
        <div className="contact">
          <Avatar size={50} style={{ backgroundColor: contactsColors[Math.floor(Math.random() * 6)] }} src={c.image??null}>{(c.name).match(/(\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()}</Avatar>
          <div className="text-group" style={{display: "flex", alignItems: "center"}}>
            <h2 style={{marginBottom: 0}}>
              {c.name}
            </h2>
          </div>
        </div>
        )}
      </div>
    </Modal>
    </>
  );
};

export default connect(null, { })(ModalContacts);