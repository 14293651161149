import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import {
    Button,
    Modal,
    Tooltip,
    message,
    Form,
    Avatar,
    Dropdown,
    Spin,
    Menu,
    Skeleton,
  } from "antd";
  import {
    PlusOutlined,
    MinusOutlined,
    DownOutlined,
    UserOutlined,
    CheckOutlined,
    LoadingOutlined,
    DownloadOutlined,
    CloseOutlined,
  } from "@ant-design/icons";
import moment from "moment";
import { createCalendarEvent, getCalendarEvents, deleteCalendarEvent } from '../../actions/calendar';
import { getEventFromSlug } from "../../actions/event";
import { contactsColors } from "../../helpers/contactsColors";
import Ticket from "../../assets/images/icons/ticket.svg";
import Share from "../../assets/images/icons/share-b.svg";
import googleLoginBtn from "../../assets/images/continue-w-google.svg";
import ModalUserAgreement from "../LoginPage/ModalUserAgreement";
import { login } from "../../actions/auth";
import { setAlert } from "../../actions/alert"
import jwt_decode from "jwt-decode";
import { BAD_REQUEST, OK } from "../../helpers/statuscodes";
import { tagLoginDetails } from "../../utils/googleTagManager";

const PublicEvent = (props: {
    getEventFromSlug:Function, 
    member:any, 
    login: Function, 
    flutterConnect: any,
    googleAccessToken: any,
    getCalendarEvents: Function, 
    createCalendarEvent: Function, 
    deleteCalendarEvent: Function, 
}) => {
    const {getEventFromSlug, member, login, flutterConnect, googleAccessToken, createCalendarEvent, getCalendarEvents, deleteCalendarEvent} = props;
    const [eventData, setEventData] = useState<any>([]);
    const [isLoading, setLoading] = useState(false);
    const [modalUAisOpen, setModalUAisOpen] = useState(false);
    const [isModifyCalendarLoading, setIsModifyCalendarLoading] = useState(false);
    const [isLoginLoading, setIsLoginLoading] = useState(false);
    const [isLoginSuccess, setIsLoginSuccess] = useState(false);
    const [validationMessages, setValidationMessages]: any = useState([]);
    const [flutterLoginLaunched, setFlutterLoginLaunched]: any = useState(false);
    const [flutterCodeReceived, setFlutterCodeReceived]: any = useState(false);
    const [eventIsAdded, setEventIsAdded]: any = useState(false);
    const [eventCounterpart, setEventCounterpart]: any = useState([]);
    const navigate = useNavigate();
    const dateAndTime = `${moment(eventData?.startDate).format("D MMMM YYYY")} ${moment(eventData?.startDate).format("h:mm a")} -
    ${moment(eventData?.startDate).isSame(
        eventData?.endDate,
        "day"
    )
        ? moment(eventData?.endDate).format(
            "h:mm a"
        )
        : moment(eventData?.endDate).format(
            "MMM D, h:mm a"
        )}`
 
    const getEventData = async () => {
        setLoading(true);
        const slug = (window.location.pathname).split('/')[2];
        const result = await getEventFromSlug(slug);
        if (result.status == 200) {
            setEventData(result.data);
        } else {
          message.error("An Error occured!");
        }
        setLoading(false);
    };

    const fetchCalendarEvents = async () => {
        // check if event is already added by user
        const from = moment(eventData.startDate).startOf("week").utc().toISOString();
        const to = moment(eventData.endDate).endOf("week").utc().toISOString();
        const googleEvents = await getCalendarEvents({
          accessToken: googleAccessToken,
          startDate: from,
          endDate: to,
        });
        if (googleEvents && googleEvents.data.length >= 0) {
            let isAdded = googleEvents.data?.find((e:any)=>e.title === eventData.title);
            setEventIsAdded(isAdded!==undefined);
            setEventCounterpart(isAdded)
        }
      };

    useEffect(() => {
        getEventData();
    }, []);
    useEffect(() => {
        if(member!==null && eventData!==null){
            fetchCalendarEvents();
        }
    }, [member, eventData]);

    const club = eventData?.metadata?.fields.find(
        (f: any) => f.name === "clubLogin"
    ).value;
    const eventUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventUrl"
    )?.value;
    const location = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventLocation"
    )?.value;
    const bannerUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventPicture"
    )?.value;
    const eventPic =
    bannerUrl && bannerUrl.startsWith("http")
        ? bannerUrl
        : bannerUrl
        ? `https://yaleconnect.yale.edu${
            eventData?.metadata?.fields.find(
            (f: any) => f.name === "eventPicture"
            )?.value
        }`
        : null;
    let isUpcomingEvent = moment(eventData?.startDate).isAfter(new Date());

    const loginGoogle = useGoogleLogin({
        onSuccess: tokenResponse => responseMessage(tokenResponse),
        flow: 'auth-code',
        onError: () => setAlert('Error', 'Login failed.', "error"),
        scope: 'https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events openid',
        // flow: 'auth-code',
        // ux_mode: flutterConnect ? 'redirect' : 'popup',
        include_granted_scopes: false,
        ux_mode: 'redirect',
        redirect_uri: window.location.origin
        // state: 'test'
    });

    const onAgree = () => {
        setModalUAisOpen(false);
        loginGoogle();
    }

    const responseMessage = async (response: any) => {
        setValidationMessages(new Array()); //clear validation errors
        setIsLoginLoading(true);
    
        const res = await login({
          loginType: 1,
          authCode: response.code,
          isMobile: flutterConnect
        });
    
        setFlutterLoginLaunched(false);
        setFlutterCodeReceived(false);
    
        if (res.status === OK) {
          let decoded: any = jwt_decode(res.data.token);
          // let userRole: String =
          //   decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
            // setUserRole(userRole);
            
          if (res.data.member)
            tagLoginDetails(res.data.member.memberId);
            setIsLoginSuccess(true);
        } else if (res.response.status === BAD_REQUEST) {
          if (
            res.response.data.error ===
            "Your account has been locked due to 5 consecutive unsuccessful login attempts. Try again in 5 minutes."
          ) {
            //failed login attempt
          }
          setValidationMessages({
            message: res.response.data.error,
          });
        }
        setIsLoginLoading(false);
      };

      return (
        <>
            <ModalUserAgreement
                isOpen={modalUAisOpen}
                setIsOpen={setModalUAisOpen}
                onAgree={onAgree}
            />
            <div className={`public-event-page`}>
                <div className="event-container">
                    {isLoading===true?(
                        <div className="skeletons">
                            <Skeleton avatar active={isLoading}/>
                            <Skeleton.Image active={isLoading} />
                            <Skeleton paragraph={{rows:5}} active={isLoading} />
                            <Skeleton.Button active={isLoading} shape={"square"} />
                        </div>
                    ):(
                    <>
                        {member!==null && (
                            <Button
                                className="close-btn"
                                style={{border:'none'}}
                                type="default"
                                icon={<CloseOutlined />}
                                onClick={()=>navigate('/calendar')}
                            />
                        )}
                        <p>{dateAndTime}</p>
                        <div className="main-data">
                            <UserOutlined className="img-placeholder"/>
                            <div className="data">
                            <Tooltip title={eventData?.title}>
                                <h2 className="title">{eventData?.title}</h2>
                                <p className="organizer">{eventData?.organizer ?? 'N/A'}</p>
                                {eventData?.interest && (
                                <p className="interest">{eventData?.interest}</p>
                                )}
                            </Tooltip>
                            </div>
                        </div>
                        <div className="image-container">
                            {eventPic ? (
                            <img className="image-banner" src={eventPic} />
                            ) : (
                            <div className="image-placeholder" />
                            )}
                            {member!==null && eventData?.metadata !== null && (
                            <div className="img-btns">
                                <Button className="circle-btn" disabled><img src={Ticket}/></Button>
                                <Button className="circle-btn" disabled><img src={Share}/></Button>
                            </div>
                            )}
                        </div>
                        <div className="content">
                            <span className="info-point">
                            <p>
                                At {location ? location?.trim() ?? "No Location" : "No Location"}
                            </p>
                            </span>
                            <Tooltip title={eventData?.description}>
                            <p className="description">{eventData?.description}</p>
                            </Tooltip>
                            {member!==null && (<>
                                <span className="info-point">
                                <div
                                    style={{
                                    cursor:
                                        eventData?.mutual && eventData?.mutual.length > 0
                                        ? "pointer"
                                        : "",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    }}
                                    // onClick={() => {
                                    // if (eventData?.mutual && eventData?.mutual.length > 0)
                                    //     onViewContacts(eventData?.mutual);
                                    // }}
                                >
                                    {eventData?.mutual && eventData?.mutual.length > 0 ? (
                                    <>
                                        <Avatar.Group
                                        maxCount={3}
                                        // maxPopoverTrigger="click"
                                        maxPopoverTrigger="hover"
                                        size="default"
                                        maxStyle={{
                                            color: "#0098EE",
                                            backgroundColor: "#D3F4FF",
                                            cursor: "pointer",
                                            pointerEvents: "none",
                                        }}
                                        className="avatar-group"
                                        >
                                        {eventData?.mutual?.map((s: any) => (
                                            <Tooltip title={s.displayName} placement="top">
                                            <Avatar
                                                style={{
                                                backgroundColor:
                                                    contactsColors[Math.floor(Math.random() * 6)],
                                                }}
                                                src={s.profilePicture ?? null}
                                            >
                                                {s.displayName.match(/\b(\w)/g).join("")}
                                            </Avatar>
                                            </Tooltip>
                                        ))}
                                        </Avatar.Group>
                                        <p className="names">
                                        {eventData?.mutual
                                            ?.slice(0, 3)
                                            .map((n: any) => `${n.displayName}, `)}{" "}
                                        {eventData?.mutual?.length > 3 &&
                                            `and {eventData?.mutual?.length - 3} others`}
                                        </p>
                                    </>
                                    ) : (
                                        <span>No mutual(s) attending</span>
                                    )}
                                </div>
                                </span>
                            </>)}
                        </div>
                        <div className="btn-group">
                        {member!==null && eventData?.metadata !== null && isUpcomingEvent && (
                            <Dropdown
                                className={eventIsAdded?"added-btn":"add-btn"}
                                overlay={
                                    <Menu>
                                        {eventIsAdded===true?(
                                            <Menu.Item
                                                key='Remove'
                                                onClick={async () => {
                                                try {
                                                    setIsModifyCalendarLoading(true);
                                                    const res = await deleteCalendarEvent({
                                                        accessToken: googleAccessToken,
                                                        googleEventId: eventCounterpart?.eventId,
                                                        eventId: eventData.eventId,
                                                    });

                                                    if(res.status===200){
                                                        message.success(
                                                            `You successfully removed ${eventData.title}!`
                                                        );
                                                        navigate('/calendar')
                                                    }
                                                } catch (e) {
                                                    message.error("An Error Occured!");
                                                }
                                                }}
                                            >
                                            <MinusOutlined/> Remove from Calendar
                                            </Menu.Item>
                                        ):(
                                            <>
                                                <Menu.Item
                                                    key='add'
                                                    onClick={async () => {
                                                        try {
                                                            setIsModifyCalendarLoading(true);
                                                            const res = await createCalendarEvent({
                                                                accessToken: googleAccessToken,
                                                                eventId: eventData.eventId,
                                                            });
                                                            message.success(
                                                                `You successfully added ${eventData.title}!`
                                                            );
                                                            navigate('/calendar')
                                                        } catch (e) {
                                                            message.error("An Error Occured!");
                                                        }
                                                        setIsModifyCalendarLoading(false);
                                                    }
                                                    }
                                                >
                                                    <PlusOutlined/> Add to Google Calendar
                                                </Menu.Item>
                                                <Menu.Item
                                                    key='download'
                                                    disabled
                                                >
                                                    <DownloadOutlined/> Download ICS File
                                                </Menu.Item>
                                            </>
                                        )}

                                    </Menu>

                                    }
                                >
                                    <Button>
                                    {eventIsAdded?"Added":"Add to Calendar"} {isModifyCalendarLoading?<Spin indicator={<LoadingOutlined spin />} className="loading-spin"/>:eventIsAdded?<CheckOutlined/>:<DownOutlined />}
                                    </Button>
                                </Dropdown>
                            )}
                            {member!==null && eventData?.metadata !== null && eventUrl && (
                                <Button 
                                className="attendance-btn"
                                onClick={() =>
                                    window.open(
                                    eventUrl && eventUrl.startsWith("http")
                                        ? eventUrl
                                        : `https://yaleconnect.yale.edu/${club}${eventUrl}`,
                                    "_blank"
                                    )
                                }
                                >
                                Visit Event Page
                                </Button>
                            )}
                            {member===null && (
                                <div className="google-login-btn" 
                                    onClick={() => setModalUAisOpen(true)}
                                >
                                    <img src={googleLoginBtn}/>
                                </div>
                            )}
                        </div>
                    </>
                    )}
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state: {
    auth: any;
    flutterConnect: any;
    c: any;
  }) => ({
    member: state.auth?.member,
    flutterConnect: state.flutterConnect,
    googleAccessToken: state.auth?.googleAccessToken,
  });

export default connect(mapStateToProps, { getEventFromSlug, login, createCalendarEvent, getCalendarEvents, deleteCalendarEvent })(PublicEvent);
