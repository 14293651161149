import { Button, Form, FormInstance, Select, SelectProps, Spin } from "antd";
import { Fragment, useEffect, useMemo, useRef, useState } from "react";
import AlertContainer from "../../../layouts/AlertContainer";
import { getParticipantListNonPaged } from "../../../actions/participant";
import { connect } from "react-redux";
import { CloseOutlined } from "@ant-design/icons";
import debounce from "lodash/debounce";

const { Option } = Select;

export interface DebounceSelectProps<ValueType = any>
  extends Omit<SelectProps<ValueType | ValueType[]>, "options" | "children"> {
  fetchOptions: (search: string) => Promise<ValueType[]>;
  debounceTimeout?: number;
}

function DebounceSelect<
  ValueType extends {
    key?: string;
    label: React.ReactNode;
    value: string | number;
  } = any
>({
  fetchOptions,
  debounceTimeout = 800,
  ...props
}: DebounceSelectProps<ValueType>) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState<ValueType[]>([]);
  const fetchRef = useRef(0);

  const debounceFetcher = useMemo(() => {
    const loadOptions = (value: string) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);

      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }

        setOptions(newOptions);
        setFetching(false);
      });
    };

    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);

  return (
    <Form.Item label="Participants" name="participants">
      <Select
        labelInValue
        filterOption={false}
        onSearch={debounceFetcher}
        notFoundContent={fetching ? <Spin size="small" /> : null}
        {...props}
        options={options}
      />
    </Form.Item>
  );
}

// Usage of DebounceSelect
interface UserValue {
  label: string;
  value: string;
}

const ParticipantField = (props: {
  getParticipantListNonPaged: Function;
  validationMessages: any;
  className: string;
  extendedView: boolean;
  form: FormInstance<any>;
}) => {
  const [participants, setParticipants] = useState<UserValue[]>([]);
  const {
    validationMessages,
    getParticipantListNonPaged,
    className,
    extendedView,
    form
  } = props;
  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  async function fetchUserList(username: string): Promise<UserValue[]> {
    let params = {
      SearchString: username
    };
    const data = await getParticipantListNonPaged(params);

    return data.data
      .filter(
        (f: any) =>
          participants.findIndex((ff) => ff.value === `${f.id}-${f.type}`) < 0
      )
      .map((item: any) => ({
        label: `${item.name}`,
        value: `${item.id}-${item.type}`
      }));
  }

  useEffect(() => {
    const formData = form.getFieldValue("participants");
    if (!formData) {
      setParticipants([]);
    }
  }, [form]);

  return (
    <Fragment>
      <DebounceSelect
        mode="multiple"
        value={participants}
        placeholder="Select Attendees"
        fetchOptions={fetchUserList}
        onChange={(newValue) => {
          setParticipants(newValue as UserValue[]);
        }}
        style={{ width: "100%" }}
        allowClear
      />
      <div
        className={`mb-4 ${
          validationMessages && validationMessages.memberGroupId && "has-error"
        }`}
      >
        {validationMessages && validationMessages.memberGroupId && (
          <AlertContainer data={validationMessages.memberGroupId} />
        )}
      </div>

      {extendedView && (
        <Form.Item className="selected-list" label="Selected participants">
          <div className="select-item-selected-container">
            {participants.map((p: UserValue) => (
              <div>
                {p.label}
                <Button
                  type="link"
                  size="small"
                  icon={<CloseOutlined />}
                  onClick={() => {
                    setParticipants(
                      participants.filter((f) => f.value !== p.value)
                    );
                    form.setFieldsValue({
                      participants: participants.filter(
                        (f) => f.value !== p.value
                      )
                    });
                  }}
                ></Button>
              </div>
            ))}
          </div>
        </Form.Item>
      )}
    </Fragment>
  );
};

export default connect(null, {
  getParticipantListNonPaged
})(ParticipantField);
