import { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  Tooltip,
  message,
  Form,
  Avatar,
  Dropdown,
  Spin,
  Menu,
} from "antd";
import moment from "moment";
import { connect } from "react-redux";
import {
  ExclamationCircleOutlined,
  DownloadOutlined,
  PlusOutlined,
  MinusOutlined,
  DownOutlined,
  UserOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
} from "../../actions/event";
import ModalDeclineReason from "../member-app/Modals/ModalDeclineReason";
import { OK, NO_CONTENT } from "../../helpers/statuscodes";
import Ticket from "../../assets/images/icons/ticket.svg";
import Share from "../../assets/images/icons/share-b.svg";
import Promo from '../../assets/images/icons/promo-icon.svg';
import Highlight from '../../assets/images/icons/highlight.svg';
import {
  createCalendarEvent,
  deleteCalendarEvent,
} from "../../actions/calendar";
import ModalContacts from "./ModalContacts";
import Contacts from "../../assets/images/icons/user-check-b.svg";
import { contactsColors } from "../../helpers/contactsColors";
import { createEventViewTrail } from "../../actions/analytics";

const ModalEventView = (props: {
  acceptEventInvite: Function;
  declineEventInvite: Function;
  deleteEvent: Function;
  refresh: Function;
  eventData: any;
  eventTags: any;
  conflicts: any;
  isVisible: boolean;
  setIsVisible: Function;
  onModalCancelHandler: any;
  selectedTime: any;
  googleAccessToken: string;
  createCalendarEvent: Function;
  deleteCalendarEvent: Function;
  onEventModify: Function;
  allEvents: any;
  createEventViewTrail: Function;
}) => {
  const {
    acceptEventInvite,
    declineEventInvite,
    eventData,
    eventTags,
    conflicts,
    isVisible,
    setIsVisible,
    deleteEvent,
    onModalCancelHandler,
    refresh,
    selectedTime,
    googleAccessToken,
    createCalendarEvent,
    deleteCalendarEvent,
    onEventModify,
    allEvents,
    createEventViewTrail,
  } = props;
  const [hasInvite, setHasInvite] = useState(false);
  const [isAcceptLoading, setIsAcceptLoading] = useState(false);
  const [isDeclineEventModalOpen, setIsDeclineEventModalOpen] = useState(false);
  const [isLoadingDeclineEventInvite, setIsLoadingDeclineEventInvite] =
    useState(false);
  const [declineEventForm] = Form.useForm();
  const [isAttended, setIsAttended] = useState(false);
  const [isModifyCalendarLoading, setIsModifyCalendarLoading] = useState(false);
  const [contacts, setContacts] = useState([]);
  const [isContactsModalOpen, setIsContactsModalOpen] = useState(false);
  const [widthSize, setWidthSize] = useState<number>(window.innerWidth);
  const dateAndTime = `${moment(eventData?.actualStart ?? eventData?.start).format("D MMMM YYYY")} ${moment(eventData?.actualStart ?? eventData?.start).format("h:mm a")} -
  ${moment(eventData?.actualStart ?? eventData?.start).isSame(
    eventData?.actualEnd ?? eventData?.end,
    "day"
  )
    ? moment(eventData?.actualEnd ?? eventData?.end).format(
        "h:mm a"
      )
    : moment(eventData?.actualEnd ?? eventData?.end).format(
        "MMM D, h:mm a"
      )}`
  let isUpcomingEvent = moment(
    eventData?.actualStart ?? eventData?.start
  ).isAfter(new Date());

  const onBack = () => {
    setIsVisible(true);
    setContacts([]);
    setIsContactsModalOpen(false);
  };

  const onViewContacts = (contacts: any) => {
    onModalCancelHandler();
    setContacts(
      contacts.map((e: any) => ({
        name: e.displayName,
        image: e.profilePicture,
      }))
    );
    setIsContactsModalOpen(true);
  };
  
  const ConflictTooltip = () => {
    return (
      <div className="custom-tooltip">
        {
          <>
            <h3 className="conflict-h3">Conflict</h3>
            <p>
              Event has conflict{conflicts?.conflicts?.length > 1 && "s"} with{" "}
              {conflicts?.conflicts?.map(
                (d: any, i: number) =>
                  `${d}${
                    conflicts?.conflicts?.length === i + 2
                      ? ", and "
                      : conflicts?.conflicts?.length === i + 1
                      ? ""
                      : ", "
                  } `
              )}
            </p>
          </>
        }
      </div>
    );
  };

  const acceptInvite = async () => {
    if (eventData?.userInvite !== undefined) {
      setIsAcceptLoading(true);
      const result = await acceptEventInvite({
        eventId: eventData?.eventId,
        inviteId: eventData?.userInvite?.inviteId,
      });

      setIsAcceptLoading(false);
      if (result && result?.data) {
        message.success("Invite Accepted");
        refresh();
        onModalCancelHandler();
      } else {
        message.error("An Error occured!");
      }
    }
  };

  const declineInvite = async (formData: any) => {
    setIsLoadingDeclineEventInvite(true);
    let values = {
      ...formData,
      eventName: eventData?.title,
      eventId: eventData?.eventId,
      inviteId: eventData?.userInvite?.inviteId,
    };

    const result = await props.declineEventInvite(values);
    setIsLoadingDeclineEventInvite(false);

    if (result.status == OK) {
      declineEventForm.resetFields();
      message.success(
        `You have successfully submitted decline reason for ${eventData?.title} invitation.`
      );
      refresh();
      setIsDeclineEventModalOpen(false);
      onModalCancelHandler();
    } else {
      message.error("An Error occured!");
    }
  };

  const onDeleteEvent = (eventId: string, eventName: string) => {
    Modal.confirm({
      title: "Delete Event",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure you want to delete "${eventName}" event?`,
      okText: "Confirm",
      onOk: () => submitDeleteEvent(eventId),
      okButtonProps: { shape: "round" },
      cancelText: "Cancel",
      cancelButtonProps: { shape: "round" },
    });
  };

  const submitDeleteEvent = async (eventId: string) => {
    setIsAcceptLoading(true);
    const response = await deleteEvent(eventId);
    setIsAcceptLoading(false);
    if (response.status === NO_CONTENT) {
      refresh();
      onModalCancelHandler();
    }
  };

  const recordEventTrail = async (eventId: string) => {
    await createEventViewTrail(eventId);
  };

  const shareEvent = () =>{
    let url = `${(window.location.origin)}/event/${eventData?.slug}`
    navigator.clipboard.writeText(url)
    message.success("Event Link Copied to Clipboard!");
  }

  useEffect(() => {
    if (eventData !== undefined) {
      if (
        eventData?.userInvite?.status === 0 &&
        eventData?.userInvite?.createdBy !== eventData?.userInvite?.memberId &&
        eventData?.userInvite?.inviteId !== undefined
      ) {
        setHasInvite(true);
      } else {
        setHasInvite(false);
      }
      if (isVisible) {
        recordEventTrail(eventData.eventId);
      }
    }
  }, [isVisible]);

  useEffect(() => {
    setWidthSize(window.innerWidth);
  }, [isVisible]);

  const club = eventData?.metadata?.fields.find(
    (f: any) => f.name === "clubLogin"
  ).value;
  const eventUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventUrl"
  )?.value;
  const location = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventLocation"
  )?.value;
  const bannerUrl = eventData?.metadata?.fields.find(
    (f: any) => f.name === "eventPicture"
  )?.value;
  const eventPic =
    bannerUrl && bannerUrl.startsWith("http")
      ? bannerUrl
      : bannerUrl
      ? `https://yaleconnect.yale.edu${
          eventData?.metadata?.fields.find(
            (f: any) => f.name === "eventPicture"
          )?.value
        }`
      : null;

      return (
    <>
      <ModalContacts
        visible={isContactsModalOpen}
        onCancel={() => setIsContactsModalOpen(false)}
        onBack={onBack}
        // contacts={eventData?.mutual ? [] : eventData?.mutual.map((e:any) => {
        //   return {
        //     name: e.displayName ?? ''
        //   }
        // })}
        contacts={contacts}
      />
      <ModalDeclineReason
        title="Enter Decline Reason"
        handleSubmit={declineInvite}
        isOpen={isDeclineEventModalOpen}
        setIsOpen={setIsDeclineEventModalOpen}
        form={declineEventForm}
        isLoading={isLoadingDeclineEventInvite}
      />
      <Modal
        title={<>
          {widthSize<992 && (
                        <Button
                            className="mr-1"
                            style={{border:'none'}}
                            type="default"
                            icon={<ArrowLeftOutlined />}
                            onClick={onModalCancelHandler}
                        />
                    )}
            {dateAndTime}
        </>
      }
        className="modal-event-item"
        centered
        visible={isVisible}
        closable={widthSize>992?true:false}
        onCancel={() => onModalCancelHandler()}
        footer={[
            eventData?.metadata !== null &&
            isUpcomingEvent && (
          <Dropdown
              placement={widthSize<992?"topLeft":"bottomLeft"}
              className={eventData?.isGoogleEvent?"added-btn":"add-btn"}
              overlay={
                  <Menu>
                    {!eventData?.isGoogleEvent ? (
                      <>
                        <Menu.Item
                            key='add'
                            onClick={async () => {
                              try {
                                setIsModifyCalendarLoading(true);
                                const res = await createCalendarEvent({
                                  accessToken: googleAccessToken,
                                  eventId: eventData.eventId,
                                });
                                message.success(
                                  `You successfully added ${eventData.title}!`
                                );
                                onModalCancelHandler();
                              } catch (e) {
                                message.error("An Error Occured!");
                              }
                              onEventModify();
                              setIsModifyCalendarLoading(false);
                            }}
                        >
                          <PlusOutlined/> Add to Google Calendar
                        </Menu.Item>
                        <Menu.Item
                            key='download'
                            disabled
                        >
                          <DownloadOutlined/> Download ICS File
                        </Menu.Item>
                      </>)
                      :(
                        <Menu.Item
                            key='Remove'
                            onClick={async () => {
                              try {
                                setIsModifyCalendarLoading(true);

                                const isPreferredEvent =
                                  eventData.location !== "breeze.school.event.preferred";

                                const eventCounterpart = allEvents.find(
                                  (e: any) =>
                                    eventData.title === e.title &&
                                    ((isPreferredEvent &&
                                      e.location === "breeze.school.event.preferred") ||
                                      (!isPreferredEvent &&
                                        e.location !== "breeze.school.event.preferred"))
                                );

                                const res = await deleteCalendarEvent({
                                  accessToken: googleAccessToken,
                                  googleEventId: isPreferredEvent
                                    ? eventCounterpart.eventId
                                    : eventData.eventId,
                                  eventId: isPreferredEvent
                                    ? eventData.eventId
                                    : eventCounterpart
                                    ? eventCounterpart.eventId
                                    : "",
                                });
                                message.success(
                                  `You successfully removed ${eventData.title}!`
                                );
                                onModalCancelHandler();
                              } catch (e) {
                                message.error("An Error Occured!");
                              }
                              setIsModifyCalendarLoading(false);
                              onEventModify();
                            }}
                        >
                          <MinusOutlined/> Remove from Calendar
                        </Menu.Item>
                      )
                    }
                  </Menu>
              }
          >
              <Button>
                  {eventData?.isGoogleEvent?"Added":"Add to Calendar"} {isModifyCalendarLoading?<Spin indicator={<LoadingOutlined spin />} className="loading-spin"/>: eventData?.isGoogleEvent?<CheckOutlined/>:<DownOutlined />}
              </Button>
          </Dropdown>),
          eventData?.metadata !== null && eventUrl && (
            <Button
              className="attendance-btn"
              onClick={() => {
                recordEventTrail(eventData.eventId);
                window.open(
                  eventUrl && eventUrl.startsWith("http")
                    ? eventUrl
                    : `https://yaleconnect.yale.edu/${club}${eventUrl}`,
                  "_blank"
                )
              }}
            >
              Visit Event Page
            </Button>
          ),
        ]}
      >
        <div className="event-view">
          {eventData?.isPriority===true && (
            <div className='promoted'>
                Promoted 
                <img src={Promo}/>
            </div>
          )}
           {eventData?.isPriority===false && eventData?.isHighlighted === true && (
          <div className='highlighted'>
              <p>Highlighted</p>
              <img src={Highlight}/>
          </div>
        )}
          <div className="main-data">
            <UserOutlined className="img-placeholder"/>
            <div className="data">
              <Tooltip title={eventData?.title}>
                <h2 className="title">{eventData?.title}</h2>
                <p className="organizer">{eventData?.organizer ?? 'N/A'}</p>
                {eventData?.interest?.name && (
                  <p className="interest">{eventData?.interest?.name}</p>
                )}
              </Tooltip>
            </div>
          </div>
          <div className="image-container">
            {eventPic ? (
              <img className="image-banner" src={eventPic} />
            ) : (
              <div className="image-placeholder" />
            )}
            {(eventData?.isGoogleEvent===false||eventData?.isAddedEvent===true) && eventData?.metadata !== null && (
              <div className="img-btns">
                <Button className="circle-btn" disabled><img src={Ticket}/></Button>
                <Button className="circle-btn" onClick={shareEvent}><img src={Share}/></Button>
              </div>
            )}
          </div>
          <div className="content">
            <span className="info-point">
              <p>
                At {location ? location.trim() ?? "No Location" : "No Location"}
              </p>
            </span>
            <Tooltip title={eventData?.description}>
              <p className="description">{eventData?.description}</p>
            </Tooltip>
            <span className="info-point">
              <div
                style={{
                  cursor:
                    eventData?.mutual && eventData?.mutual.length > 0
                      ? "pointer"
                      : "",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                onClick={() => {
                  if (eventData?.mutual && eventData?.mutual.length > 0)
                    onViewContacts(eventData?.mutual);
                }}
              >
                {eventData?.mutual && eventData?.mutual.length > 0 ? (
                  <>
                    <Avatar.Group
                      maxCount={3}
                      // maxPopoverTrigger="click"
                      maxPopoverTrigger="hover"
                      size="default"
                      maxStyle={{
                        color: "#0098EE",
                        backgroundColor: "#D3F4FF",
                        cursor: "pointer",
                        pointerEvents: "none",
                      }}
                      className="avatar-group"
                    >
                      {eventData?.mutual?.map((s: any) => (
                        <Tooltip title={s.displayName} placement="top">
                          <Avatar
                            style={{
                              backgroundColor:
                                contactsColors[Math.floor(Math.random() * 6)],
                            }}
                            src={s.profilePicture ?? null}
                          >
                            {s.displayName.match(/\b(\w)/g).join("")}
                          </Avatar>
                        </Tooltip>
                      ))}
                    </Avatar.Group>
                    <p className="names">
                      {eventData?.mutual
                        ?.slice(0, 3)
                        .map((n: any) => `${n.displayName}, `)}{" "}
                      {eventData?.mutual?.length > 3 &&
                        `and {eventData?.mutual?.length - 3} others`}
                    </p>
                  </>
                ) : (
                    <span>No mutual(s) attending</span>
                )}
              </div>
            </span>
              <div className="btn-group">
                  {eventData?.metadata !== null && isUpcomingEvent && (
                  <Dropdown
                    className={eventData?.isGoogleEvent?"added-btn":"add-btn"}
                    overlay={
                        <Menu>
                          {!eventData?.isGoogleEvent ? (
                                <>
                                  <Menu.Item
                                      key='add'
                                      onClick={async () => {
                                        try {
                                          setIsModifyCalendarLoading(true);
                                          const res = await createCalendarEvent({
                                            accessToken: googleAccessToken,
                                            eventId: eventData.eventId,
                                          });
                                          message.success(
                                            `You successfully added ${eventData.title}!`
                                          );
                                          onModalCancelHandler();
                                        } catch (e) {
                                          message.error("An Error Occured!");
                                        }
                                        onEventModify();
                                        setIsModifyCalendarLoading(false);
                                      }}
                                  >
                                    <PlusOutlined/> Add to Google Calendar
                                  </Menu.Item>
                                  <Menu.Item
                                      key='download'
                                      disabled
                                  >
                                    <DownloadOutlined/> Download ICS File
                                  </Menu.Item>
                                </>)
                                :(
                                  <Menu.Item
                                      key='Remove'
                                      onClick={async () => {
                                        try {
                                          setIsModifyCalendarLoading(true);

                                          const isPreferredEvent =
                                            eventData.location !== "breeze.school.event.preferred";

                                          const eventCounterpart = allEvents.find(
                                            (e: any) =>
                                              eventData.title === e.title &&
                                              ((isPreferredEvent &&
                                                e.location === "breeze.school.event.preferred") ||
                                                (!isPreferredEvent &&
                                                  e.location !== "breeze.school.event.preferred"))
                                          );

                                          const res = await deleteCalendarEvent({
                                            accessToken: googleAccessToken,
                                            googleEventId: isPreferredEvent
                                              ? eventCounterpart.eventId
                                              : eventData.eventId,
                                            eventId: isPreferredEvent
                                              ? eventData.eventId
                                              : eventCounterpart
                                              ? eventCounterpart.eventId
                                              : "",
                                          });
                                          message.success(
                                            `You successfully removed ${eventData.title}!`
                                          );
                                          onModalCancelHandler();
                                        } catch (e) {
                                          message.error("An Error Occured!");
                                        }
                                        setIsModifyCalendarLoading(false);
                                        onEventModify();
                                      }}
                                  >
                                    <MinusOutlined/> Remove from Calendar
                                  </Menu.Item>
                                )
                              }
                            </Menu>
                        }
                    >
                        <Button>
                            {eventData?.isGoogleEvent?"Added":"Add to Calendar"} {isModifyCalendarLoading?<Spin indicator={<LoadingOutlined spin />} className="loading-spin"/>: eventData?.isGoogleEvent?<CheckOutlined/>:<DownOutlined />}
                        </Button>
                    </Dropdown>
                  )}
                  {eventData?.metadata !== null && eventUrl && (
                    <Button
                      className="attendance-btn"
                      onClick={() => {
                        recordEventTrail(eventData.eventId);
                        window.open(
                          eventUrl && eventUrl.startsWith("http")
                            ? eventUrl
                            : `https://yaleconnect.yale.edu/${club}${eventUrl}`,
                          "_blank"
                        )
                      }}
                    >
                      Visit the Page
                    </Button>
                  )}
              </div>
            {/* <div className="tag-area">
              {window.innerWidth > 992 && eventData?.eventTagIds !== null && (
                <Space size={[0, 8]} wrap>
                  {eventData?.eventTagIds?.map((t: any) => (
                    <Tag className="tag" key={t}>
                      {
                        eventTags.filter((tag: any) => tag.eventTagId === t)[0]
                          ?.name
                      }
                    </Tag>
                  ))}
                </Space>
              )}
            </div> */}
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state: { auth: any; googleAccessToken: string }) => ({
  googleAccessToken: state.auth?.googleAccessToken,
});
export default connect(mapStateToProps, {
  acceptEventInvite,
  declineEventInvite,
  deleteEvent,
  createCalendarEvent,
  deleteCalendarEvent,
  createEventViewTrail,
})(ModalEventView);
