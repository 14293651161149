import { Fragment, useState, useEffect, ReactFragment } from "react";
import { Button, Col, Row, Tooltip } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";

import Day from "../components/Day";

const Time=(props:{
    data:any;
    primeTime:any;
})=>{
    const{data, primeTime} = props;
    const currWeek = data?.slice(-7);
    const prevWeek = data?.slice(-14,-7);

    return(
        <div style={{height:'100%', width:'100%'}}>
            <Row gutter={[16, 16]}>
            {currWeek?.map((s:any,i:any)=>
                <Col xs={24} sm={24} md={12} lg={8} xl={6}>
                    <Day data={s} prev={prevWeek[i]} primeTime={primeTime}/>
                </Col>
            )}
            </Row>
        </div>
    )
}

export default Time;
