import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
} from "antd";

import { Fragment, useEffect, useState } from "react";
import ContactItem from "./ContactItem";
import { connect } from "react-redux";
import NoInvitesSent from "../../../assets/images/illustration/empty-invites.svg";
import { SENTREQUEST } from "../../../enums/friendTypes";
import {
  getFriendApi,
  unFriendApi,
  blockFriendApi,
} from "../../../actions/social";
import { contactsColors } from "../../../helpers/contactsColors";

const SentFriendRequest = (props: {
  getFriendApi: Function;
  unFriendApi: Function;
  searchTerm: String;
  blockFriendApi: Function;
}) => {
  const { getFriendApi, unFriendApi, searchTerm, blockFriendApi } = props;
  const [friendsData, setFriendsData] = useState<any[]>([]);
  const [originalFriendsData, setOriginalFriendsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    fetchFriends();
  }, []);

  const unfriendHandler = async (id: String) => {
    let data = await unFriendApi(id);
    const filteredItems = friendsData.filter((item: any) => item.id !== id);
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  const blockFriendHandler = async (emailAddress: String) => {
    let data = await blockFriendApi(emailAddress);
    data = data.data;
    const filteredItems = friendsData.filter(
      (item: any) => item.id !== data.id
    );
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  useEffect(() => {
    const searchResults = originalFriendsData.filter((item) =>
      Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFriendsData(searchResults);
  }, [searchTerm]);

  const fetchFriends = async () => {
    setIsLoading(true);
    let data = await getFriendApi(SENTREQUEST);
    const friendDataWithColor = data.data.map((friend: any) => {
      return {
        ...friend,
        color:
          contactsColors[Math.floor(Math.random() * contactsColors.length)],
        mutualFriends: friend.mutualFriends.map((mutual: any) => {
          return {
            ...mutual,
            color:
              contactsColors[Math.floor(Math.random() * contactsColors.length)],
          };
        }),
      };
    });
    setFriendsData(friendDataWithColor);
    setOriginalFriendsData(friendDataWithColor);
    setIsLoading(false);
  };
  return (
    <div className="contact-list-container">
      <div className="contact-list-container">
        <h3 className="list-title d-flex align-items-center">
          Your Pending Requests{" "}
          <Badge showZero className="ml-1" count={friendsData.length} />
        </h3>
      </div>
      <div className="contact-list">
        {friendsData &&
          friendsData.map((friend) => (
            <ContactItem
              key={friend.id}
              data={friend}
              unfriendHandler={unfriendHandler}
              blockFriendHandler={blockFriendHandler}
            />
          ))}

        {friendsData.length == 0 && !isLoading && (
          <div className="d-flex justify-content-center d-flex-column align-items-center mt-5">
            <img
              className="contact-empty-state"
              src={NoInvitesSent}
              width="auto"
              height="400px"
            />
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={12}
              style={{ margin: "0 auto", flex: 0 }}
            >
              <div className="d-flex d-flex-column align-items-center">
                <h1
                  style={{ fontWeight: "bolder" }}
                  className="text-center mt-4 mb-2"
                >
                  No Invites Sent
                </h1>
                <p className="text-center" style={{ color: "#9E9E9E" }}>
                  You haven’t sent any friend invites yet. Start connecting with
                  fellow students to attend events together.
                </p>
              </div>
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, { getFriendApi, unFriendApi, blockFriendApi })(
  SentFriendRequest
);
