import { Col, Row } from "antd";
import { ReactNode } from "react";

const PageBanner = (props: { title: ReactNode }) => {
  return (
    <div className="page-banner-wrapper">
      <div className={`page-banner-container`}>
        <h2 className="page-title">{props.title}</h2>
        <span className="sub-title">Last updated: <b>June 26, 2024</b></span>
      </div>
    </div>
  );
};

export default PageBanner;
