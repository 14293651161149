import { callApi } from './general';

const baseUrl = '/api/Calendar';

export const getCalendarEvents = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'accessToken' },
    { name: 'startDate' },
    { name: 'endDate' },
  ];
  return await callApi({
    api: 'Events',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'get',
    baseUrl: baseUrl,
  });
};

export const createCalendarEvent = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'accessToken' },
    { name: 'eventId' },
  ];
  return await callApi({
    api: '',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'post',
    baseUrl: baseUrl,
  });
};

export const deleteCalendarEvent = (values, controller) => async (dispatch) => {
  var params = [
    { name: 'accessToken' },
    { name: 'eventId' },
    { name: 'googleEventId' },
  ];
  return await callApi({
    api: 'remove',
    params: params,
    values: values,
    controller: controller,
    dispatch: dispatch,
    type: 'post',
    baseUrl: baseUrl,
  });
};