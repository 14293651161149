import axios from "axios";
import { axiosApiInstance } from "../utils/axiosApiInstance";
import { LOGIN, EVENT_VIEW } from "../helpers/trailTypes";

export const createLoginTrail = () => async () => {
  try {
    const res = await axiosApiInstance.post(`/api/Analytics/create-trail`, {
      TrailType: LOGIN,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const createEventViewTrail = (eventId: any) => async () => {
  try {
    const res = await axiosApiInstance.post(`/api/Analytics/create-trail`, {
      TrailType: EVENT_VIEW,
      eventIds: [eventId],
    });
    return res;
  } catch (err) {
    return err;
  }
};
