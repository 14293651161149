import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
  Tooltip,
} from "antd";
import {
  FilterOutlined,
  PlusOutlined,
  DownOutlined,
  UpOutlined,
  CloseOutlined,
  CalendarOutlined,
  CommentOutlined,
  SearchOutlined,
  MoreOutlined,
} from "@ant-design/icons";
import { Fragment, useEffect, useState } from "react";
import { contactsColors } from "../../../helpers/contactsColors";
import {
  ACTIVE,
  BLOCK,
  PENDING,
  SENTREQUEST,
} from "../../../enums/friendTypes";
import { connect } from "react-redux";
import CancelImg from "../../../assets/images/icons/cancel.svg";
import BlockImg from "../../../assets/images/icons/block.svg";

const ContactItem = (props: any) => {
  const [isLoading, setIsloading] = useState(false);
  const {
    data,
    unfriendHandler,
    friendData,
    addFriendHandler,
    acceptFriendHandler,
    blockFriendHandler,
    auth,
  } = props;
  const friendInfo = data.friendInfo ? data.friendInfo : data;
  // const mutualFriendInfo = data.mutualFriends
  const menu = (
    <Menu className="social-action-btn">
      {data.status == PENDING && auth.member.emailAddress != data.fromEmail && (
        <Menu.Item key="cancel" onClick={() => unfriendHandler(data.id)}>
          <div className="d-flex">
            <div className="d-flex align-items-center mr-3">
              <img src={CancelImg} />
            </div>
            <div className="d-flex d-flex-column" style={{ gap: "2px" }}>
              <div>
                <span className="opt-title">
                  Decline {friendInfo.firstName} {friendInfo.lastName}
                </span>
              </div>
              <div className="opt-desc">
                {friendInfo.firstName} {friendInfo.lastName} can still send you
                a friend request.
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      {(data.status == PENDING ||
        data.status == ACTIVE ||
        data.status == undefined) && (
        <Menu.Item key="block">
          <div
            className="d-flex"
            onClick={() => blockFriendHandler(friendInfo.emailAddress,data.id)}
          >
            <div className="d-flex align-items-center mr-3">
              <img src={BlockImg} />
            </div>
            <div className="d-flex d-flex-column" style={{ gap: "2px" }}>
              <div>
                <span className="opt-title">
                  Block {friendInfo.firstName} {friendInfo.lastName}
                </span>
              </div>
              <div className="opt-desc">
                {friendInfo.firstName} {friendInfo.lastName} wont be able to see
                you or contact you.
              </div>
            </div>
          </div>
        </Menu.Item>
      )}
      {/* <Menu.Item key="block">Ignore</Menu.Item>
      <Menu.Item key="block">Block</Menu.Item>
      <Menu.Item key="cancel">Cancel</Menu.Item> */}
    </Menu>
  );
  const btnAction =
    data.status == ACTIVE ? (
      <Button
        disabled={isLoading}
        className="btn-breeze-grayed"
        onClick={async () => {
          setIsloading(true);
          unfriendHandler(data.id);
          setIsloading(false);
        }}
      >
        Unfriend
      </Button>
    ) : data.status == PENDING && auth.member.emailAddress != data.fromEmail ? (
      <Button
        disabled={isLoading}
        className="btn-outlined"
        onClick={async () => {
          setIsloading(true);
          acceptFriendHandler(data.id);
          setIsloading(false);
        }}
      >
        Accept
      </Button>
    ) : data.status == PENDING &&
      auth.member.emailAddress != friendInfo.emailAddress ? (
      <Button
        disabled={isLoading}
        className="btn-outlined"
        onClick={async () => {
          setIsloading(true);
          unfriendHandler(data.id);
          setIsloading(false);
        }}
      >
        Cancel
      </Button>
    ) : data.status == BLOCK &&
      auth.member.emailAddress != friendInfo.emailAddress ? (
      <Button
        disabled={isLoading}
        className="btn-outlined"
        onClick={async () => {
          setIsloading(true);
          unfriendHandler(data.id);
          setIsloading(false);
        }}
      >
        Unblock
      </Button>
    ) : (
      <Button
        disabled={isLoading}
        className="btn-breeze-primary"
        onClick={async () => {
          setIsloading(true);
          addFriendHandler(data.emailAddress);
          setIsloading(false);
        }}
        type={"primary"}
      >
        Add
      </Button>
    );
  return (
    <div className="contact">
      <div>
        <Avatar
          size={48}
          style={{
            backgroundColor: data.color,
          }}
          src={null}
        >
          {friendInfo && friendInfo.firstName.charAt(0)}
          {friendInfo && friendInfo.lastName.charAt(0)}
        </Avatar>
      </div>
      <div
        className="d-flex w-100 align-items-center"
        style={{
          justifyContent: "space-between",
        }}
      >
        <div
          className="ml-3 mr-2"
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            wordBreak: "break-all",
          }}
        >
          <h2 style={{ marginBottom: 0 }}>
            {friendInfo && friendInfo.firstName}{" "}
            {friendInfo && friendInfo.lastName}
          </h2>
          <p className="mb-0">{friendInfo && friendInfo.emailAddress}</p>
          {data.mutualFriends && (
            <div className="d-flex mt-1 align-items-center mutuals-container mr-1">
              {data.mutualFriends.length > 0 && (
                <div className="mutual-avatar mr-1">
                  {data.mutualFriends.map((mfData: any) => {
                    return (
                      // <Tooltip placement="bottom" title={mfData.firstName +' '+mfData.lastName}>
                      <Avatar
                        size={20}
                        style={{
                          backgroundColor: mfData.color,
                        }}
                        src={null}
                      >
                        {mfData.firstName.charAt(0)}
                        {mfData.lastName.charAt(0)}
                      </Avatar>
                      // </Tooltip>
                    );
                  })}
                </div>
              )}

              <div>
                {data.mutualFriends.length > 0 && (
                  <span className="mutual-text">
                    {data.mutualFriends.length} mutual friends
                  </span>
                )}
              </div>
            </div>
          )}
        </div>
        <div
          className="d-flex contact-action"
          style={{ gap: 8, justifyContent: "end" }}
        >
          {btnAction}
          {data.status != BLOCK && (
            <Dropdown
              key="more"
              trigger={["click"]}
              overlay={menu}
              placement="bottomRight"
            >
              <Button
                className="icon-rotate"
                type="text"
                icon={<MoreOutlined style={{ fontSize: 20 }} />}
              />
            </Dropdown>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { auth: any }) => ({
  auth: state.auth,
});

export default connect(mapStateToProps, {})(ContactItem);
