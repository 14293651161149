import {
  FLUTTER_CONNECTION_SUCCESS,
  FLUTTER_CONNECTION_ERROR
} from "../actions/types";

const initialState: Boolean = false;

export default function (state = initialState, action: any) {
  const { type, payload } = action;
  switch (type) {
    case FLUTTER_CONNECTION_SUCCESS:
      return payload;
    default:
      return state;
  }
}