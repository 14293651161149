import { FIREBASE_REG_TOKEN, CLEAR_FIREBASE_REG_TOKEN } from "../actions/types";

const initialState = {
  firebaseRegistrationToken: null,
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case FIREBASE_REG_TOKEN:
      return {
        firebaseRegistrationToken: payload,
      };
    case CLEAR_FIREBASE_REG_TOKEN:
      return {
        firebaseRegistrationToken: null,
      };
    default:
      return state;
  }
}
