import { useState } from "react";
import { Button, Modal, Checkbox } from "antd";
import BreezeIcon from "../../assets/images/logos/breeze-horizontal-blue.svg";
import ContractIcon from "../../assets/images/icons/contract.png";
import type { CheckboxProps } from 'antd';
import { Link } from "react-router-dom";

export const ModalUserAgreement = (props: {
  isOpen: boolean;
  setIsOpen: Function;
  onAgree: Function;
}) => {
  const { isOpen, setIsOpen, onAgree } = props;
  const [isChecked, setIsChecked] = useState(false);

  const onCancelHandler = () => {
    setIsChecked(false);
    setIsOpen(false);
  };

  const onChange: CheckboxProps['onChange'] = (e) => {
    setIsChecked(!isChecked);
  };

  const Continue = () =>{
    setIsChecked(false);
    onAgree();
  }

  return (
    <Modal
      centered
      className="modal-user-agreement"
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={null}
    >
      <div className="d-flex justify-content-center w-100">
        <img src={BreezeIcon} className="logo"/>
      </div>
      <div className="inner-container">
        <h2>
          <img src={ContractIcon} className="icon"/>
          User Agreement
        </h2>
        <p className="last-updated">Last Updated January 30, 2023</p>
        <p className="agreement">Breeze app use and transfer to any other app of information received from Google APIs
        will adhere to <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes" target="_blank">
          Google API Services User Data Policy</a>, including the Limited Use requirements.</p>
      </div>
      <Checkbox onChange={onChange} checked={isChecked} className="checkbox" id="ua-checkbox">I agree to the <Link className="link" to="/privacy-policy" target="_blank">Breeze Privacy Policy</Link></Checkbox>
      <div className="button-row">
        <Button id="ua-close" onClick={onCancelHandler}>Close</Button>
        <Button id="ua-agree" type="primary" disabled={!isChecked} onClick={Continue}>I Agree</Button>
      </div>
    </Modal>
  );
};

export default ModalUserAgreement;
