import {
    Button, 
    Form, 
    Space, 
    Select, 
    Input,
    DatePicker,
    message,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import moment, { Moment } from "moment";
import { useEffect, useState, Fragment } from "react";
import { connect } from "react-redux";
import ModalCustomForm from "../Modals/ModalCustomForm";
import type { RangePickerProps } from "antd/es/date-picker";
import {eventTypes} from "../../../helpers/eventTypes";

export const CreateReminderModal = (props: {
  member: any;
  title: string;
  handleSubmit: any;
  isOpen: boolean;
  setIsOpen: Function;
  form: any;
  isLoading: boolean;
}) => {
    const {
        title,
        handleSubmit,
        isOpen,
        setIsOpen,
        form,
        isLoading,
        member,
    } = props;
    const { RangePicker } = DatePicker;
    const [dateRangeComponentValue, setDateRangeComponentValue] = useState<any[]>([]);


    const onDateChange: RangePickerProps["onChange"] = (dates, dateStrings,) => {
        if (dates) {
          setDateRangeComponentValue(dateStrings);
          form.setFieldsValue({date:[moment(dates[0]),moment(dates[1])]});
        } else {
          setDateRangeComponentValue([]);
        }
      };

    const onReset = () => {
      setDateRangeComponentValue([]);
      form.resetFields();
    };

    const onSubmit = () => {
      let canContinue = 
          (form.getFieldValue('title')!==undefined &&
          form.getFieldValue('date')[0] !==undefined &&
          form.getFieldValue('date')[1] !==undefined);
      if(canContinue===true){
          form.submit();
      }else{
          message.error('Missing Fields')
      }
  };

  return (
    <ModalCustomForm
    title={title}
    handleSubmit={handleSubmit}
    isOpen={isOpen}
    setIsOpen={setIsOpen}
    form={form}
    isLoading={isLoading}
    width={500}
    footerArray={
     [
      <Button
        type="primary"
        key="submit"
        loading={isLoading}
        onClick={() =>onSubmit()}
      >
        Save Event
      </Button>,
      <Button
        key="cancel"
        // loading={isLoadingModalDeclineReason}
        onClick={() => {
          onReset();
          setIsOpen(false);
        }}
      >
        Cancel
      </Button>,
    ]
    }
  >
    <Fragment>
      <Form.Item
        className="mb-2"
        name={'title'}
        label={"Title:"}
        rules={[{ required: true, message: "Enter Title" }]}
      >
        <Input
          placeholder="Enter Event title"
        />
      </Form.Item>
      <Form.Item
        className="mb-2"
        name={'date'}
        label={"Date Time:"}
        rules={[{ required: true, message: "Enter Date" }]}
      >
        <div className="date-filter-container">
          <RangePicker
            defaultValue={dateRangeComponentValue && dateRangeComponentValue?.length === 2
              ? [moment(dateRangeComponentValue[0]), moment(dateRangeComponentValue[1])]
              : null}
            value={dateRangeComponentValue && dateRangeComponentValue?.length === 2
              ? [moment(dateRangeComponentValue[0]), moment(dateRangeComponentValue[1])]
              : null}
            style={{ height: "40px", width:'100%', borderRadius:4 }}
            placeholder={["From", "To"]}
            ranges={{
              Today: [moment(), moment()],
              "This Week": [
                moment().startOf("week"),
                moment().endOf("week"),
              ],
              "This Month": [
                moment().startOf("month"),
                moment().endOf("month"),
              ],
            }}
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD"
            onChange={onDateChange}
            disabledDate={(current) => current.isBefore(moment().subtract(1,"day"))}
          />
        </div>
      </Form.Item>
      <Form.Item
        className="mb-0"
        name={`description`}
        label={"Details:"}
        rules={[{ required: false, message: "Enter details of event" }]}
      >
        <TextArea
          rows={2}
          placeholder="Enter details of event"
          maxLength={1000}
        />
      </Form.Item>
    </Fragment>
  </ModalCustomForm>
  );
};


const mapStateToProps = (state: any) => ({
    member: state.auth?.member,
  });
  export default connect(mapStateToProps, {
  })(CreateReminderModal);