import axios from "axios";
import {
  BAD_REQUEST,
  METHOD_NOT_ALLOWED,
  NOT_FOUND
} from "../helpers/statuscodes";
import { setAlert } from "./alert";
import { axiosApiInstance } from "../utils/axiosApiInstance";

import { callApi } from "./general";

export const getParticipantListNonPaged = (values) => async (dispatch) => {
  // try {
  //   const res = await axiosApiInstance.get(
  //     `api/Event/participants?SearchString=${params.SearchString}`
  //   );
  //   return res;
  // } catch (err) {
  //   const response = err.response;
  //   if (response) {
  //     dispatch(
  //       setAlert(
  //         response.status,
  //         "There was an error loading Participants",
  //         "error"
  //       )
  //     );
  //     return response;
  //   }
  //   return;
  // }

  var params = [
    { name: 'SearchString' },
    { name: 'ParticipantTypes' },
    { name: 'AccountStatus' },
  ];

  return await callApi({
    api: '',
    params: params,
    values: values,
    dispatch: dispatch,
    baseUrl: '/api/Event/participants',
  });
};

export const getEventParticipantList = (params) => async (dispatch) => {
  let status = params.status != "-1" ? `&status=${params.status}` : "";
  try {
    let searchString = params.searchString
      ? `&searchString=${encodeURIComponent(params.searchString)}`
      : "";
    const res = await axiosApiInstance.get(
      `/api/Event/${params.eventId}/participants` +
        `?page=${params.page}&pageSize=${params.pageSize}` +
        `&status=${params.status}&joinRequestOnly=${params.joinRequestOnly}` +
        searchString
    );

    return res;
  } catch (err) {
    const response = err.response;
    dispatch(setAlert(response.status, response.statusText, "error"));
    return response;
  }
};

export const handleParticipantJoinRequest = (params) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json"
      }
    };
    const res = await axiosApiInstance.put(
      `/api/event/${params.eventId}/invite/${params.inviteId}/${params.type}`,
      params,
      config
    );

    dispatch(
      setAlert(
        "Success",
        `Join Request ${params.type === "approve" ? "Approved" : "Declined"}`,
        "success"
      )
    );
    return res;
  } catch (err) {
    const response = err.response;
    if (
      response.status === METHOD_NOT_ALLOWED ||
      response.status === NOT_FOUND ||
      response.status === BAD_REQUEST
    ) {
      dispatch(setAlert(response.status, response.statusText, "error"));
    }
    return response;
  }
};
