import { Button, Modal } from "antd";
import "../../../assets/scss/member_pages/modal/modal-full-view.scss";
import breezeHorizontalLogo from "../../../assets/images/logos/breeze-horizontal-blue.svg";

export const ModalFullView = (props: {
  children: any;
  handleSubmit?: Function;
  isOpen: boolean;
  isLoading: boolean;
  setIsOpen: Function;
}) => {
  const { handleSubmit, isOpen, isLoading, setIsOpen, children } = props;

  const onCancelHandler = () => {
    setIsOpen(false);
  };

  const onSubmitHandler = async () => {
    handleSubmit && handleSubmit();
  };

  return (
    <Modal
      className="modal-fullview"
      title={
        <div>
          <img src={breezeHorizontalLogo} />
        </div>
      }
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={[]}
    >
      {props.children}
    </Modal>
  );
};

export default ModalFullView;
