import { Button, Col, Input, Table, Space, message, TableProps } from "antd";
import { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { getClubListNonPaged } from "../../../actions/club";
type ColumnsType<T extends object = object> = TableProps<T>['columns'];

const { Search } = Input;

const ClubLinks = (props:any) => {
    const { getClubListNonPaged } = props;
    const [isLoading, setIsLoading] = useState(false);
    const [allClubs, setAllClubs] = useState<any>([]);
    const [filteredClubs, setFilteredClubs] = useState<any>(null);

    const fetchClubList = async () => {
        setIsLoading(true);
        let res = await getClubListNonPaged();
        if(res?.status === 200){
            let sorted = (res?.data).sort((a:any, b:any) => a.name.localeCompare(b.name));
            setAllClubs(sorted);
            setFilteredClubs(sorted);
        }
        setIsLoading(false);
    };


    interface DataType {
        clubName: string;
        slug: string;
    }

    const onCopy = (slug:String) =>{
        let url = `${window.location.origin}/club/${slug}`;
        navigator.clipboard.writeText(url);
        message.success("Club Link Copied to Clipboard!");
    }

    const onSearch = (value:any) => {
        setFilteredClubs(allClubs.filter((a:any)=>a?.name.toLowerCase().includes(value.toLowerCase())))
    }
    const onChange = (e:any) => {
        if(e?.target?.value === ""){
            setFilteredClubs(allClubs);
        }
    }
 
    const columns: ColumnsType<DataType> = [
        {
          title: 'Club Name',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: 'URL',
          dataIndex: 'slug',
          key: 'url',
          render: (slug:any) => (
            <Space size="middle">
                <p>{window.location.origin}/club/{slug}</p>
            </Space>
          ),
        },
        {
            title: 'Actions',
            key: 'actions',
            dataIndex: 'slug',
          render: (slug:any) => (
            <Space size="middle">
                <a onClick={()=>onCopy(slug)}>Copy Link</a>
            </Space>
          ),
        },
    ];

    useEffect(()=>{
        fetchClubList();
    },[]);

    return (
        <Fragment>
            <div className="app-main-content-wrapper">
                <div className="app-main-content" style={{padding:'0px', margin:'0px'}}>
                        <div className="club-links-page">
                            <div className="header">
                                <h1>Club Links</h1>
                                <Search placeholder="search club name" onSearch={onSearch} onChange={onChange} enterButton className="search-bar"/>
                            </div>
                            <div className="table-container">
                                <Table
                                    className="promo-table"
                                    loading={isLoading}
                                    rowKey="key"
                                    columns={columns}
                                    dataSource={filteredClubs}
                                />
                            </div>
                        </div>
                </div>
            </div>
        </Fragment >
    );
};

const mapStateToProps = (state: {
    auth: any;
  }) => ({
    member: state.auth?.member,
  });

export default connect(mapStateToProps, { getClubListNonPaged })(ClubLinks);
