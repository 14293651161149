export const showBlurEffect = () => {
  var rootElement: any = document.getElementById("root");
  rootElement.className += "blur-effect";
};

export const hideBlurEffect = () => {
  var rootElement: any = document.getElementById("root");
  rootElement.classList.remove("blur-effect");
};


export const showBlurEffect2 = () => {
    var rootElement: any = document.getElementById("sidebarMenu");
    rootElement.className += " blur-effect";
    var rootElement2: any = document.getElementById("adminpagecontainer");
    rootElement2.className += " blur-effect";

  };
  
  export const hideBlurEffect2 = () => {
    var rootElement: any = document.getElementById("sidebarMenu");
    rootElement.classList.remove("blur-effect");
    var rootElement2: any = document.getElementById("adminpagecontainer");
    rootElement2.classList.remove("blur-effect");
  };
  