import { Button, Col, Form, InputNumber, Modal, Row, Select } from "antd";
import { Fragment, useEffect, useState } from "react";
import { CloseOutlined } from "@ant-design/icons";
import infoCircleIcon from "../../../assets/images/icons/info-circle.svg";

const { Option } = Select;

const PointDistributionInput = (props: any) => {
  const {
    restField,
    name,
    remove,
    softSkillList,
    isLoadingSoftSkillList,
    changeSoftSkillHandler,
    selectedSoftSkill
  } = props;

  const [selectedSkill, setSelectedSkill] = useState<{
    name: string;
    description: string;
  }>();

  const [
    isModalInterestDescriptionVisible,
    setIsModalInterestDescriptionVisible
  ] = useState(false);

  const handleModalDescription = (key: number) => {
    const newObj: any = selectedSoftSkill[key];
    let tempSkill = softSkillList.filter((skill: { softSkillId: string }) => {
      return skill.softSkillId == newObj.id;
    });
    setSelectedSkill(tempSkill[0]);
    if (tempSkill.length != 0) {
      setIsModalInterestDescriptionVisible(true);
    }
  };

  return (
    <Fragment>
      <Modal
        title={selectedSkill?.name}
        visible={isModalInterestDescriptionVisible}
        onCancel={() => setIsModalInterestDescriptionVisible(false)}
        footer={[
          <Button
            type="primary"
            key="back"
            onClick={() => setIsModalInterestDescriptionVisible(false)}
          >
            OK
          </Button>
        ]}
      >
        <p>{selectedSkill?.description}</p>
      </Modal>
      <div className="point-entry">
        <div className="point-row">
          <div className="point-col point-selection">
            <div className="input-group">
              <Form.Item
                {...restField}
                name={[name, "softSkillId"]}
                rules={[
                  {
                    required: true,
                    message: "Please select Soft Skill!"
                  }
                ]}
                required
              >
                <Select
                  onChange={(e: any) =>
                    changeSoftSkillHandler(restField.fieldKey, e)
                  }
                  disabled={isLoadingSoftSkillList}
                  placeholder={
                    isLoadingSoftSkillList
                      ? "Loading Softskills..."
                      : "Select Softskill"
                  }
                  allowClear
                >
                  {softSkillList &&
                    softSkillList.map((data: any) => {
                      var exists = Object.keys(selectedSoftSkill).some(
                        (k) => selectedSoftSkill[k].id === data.softSkillId
                      );
                      return (
                        <Option
                          key={data.softSkillId}
                          value={data.softSkillId}
                          disabled={exists}
                        >
                          {data.name}
                        </Option>
                      );
                    })}
                </Select>
              </Form.Item>
              <div className="point-icon">
                <img
                  src={infoCircleIcon}
                  height={18}
                  width={18}
                  onClick={() => handleModalDescription(restField.fieldKey)}
                />
              </div>
            </div>
          </div>
          <div className="point-col point-percentage">
            <div className="input-group">
              <Form.Item name={[name, "percentage"]} style={{ margin: "unset" }}>
                <InputNumber
                  onChange={(e: any) =>
                    changeSoftSkillHandler(restField.fieldKey, e)
                  }
                  formatter={(value) => `${value}%`}
                  className="normal-size"
                  placeholder="%"
                  max={100}
                  min={1}
                />
              </Form.Item>
              <div className="point-icon">
                <Button
                  size="small"
                  shape="circle"
                  icon={<CloseOutlined />}
                  onClick={(e: any) => {
                    remove(name);
                    changeSoftSkillHandler(restField.fieldKey, e);
                  }}
                ></Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default PointDistributionInput;
