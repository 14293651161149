import { Button, Col, PageHeader, Row, Space } from "antd";
import { Fragment } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import PageBanner from "../components/member-app/layouts/PageBanner";
import PrivacyContent from "../components/member-app/privacy/PrivacyContent";
import HomePageHeader from "../components/HomePageHeader/HomePageHeader";
import DetailedFooter from "../components/layout/DetailedFooter";


const PrivacyPolicy = () => {
  const navigate = useNavigate();
  const location: any = useLocation();

  return (
    <Fragment>
      <HomePageHeader title=""/>
      <PageBanner title={"Privacy Policy"} />
      <div className="privacy-policy">
        <PrivacyContent />
      </div>
      <DetailedFooter/>
    </Fragment>
  );
};

export default PrivacyPolicy;
