import { Col, Row } from "antd";
import { ReactNode, useEffect, useRef } from "react";
import { connect } from "react-redux";
import flutterConnect from "../../../reducers/flutterConnect";

const PageHeader = (props: {
  title: ReactNode;
  className?: String;
  header?: ReactNode;
  children?: ReactNode;
  flutterConnect: Boolean;
}) => {

  var { flutterConnect } = props;
  const pageMenu = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!flutterConnect)
      return;
    let prevYPos = window.scrollY;
    let height = pageMenu?.current?.style.height ?? '0px';

    const onScroll: EventListener = (event: Event) => {
      var currentYPos = window.scrollY;
      console.log(`pos ${currentYPos}`);
      console.log(`${height}`);


      if (pageMenu && pageMenu.current && prevYPos > currentYPos) {
        pageMenu.current.style.top = '60px';
      } else if (pageMenu && pageMenu.current) {
        pageMenu.current.style.top = '-300px';
      }
      prevYPos = currentYPos;
    };

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="page-header-wrapper" ref={pageMenu}>
      <div className={`page-header-container ${props.className}`}>
        <div className="d-flex justify-content-space-between align-items-center">
          <div className="page-title">{props.title}</div>
          <div className="controls-container">{props.header}</div>
        </div>
        <div className="header-foot-controls">{props.children}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  flutterConnect: state.flutterConnect,
});


export default connect(mapStateToProps)(PageHeader);
