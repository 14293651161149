import { Button, Form, Modal } from "antd";
import TextArea from "antd/lib/input/TextArea";
import { useEffect, useState } from "react";

export const ModalDeclineReason = (props: {
  title: string;
  handleSubmit: any;
  isOpen: boolean;
  setIsOpen: Function;
  form: any;
  isLoading: boolean;
}) => {
  const { title, handleSubmit, isOpen, setIsOpen, form, isLoading } = props;
  const [count, setCount] = useState(0);
  const onCancelHandler = () => {
    setIsOpen(false);
  };

  return (
    <Modal
      title={title}
      visible={isOpen}
      onCancel={onCancelHandler}
      footer={[
        <Button
          type="primary"
          shape="round"
          key="submit"
          loading={isLoading}
          onClick={() => form.submit()}
        >
          Submit
        </Button>,
        <Button
          shape="round"
          key="cancel"
          loading={isLoading}
          onClick={() => onCancelHandler()}
        >
          Cancel
        </Button>,
      ]}
    >
      <Form onFinish={handleSubmit} form={form} layout="vertical">
        <Form.Item
          className="mb-0"
          name={`remarks`}
          label={<span>{`Reason(s)`}</span>}
          rules={[{ required: true, message: "Please input your reason!" }]}
        >
          <TextArea
            onChange={(e) => setCount(e.target.value.length)}
            rows={3}
            placeholder="Input reason"
            maxLength={1000}
          />
        </Form.Item>
        <span className="modal-char-limit">{count}/1000</span>
      </Form>
    </Modal>
  );
};

export default ModalDeclineReason;
