import {
  Button,
  Spin,
  Collapse,
  Checkbox,
  DatePicker,
  Popover,
  Form,
  message,
  notification,
  Row,
  Col,
  Input,
  Badge,
  Avatar,
  Dropdown,
  Menu,
} from "antd";

import { Fragment, useEffect, useState } from "react";
import ContactItem from "./ContactItem";
import { connect } from "react-redux";
import {
  getFriendApi,
  autoAcceptFriend,
  blockFriendApi,
  unFriendApi,
} from "../../../actions/social";
import { ACTIVE, PENDING } from "../../../enums/friendTypes";
import NoPendingReq from "../../../assets/images/illustration/no-pending-req.svg";
import { contactsColors } from "../../../helpers/contactsColors";

const FriendRequestComponent = (props: {
  getFriendApi: Function;
  autoAcceptFriend: Function;
  searchTerm: String;
  blockFriendApi: Function;
  unFriendApi: Function;
}) => {
  const {
    getFriendApi,
    autoAcceptFriend,
    searchTerm,
    blockFriendApi,
    unFriendApi,
  } = props;
  const [friendsData, setFriendsData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [originalFriendsData, setOriginalFriendsData] = useState<any[]>([]);
  useEffect(() => {
    fetchFriends();
  }, []);

  const fetchFriends = async () => {
    setIsLoading(true);
    let data = await getFriendApi(PENDING);
    const friendDataWithColor = data.data.map((friend: any) => {
      return {
        ...friend,
        color:
          contactsColors[Math.floor(Math.random() * contactsColors.length)],
        mutualFriends: friend.mutualFriends.map((mutual: any) => {
          return {
            ...mutual,
            color:
              contactsColors[Math.floor(Math.random() * contactsColors.length)],
          };
        }),
      };
    });
    setFriendsData(friendDataWithColor);
    setOriginalFriendsData(friendDataWithColor);
    setIsLoading(false);
  };

  const acceptFriendHandler = async (id: string) => {
    let data = await autoAcceptFriend(id);
    const filteredItems = friendsData.filter((item: any) => item.id !== id);
    setFriendsData(filteredItems);
  };

  const blockFriendHandler = async (emailAddress: String,id: String) => {
    let data = blockFriendApi(emailAddress);
    data = data.data;
    // let emailToRemove =
    //   auth.member.emailAddress != data.fromEmail
    //     ? data.fromEmail
    //     : data.toEmail;
    const filteredItems = friendsData.filter(
      (item: any) => item.id != id
    );
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  const unfriendHandler = async (id: String) => {
    let data = unFriendApi(id);
    const filteredItems = friendsData.filter((item: any) => item.id !== id);
    setFriendsData(filteredItems);
    setOriginalFriendsData(filteredItems);
  };

  useEffect(() => {
    const searchResults = originalFriendsData.filter((item) =>
      Object.values(item).some((value: any) =>
        value.toString().toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
    setFriendsData(searchResults);
  }, [searchTerm]);
  return (
    <div className="contact-list-container">
      <div className="contact-list-container">
        <h3 className="list-title d-flex align-items-center">
          Friend Requests{" "}
          <Badge showZero className="ml-1" count={friendsData.length} />
        </h3>
      </div>
      <div className="contact-list">
        {friendsData &&
          friendsData.map((friend) => (
            <ContactItem
              key={friend.id}
              data={friend}
              acceptFriendHandler={acceptFriendHandler}
              blockFriendHandler={blockFriendHandler}
              unfriendHandler={unfriendHandler}
            />
          ))}

        {friendsData.length == 0 && !isLoading && (
          <div className="d-flex justify-content-center d-flex-column align-items-center mt-5">
            <img
              className="contact-empty-state"
              src={NoPendingReq}
              width="auto"
              height="400px"
            />
            <Col
              className="gutter-row"
              xs={24}
              sm={12}
              md={12}
              style={{ margin: "0 auto", flex: 0 }}
            >
              <div className="d-flex align-items-center d-flex-column">
                <h1 className="mt-4 mb-2 text-center">
                  No Pending Friend Requests
                </h1>
                <p className="text-center" style={{ color: "#9E9E9E" }}>
                  Invite friends to attend events together.
                </p>
              </div>
            </Col>
          </div>
        )}
      </div>
    </div>
  );
};

export default connect(null, {
  getFriendApi,
  autoAcceptFriend,
  blockFriendApi,
  unFriendApi,
})(FriendRequestComponent);
