import { axiosApiInstance } from "../utils/axiosApiInstance";
import {
  PENDING,
  ACTIVE,
  REJECTED,
  IGNORED,
  BLOCK,
  CANCEL,
} from "../enums/friendTypes";

export const addFriend = (emailAddress: string) => async () => {
  try {
    const res = await axiosApiInstance.post(`/api/Social`, {
      emailAddress: emailAddress,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const autoAcceptFriend = (friendId: string) => async () => {
  try {
    const res = await axiosApiInstance.put(`/api/Social`, {
      friendId: friendId,
      status: ACTIVE,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getFriendApi = (status: Number) => async () => {
  try {
    const res = await axiosApiInstance.get(
      `/api/Social/friends?status=${status}`
    );
    return res;
  } catch (err) {
    return err;
  }
};

export const unFriendApi = (id: String) => async () => {
  try {
    const res = await axiosApiInstance.delete(`/api/Social/${id}`);
    return res;
  } catch (err) {
    return err;
  }
};

export const blockFriendApi = (emailAddress: String) => async () => {
  try {
    const res = await axiosApiInstance.post(`/api/Social/block`, {
      emailAddress: emailAddress,
    });
    return res;
  } catch (err) {
    return err;
  }
};

export const getSuggestedFriendsApi = () => async () => {
  try {
    const res = await axiosApiInstance.get(`/api/Social/suggested-friends`);
    return res;
  } catch (err) {
    return err;
  }
};
//using Api of yalies io
export const searchFriendsApi = (searchTerm: String) => async () => {
  const queryObject = {
    query: searchTerm,
    page: 1,
    page_size: 10,
  };

  const jsonString = JSON.stringify(queryObject);

  // Encode the JSON string to be safely included in the URL
  const encodedJsonString = encodeURIComponent(jsonString);

  try {
    const res = await axiosApiInstance.get(
      `/api/Social/search?query=${encodedJsonString}`
    );
    return res;
  } catch (err) {
    return err;
  }
};
